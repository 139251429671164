import {
  Document, Image, Page, StyleSheet, Text, View
} from '@react-pdf/renderer';
import { colors } from 'common/colors';
import mail from 'assets/img/mail.png';
import phone from 'assets/img/phone.png';
import { BasicsSectionType } from 'pages/cvBuilder/steps/basics/Basics';
import { EducationSectionType } from 'pages/cvBuilder/steps/education/Education';
import { WorkExperienceSectionType } from 'pages/cvBuilder/steps/workExperience/WorkExperience';
import { VolunteerExperienceSectionType } from 'pages/cvBuilder/steps/volunteerExperience/VolunteerExperience';
import { TechnicalSkillsSectionType } from 'pages/cvBuilder/steps/technicalSkills/TechnicalSkills';
import { PersonalSkillsSectionType } from 'pages/cvBuilder/steps/personalSkills/PersonalSkills';

type CvTemplateProps = {
  basics: BasicsSectionType;
  education: EducationSectionType[];
  workExperience: WorkExperienceSectionType[];
  volunteerExperience: VolunteerExperienceSectionType[];
  technicalSkills: TechnicalSkillsSectionType[];
  personalSkills: PersonalSkillsSectionType[];
  summaryStatement: string;
};

const styles = StyleSheet.create({
  page: {
    padding: '50 40',
  },
  section: {
    paddingBottom: 10,
    borderBottom: `1px solid ${colors.primary_gray_light2}`,
  },
  sectionContainer: {
    marginBottom: 5
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  fullName: {
    fontSize: 20,
    textTransform: 'uppercase',
    marginBottom: 10
  },
  rsaIDNumber: {
    fontSize: 17,
    textTransform: 'uppercase',
    marginBottom: 10
  },
  sectionTitle: {
    textTransform: 'uppercase',
    fontSize: 12,
    marginTop: 12,
    marginBottom: 6
  },
  sectionContentTitle: {
    fontSize: 11,
    marginBottom: 3
  },
  sectionContent: {
    color: colors.primary_gray4,
    fontSize: 10,
    marginBottom: 3
  },
  nestedContent: {
    fontSize: 11,
    color: colors.primary_gray4,
  },
  email: {
    color: colors.primary_gray4,
    fontSize: 10,
    marginBottom: 3,
    marginLeft: 5
  },
  phoneIcon: {
    width: 8,
    height: 8,
  },
  mailIcon: {
    width: 9,
    height: 8
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 40,
    right: 40,
    bottom: 30,
    fontSize: 8,
    color: colors.primary_gray5,
    paddingTop: 10,
    borderTop: '1px solid black'
  },
  pageNumber: {
    position: 'absolute',
    color: colors.primary_gray5,
    right: 40,
    bottom: 30,
    fontSize: 8,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  basicInfoContainer: {
    width: '40%',
  }
});

const CvTemplate = ({
  basics,
  education,
  workExperience,
  volunteerExperience,
  technicalSkills,
  personalSkills,
  summaryStatement
}: CvTemplateProps): JSX.Element => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.fullName}>{basics.fullName}</Text>
        <Text style={styles.rsaIDNumber}>{basics.idNumber}</Text>
        <View style={styles.contentContainer}>
          <Image src={mail} style={styles.mailIcon} />
          <Text style={styles.email}>{basics.email}</Text>
        </View>
        <View style={styles.contentContainer}>
          <Image src={phone} style={styles.phoneIcon} />
          <Text style={styles.email}>{basics.phoneNumber}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Personal Statement</Text>
        <Text style={styles.sectionContent}>{summaryStatement}</Text>
      </View>
      {workExperience.length ? (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Work history</Text>
          {workExperience.map((section, i) => (
            <View key={`work-${i + 1}`}>
              <View style={styles.contentContainer}>
                <View style={styles.basicInfoContainer}>
                  <Text style={styles.sectionContentTitle}>
                    {section.companyName}
                  </Text>
                  <Text style={styles.sectionContent}>
                    {section.companyLocation}
                  </Text>
                  <Text style={styles.sectionContent}>
                    <Text>{section.employmentStartDate}</Text>
                    <Text> - </Text>
                    <Text>{section.isStillWorking ? 'Present' : section.employmentEndDate}</Text>
                  </Text>
                </View>
                <View>
                  <Text style={styles.sectionContentTitle}>{section.jobTitle}</Text>
                  <Text style={styles.sectionContent}>{section.duties}</Text>
                </View>
              </View>
              {section.references.map((reference, index) => (
                <View style={styles.basicInfoContainer} key={`reference_${index + 1}`}>
                  <View>
                    <Text style={styles.sectionContentTitle}>{reference.name}</Text>
                    <Text style={styles.nestedContent}>{reference.phone}</Text>
                    <Text style={styles.nestedContent}>{reference.email}</Text>
                  </View>
                  <Text style={styles.nestedContent}>{reference.reference}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      ) : null}
      {education.length ? (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Qualifications</Text>
          {education.map((section, i) => (
            <View style={styles.contentContainer} key={`education-${i + 1}`}>
              <View style={styles.basicInfoContainer}>
                <Text style={styles.sectionContentTitle}>{section.schoolName}</Text>
                {section.subjects.map((subject, index) => (
                  <View style={styles.contentContainer} key={`subject_${index + 1}`}>
                    <Text style={styles.nestedContent}>{subject.subject}</Text>
                    <Text style={styles.nestedContent}> - </Text>
                    <Text style={styles.nestedContent}>{subject.subjectPercentage}</Text>
                    <Text style={styles.nestedContent}> %</Text>
                  </View>
                ))}
                <Text style={styles.sectionContent}>{section.schoolLocation}</Text>
                <Text style={styles.sectionContent}>{section.isStillStudying ? 'Present' : section.schoolEndDate}</Text>
              </View>
              <View>
                <Text style={styles.sectionContentTitle}>{section.qualification}</Text>
              </View>
            </View>
          ))}
        </View>
      ) : null}
      {technicalSkills.length ? (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Technical skills</Text>
          {technicalSkills.map(({ skillName }, i) => (
            <Text style={styles.sectionContent} key={`technicalSkill-${i + 1}`}>- {skillName}</Text>
          ))}
        </View>
      ) : null}
      {personalSkills.length ? (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Personal skills</Text>
          {personalSkills.map(({ skillName, description }, i) => (
            <View key={`personalSkill-${i + 1}`}>
              <Text style={styles.sectionContentTitle}>{skillName}</Text>
              <Text style={styles.sectionContent}>{description}</Text>
            </View>
          ))}
        </View>
      ) : null}
      {volunteerExperience.length ? (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Community & volunteer experience</Text>
          {volunteerExperience.map((section, i) => (
            <View style={styles.contentContainer} key={`volunteer-${i + 1}`}>
              <View style={styles.basicInfoContainer}>
                <Text style={styles.sectionContentTitle}>
                  {section.organizationName}
                </Text>
                <Text style={styles.sectionContent}>
                  {section.organizationLocation}
                </Text>
                <Text style={styles.sectionContent}>
                  <Text>{section.volunteeringStartDate}</Text>
                  <Text> - </Text>
                  <Text>{section.isStillWorking ? 'Present' : section.volunteeringEndDate}</Text>
                </Text>
              </View>
              <View>
                <Text style={styles.sectionContentTitle}>{section.role}</Text>
                <Text style={styles.sectionContent}>{section.duties}</Text>
              </View>
            </View>
          ))}
        </View>
      ) : null}
      <Text
        fixed
        style={styles.footer}
      >
        {basics.fullName} | {basics.phoneNumber} | {basics.email}
      </Text>
      <Text
        fixed
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => (`Page ${pageNumber} of ${totalPages}`)}
      />
    </Page>
  </Document>
);

export default CvTemplate;
