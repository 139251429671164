import styled from 'styled-components';
import { colors } from 'common/colors';
import { Button as ButtonMUI, withStyles } from '@material-ui/core';

export const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.primary_white};
  border-radius: 10px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
`;

export const ImgWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${colors.primary_white};
  border-radius: 10px;
`;

export const ImgContainer = styled.img`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ControlArrow = styled.div<{
  position: 'left' | 'right';
}>`
  position: absolute;
  width: 25%;
  height: 100%;
  ${({ position }) => (position === 'left' ? 'left: 0;' : 'right: 0;')};
`;

export const DotsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 6px;
`;

export const Dot = styled.div<{
  isActive: boolean;
}>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 7px;
  background-color: ${({ isActive }) => (isActive ? colors.secondary_green : colors.primary_gray_light2)};
`;

export const InfoWrap = styled.div`
  padding: 15px;
  border-radius: 10px;
`;

export const ImgTitle = styled.h5`
  color: ${colors.primary_gray1};
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  svg {
    margin-right: 8px;
  }
`;

export const RoundButton = withStyles({
  root: {
    fontFamily: 'AppMulish',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '30px',
    textTransform: 'none',
    margin: '0 7.5px',
    padding: '10px',
    backgroundColor: colors.primary_white,
  },
  outlined: {
    border: 0,
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
    '&:hover': {
      boxShadow: '0px 4px 9px rgba(54, 52, 163, 0.3)',
      backgroundColor: 'inherit'
    }
  }
})(ButtonMUI);
