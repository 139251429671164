import { chooseComponent } from 'pages/courseControler/components/stepBuilder/StepBuilderService';
import { Step } from 'pages/pathway/Step';
import { colors } from 'common/colors';
import ProgressBar from 'common/progressBar/ProgressBar';
import {
  calculateProgressBasedOnSteps,
  getUserStatistics,
  getUserStoredDataForStep
} from 'pages/courseControler/components/userProgress/userProgressHelper';
import { getAllStepsInPathway } from 'pages/courseControler/components/navigation/withNavigationHelper';
import SaveMaterial from 'pages/courseControler/components/pathway/saveMaterial/SaveMaterial';
import { CheckboxesState } from 'stepComponents/checkboxList/CheckboxList';
import { StepTypeEnum } from './StepTypeEnum';

export type StepBuilderPropsType = {
  step: Step;
  isTab?: boolean;
};

function StepBuilder({ step, isTab }: StepBuilderPropsType): JSX.Element {
  const { stepType, stepComponents }: Step = step;
  const storedStepData = getUserStoredDataForStep(step.id);

  function getValueForElementFromStoredDataBasedOnElementId(elementId: unknown) {
    if (!elementId || !storedStepData) {
      return undefined;
    }
    return storedStepData[Object.keys(storedStepData)
      .filter(key => key.match(elementId as string))[0]];
  }

  const stepComponentsWithStoredData = stepComponents.map(stepComponent => {
    switch (stepComponent.__typename) {
      case 'ComponentStepInput':
        return {
          storedValue: getValueForElementFromStoredDataBasedOnElementId(stepComponent?._id) as string,
          ...stepComponent
        };
      case 'ComponentStepCheckboxList':
        return {
          storedValue: getValueForElementFromStoredDataBasedOnElementId(stepComponent._id) as CheckboxesState,
          ...stepComponent
        };
      case 'ComponentStepRadioList':
        return {
          storedValue: (getValueForElementFromStoredDataBasedOnElementId(stepComponent._id) as {
            label: string, value: string, selectedValue: string
          }),
          ...stepComponent
        };
      case 'ComponentStepRangeSlider':
        return {
          storedValue: getValueForElementFromStoredDataBasedOnElementId(stepComponent._id) as number,
          ...stepComponent
        };
      case 'ComponentStepCardNumbered':
        return {
          storedValue: getValueForElementFromStoredDataBasedOnElementId(stepComponent._id) as string,
          ...stepComponent
        };
      case 'ComponentStepRangeNumbered':
        return {
          storedValue: getValueForElementFromStoredDataBasedOnElementId(stepComponent._id) as number,
          ...stepComponent
        };
        default:
        return { ...stepComponent };
    }
  });

  const isStepType = (testedStepType: string): testedStepType is keyof typeof StepTypeEnum =>
    Object.keys(StepTypeEnum).indexOf(testedStepType as StepTypeEnum) !== -1;
  const maskedStepType = (stepType && isStepType(stepType))
    ? StepTypeEnum[stepType as keyof typeof StepTypeEnum] : stepType;

  const lSData = localStorage.getItem('currentTreadData');
  const { courseName } = JSON.parse(lSData || "{courseName: ''}");
  const { stepsFinishedInPathway } = getUserStatistics();
  const allStepsInCourse = getAllStepsInPathway().filter(step => step.courseName === courseName).length;
  const stepsFinishedInCourse = stepsFinishedInPathway.filter(step => step.courseName === courseName).length;
  const progress = calculateProgressBasedOnSteps(allStepsInCourse, stepsFinishedInCourse);
  return (
    <>
      { !isTab
      && (
        <> <ProgressBar progress={progress} />
          <SaveMaterial stepId={step.id} />
        </>
      )}
      <div className="body16Bold" style={{ color: `${colors.secondary_blue_light}` }}>{maskedStepType}
      </div>
      {stepComponentsWithStoredData.map(({ __typename, ...props }, index: number) =>
        chooseComponent({ __typename, props }, index))}
    </>
  );
}

export default StepBuilder;
