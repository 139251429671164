import {
  FC, SyntheticEvent, useEffect, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import userApi, { RegisterData } from 'utils/userApi';
import Button from 'common/button/Button';
import FormInput from 'common/formInput/FormInput';
import DotsLoader from 'common/dotsLoader/DotsLoader';
import useForm from 'utils/useForm';
import { useAuth } from 'utils/AuthContext';
import {
  RegisterPageWrap, Header, StyledLink, LoginLinkWrap, ErrorWrap
} from './RegisterPageStyles';

const RegisterPage: FC = () => {
  const {
    requestError,
    setRequestError
  } = useAuth();
  const {
    errors,
    handleInputChange,
    values,
    onSubmit
  } = useForm({
    email: '',
    password: ''
  });
  const [isSignUpInProgress, setIsSignUpInProgress] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(errors).length) {
      setRequestError('');
    }
  }, [errors]);

  useEffect(() => () => setIsSignUpInProgress(false), []);

  const handleSubmit = async () => {
    try {
      setIsSignUpInProgress(true);
      setRequestError('');
      const statusCode = await userApi.registerUser(values as RegisterData);
      if (statusCode === 200) {
        history.push('/login');
      }
    } catch (err) {
      setRequestError(err.message);
    } finally {
      setIsSignUpInProgress(false);
    }
  };

  return (
    <RegisterPageWrap>
      <Header isRequestError={!!requestError}>Sign up</Header>
      {requestError ? <ErrorWrap>{requestError}</ErrorWrap> : null}
      <form onSubmit={isSignUpInProgress ? (e: SyntheticEvent) => e.preventDefault() : onSubmit(handleSubmit)}>
        <FormInput
          fullWidth
          variant="outlined"
          name="email"
          label="Email"
          placeholder="Type your email"
          onChange={handleInputChange}
          type="email"
          value={values.email}
          error={!!errors.email}
          helperText={errors.email}
        />
        <FormInput
          fullWidth
          variant="outlined"
          type="password"
          name="password"
          label="Password"
          placeholder="Type your password"
          onChange={handleInputChange}
          value={values.password}
          error={!!errors.password}
          helperText={errors.password}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
        >
          {isSignUpInProgress ? <DotsLoader /> : 'Sign up'}
        </Button>
        <LoginLinkWrap>
          Already have an account?
          {' '}
          <StyledLink to="/login">Log in</StyledLink>
        </LoginLinkWrap>
      </form>
    </RegisterPageWrap>
  );
};

export default RegisterPage;
