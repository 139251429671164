import { FC } from 'react';
import { LabelWrap, ScoresWrap } from './DoneElementsDisplayStyles';

export type DoneElementsPropsType = {
  label: string;
  earned: number;
  max: number;
};
const DoneElementsDisplay: FC<DoneElementsPropsType> = ({
 label,
 earned,
 max
}) => (
  <ScoresWrap>
    <LabelWrap className="labelMobile12Bold">{label}</LabelWrap>
    <span className="body16Regular">{earned} of {max}</span>
  </ScoresWrap>
);

export default DoneElementsDisplay;
