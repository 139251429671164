import { ChangeEvent, FunctionComponent } from 'react';
import { TooltipColorType } from 'stepComponents/tooltip/Tooltip';
import { CheckboxListStylesEnum } from 'stepComponents/checkboxList/CheckboxListStyles';
import {
  CheckboxWrap, CheckboxIcon, StyledCheckboxMUI, Label
} from './CheckboxStyles';
import { Tooltip } from '../index';

export type CheckboxPropsType = {
  name: string;
  label: string;
  checked: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  showTooltip?: boolean;
  tooltipColor?: TooltipColorType;
  tooltipContent?: string;
  listStyle?: CheckboxListStylesEnum
};

const Checkbox: FunctionComponent<CheckboxPropsType> = ({
  name,
  label,
  checked,
  handleChange,
  showTooltip,
  tooltipColor,
  tooltipContent,
  listStyle = CheckboxListStylesEnum.default
}) => (
  <CheckboxWrap>
    <Label
      listStyle={listStyle}
      className="button16Semibold"
    >
      <StyledCheckboxMUI
        color="default"
        onChange={handleChange}
        checked={checked}
        name={name}
        icon={<CheckboxIcon listStyle={listStyle} />}
        checkedIcon={<CheckboxIcon listStyle={listStyle} checked />}
        disableRipple
        disableTouchRipple
        disableFocusRipple
      />
      <span>{ label }</span>
    </Label>
    {showTooltip ? (
      <Tooltip
        backgroundColor={tooltipColor}
        content={tooltipContent || ''}
      />
    ) : null}
  </CheckboxWrap>
);

export default Checkbox;
