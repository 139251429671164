import React, { FC, useState } from 'react';
import { ReactComponent as CyclopsWithCard } from 'assets/img/cyclops_with_card.svg';
import { ReactComponent as HappyCyclops } from 'assets/img/happy_cyclops.svg';
import { ReactComponent as WhatHappenedCyclops } from 'assets/img/what_happened_cyclops.svg';
import CloseButton from 'common/cardCloseButton/CloseButton';
import Description from 'stepComponents/description/Description';
import { TooltipWrap, DescriptionWrap } from './TooltipStyles';

export type TooltipColorType = 'primary_gray_light5' | 'primary_aquamarine' |
  'primary_white' | 'secondary_crimson' | 'secondary_cyan' | 'success' | 'failure';

export type TooltipPropTypes = {
  backgroundColor?: TooltipColorType,
  icon?: 'cyclops_with_card' | 'happy_cyclops' | 'what_happened_cyclops',
  iconPosition?: 'topLeft' | 'right',
  closeButton?: boolean,
  onCloseButton?: () => void;
  title?: string,
  content: string
};

const Tooltip: FC<TooltipPropTypes> = ({
  backgroundColor,
  icon,
  iconPosition,
  title,
  content,
  closeButton,
  onCloseButton
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  let iconImg: JSX.Element | undefined;
  switch (icon) {
    case 'cyclops_with_card': iconImg = <CyclopsWithCard />; break;
    case 'happy_cyclops': iconImg = <HappyCyclops />; break;
    case 'what_happened_cyclops': iconImg = <WhatHappenedCyclops />; break;
    default: iconImg = undefined;
  }

  const handleClose = () => setIsOpen(false);

  if (!isOpen) return null;

  return (
    <TooltipWrap background={backgroundColor || 'primary_gray_light5'}>
      <div className="heading">
        <div className="title">{iconImg && iconPosition === 'topLeft' ? iconImg : null}
          {title ? <h4>{title}</h4> : null}
        </div>
        {closeButton ? <CloseButton onClose={onCloseButton || handleClose} /> : null}
      </div>
      <DescriptionWrap>
        <Description content={content} className="body16Regular" />
        {iconImg && iconPosition === 'right' ? (
          <span>{iconImg}</span>
        ) : null}
      </DescriptionWrap>
    </TooltipWrap>
  );
};

export default Tooltip;
