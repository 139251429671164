import { FC } from 'react';
import { usePDF } from '@react-pdf/renderer';
// import HTMLtoDOCX from 'html-to-docx';
import { saveAs } from 'file-saver';
import CvTemplate from 'pages/cvBuilder/CvTemplate';
import cvDocTemplate from 'pages/cvBuilder/CVDocTemplate';
import Button from 'common/button/Button';
import downloadFile from 'utils/downloadFile';
import { useCvBuilder } from 'utils/CvBuilderContext';
import DotsLoader from 'common/dotsLoader/DotsLoader';
import Description from 'stepComponents/description/Description';
import { StepHeader } from 'pages/cvBuilder/steps/styles';
import { GetCvWrap } from './GetCvStyles';

const GetCv: FC = () => {
  const { values, handleDownloadedChange, handleUpdateCv } = useCvBuilder();
  const [instance] = usePDF({
    document: CvTemplate({
      basics: values.basics,
      education: values.education,
      workExperience: values.workExperience,
      volunteerExperience: values.volunteerExperience,
      technicalSkills: values.technicalSkills,
      personalSkills: values.personalSkills,
      summaryStatement: values.summaryStatement
    })
  });

  const handleClickPDF = async () => {
    if (!instance.loading && !instance.error && instance.url) {
      downloadFile(instance.url, 'cv.pdf');
      // mark that CV was downloaded
      handleDownloadedChange();
      handleUpdateCv(true);
      }
  };

  const handleClickDoc = async () => {
    if (!instance.loading && !instance.error && instance.url) {
      const docTemplate = cvDocTemplate(values);
      //* for custom blob
      const preHtml = `
      <html
        xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:w="urn:schemas-microsoft-com:office:word"
        xmlns="http://www.w3.org/TR/REC-html40">
        <head><meta charset="utf-8"><title>CV</title></head>
        <body>`;
      const postHtml = '</body></html>';
      const html = preHtml + docTemplate + postHtml;
      const data = new Blob(['\ufeff', html], {
        type: 'application/msword'
    });
      // const data = await HTMLtoDOCX(docTemplate, { footer: true, pageNumber: true });
      saveAs(data, 'cv.doc');
      // mark that CV was downloaded
      handleDownloadedChange();
      handleUpdateCv(true);
      }
  };

  return (
    <GetCvWrap>
      <StepHeader isDescription>
        Great job!
      </StepHeader>
      <Description
        className="body16Regular"
        content="You can now get your CV by clicking button below!"
      />
      <Button
        fullWidth
        variant="contained"
        onClick={handleClickPDF}
      >
        {instance.loading ? <DotsLoader /> : 'Click here to download PDF'}
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={handleClickDoc}
      >
        {instance.loading ? <DotsLoader /> : 'Click here to download DOC'}
      </Button>
    </GetCvWrap>
  );
};

export default GetCv;
