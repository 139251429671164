import React, { FunctionComponent } from 'react';
import CircleWithChar from 'common/circleWithChar/CircleWithChar';
import Description from 'stepComponents/description/Description';
import Input from 'stepComponents/input/Input';
import { CardNumberedWrap, Subtitle, Divider } from './CardNumberedStyles';

export type CardNumberedPropsType = {
  _id?: string;
  cardNumber: string | number;
  subtitle?: string;
  title?: string;
  content: string;
  isInput?: boolean;
  inputLabel?: string;
  inputPlaceholder?: string
  storedValue? : string;
};

const CardNumbered: FunctionComponent<CardNumberedPropsType> = ({
  _id,
  cardNumber,
  subtitle,
  title,
  content,
  isInput,
  inputLabel,
  inputPlaceholder,
  storedValue
}) => (
  <CardNumberedWrap>
    <CircleWithChar char={cardNumber} />
    {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    {title ? <Description content={title} className="body16Bold" /> : null}
    <Description content={content} className="body16Regular" />
    {isInput ? (
      <>
        <Divider />
        <Input label={inputLabel} placeholder={inputPlaceholder} storedValue={storedValue} parentId={_id} />
      </>
    ) : null}
  </CardNumberedWrap>
);

export default CardNumbered;
