import { KnowledgeBites } from './KnowledgeBites';
import { BadgeInterface, MindfulnessBreakInterface } from './pathway.interface';

export class Topic {
  name:string;

  id: string;

  description?: string;

  what_you_will_learn?: { name: string }[];

  what_you_will_do?: { name: string }[];

  knowledgeBites: KnowledgeBites[];

  badges?: BadgeInterface;

  mindfulness_break: MindfulnessBreakInterface;

  orderNumber: number;

  constructor(
    name: string,
    knowledgeBites?: KnowledgeBites[],
    id?: string,
    mindfulnessBreak?: MindfulnessBreakInterface,
    orderNumber?: number
  ) {
    this.name = name;
    this.knowledgeBites = knowledgeBites || [new KnowledgeBites('new knowledge bite')];
    this.id = id || 'noId';
    this.mindfulness_break = mindfulnessBreak || { description: undefined, videoUrl: undefined };
    this.orderNumber = orderNumber || 1;
  }
}
