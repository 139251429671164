import styled from 'styled-components';
import { colors } from 'common/colors';

export const CardWrap = styled.div<{
  isItemOver: boolean;
}>`
  display: flex;
  margin: 13px 0;
  padding: 5px 0;
  border-radius: 10px;
  background-color: ${({ isItemOver }) => (isItemOver ? colors.primary_gray_light5 : colors.primary_white)};
`;

export const CardContent = styled.div<{
  hasLetter: boolean;
}>`
  width: 48px;
  height: 48px;
  min-width: 48px;
  background-color: ${colors.primary_gray_light4};
  border-radius: 10px;
  ${({ hasLetter }) => (!hasLetter && `border: 1px dashed ${colors.primary_gray5};`)}
`;

export const CardTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const IconWrap = styled.div`
  position: relative;
  float: right;
  top: -54px;
  right: -6px;
  svg {
    width: 18px;
    height: 18px;
  }
`;
