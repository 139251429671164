import { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';

export const ListItemWrap = styled.div<{
  children: ReactNode
}>`
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 3px 9px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  user-select: none;
  touch-action: none;
  color: ${colors.primary_indigo};
  border: ${({ children }) => (
    children ? `1px solid ${colors.primary_gray_light1}` : `1px dashed ${colors.primary_gray5}`
  )};
  background-color: ${({ children }) => (children ? colors.primary_white : colors.primary_gray_light4)};
`;
