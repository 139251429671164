export const colors = {
  // blacks and grays
  primary_white: '#FFFFFF',
  primary_black: '#000000',
  primary_gray1: '#21252A',
  primary_gray2: '#343A40',
  primary_gray3: '#495057',
  primary_gray4: '#868E96',
  primary_gray5: '#ADB5BD',
  primary_gray_light1: '#CED4DA',
  primary_gray_light2: '#DEE2E6',
  primary_gray_light3: '#E9ECEF',
  primary_gray_light4: '#F1F3F5',
  primary_gray_light5: '#F8F9FA',
  background_col: '#E5E5E5',

  // colors
  primary_orange: '#E57A00',
  secondary_orange: '#E3562A',
  primary_red: '#E53535',
  secondary_red: '#EF4949',
  primary_yellow: '#E5B800',
  secondary_yellow: '#F2A03F',
  primary_green: '#05A660',
  secondary_green: '#5BA092',
  primary_cream: '#F8F2EE',
  primary_cyan: '#00B7C4',
  primary_indigo: '#3634A3',
  secondary_blue: '#004FC4',
  secondary_blue_light: '#65AAEA',
  primary_aquamarine: '#E6F7F9',
  secondary_crimson: '#FAB1AD',
  secondary_cyan: '#BCEAF9',

  primary_indigo_shade1: '#A9BDFF',
  primary_indigo_shade2: '#D4DEFF',
  primary_indigo_shade3: '#F4F9FF',

  badge_of_readiness_beginner: '#F0BB65',
  // primary orange
  badge_of_readiness_intermediate: '#E57A00',
  // secondary orange
  badge_of_readiness_expert: '#E3562A',
  // primary green
  badge_of_growth_beginner: '#05A660',
  // secondary blue light
  badge_of_growth_intermediate: '#65AAEA',
  badge_of_growth_expert: '#9B51E0',

  success: '#BCEAF9',
  failure: '#FAB1AD'
};
