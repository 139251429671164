import { FC } from 'react';
import WhatsNext from 'stepComponents/whatsNext/WhatsNext';
import WithNavigation from 'pages/courseControler/components/navigation/WithNavigation';
import BadgeWithStarsIcon from 'common/badgeWithStarsIcon/BadgeWithStarsIcon';
import useAddBadge from 'utils/useAddBadge';
import AppSpinner from 'common/appSpinner/AppSpinner';
import {
  CompletedCourseWrap, BadgeCard, GifContainer, Gif
} from './CompletedCourseStyles';

const CompletedCourse: FC = () => {
  const lSData = localStorage.getItem('currentTreadData');
  const {
    id: courseId,
    courseOrder,
    badges,
    name,
    gifUrl,
    mindfulnessBreak
  } = JSON.parse(lSData || '{name: \'fallback Course\'}');
  const { isAddingBadgeInProgress } = useAddBadge({ courseId });
  return (
    <WithNavigation level="completedCourse">
      <CompletedCourseWrap>
        { isAddingBadgeInProgress ? <AppSpinner /> : (
          <>
            <h3>Awesome!</h3>
            <h4>You&apos;ve completed Course {courseOrder + 1}!</h4>
            { badges && (
              <BadgeCard>
                <h4 className="badge-info">You have earned a<br />Badge of {badges.type}</h4>
                <BadgeWithStarsIcon badgeType={badges.type} badgeLevel={badges.level} />
                <h4 className="badge-name" color={badges.color}>{name} {badges.level}</h4>
              </BadgeCard>
            )}
            {gifUrl ? (
              <GifContainer>
                <Gif src={gifUrl} alt="gif" />
              </GifContainer>
            ) : null}
            <WhatsNext variant="completedCourse" parentId={courseId} mindfulnessBreak={mindfulnessBreak} />
          </>
        )}
      </CompletedCourseWrap>
    </WithNavigation>
  );
};

export default CompletedCourse;
