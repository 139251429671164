import { BasicsSectionType } from 'pages/cvBuilder/steps/basics/Basics';
import { EducationSectionType } from 'pages/cvBuilder/steps/education/Education';
import { WorkExperienceSectionType } from 'pages/cvBuilder/steps/workExperience/WorkExperience';
import { VolunteerExperienceSectionType } from 'pages/cvBuilder/steps/volunteerExperience/VolunteerExperience';
import { TechnicalSkillsSectionType } from 'pages/cvBuilder/steps/technicalSkills/TechnicalSkills';
import { PersonalSkillsSectionType } from 'pages/cvBuilder/steps/personalSkills/PersonalSkills';

// toDo move styles to separate css
//* allowed styles
//* https://grabz.it/support/article/html-to-docx-supported-css/
type CvDocTemplateProps = {
  basics: BasicsSectionType;
  education: EducationSectionType[];
  workExperience: WorkExperienceSectionType[];
  volunteerExperience: VolunteerExperienceSectionType[];
  technicalSkills: TechnicalSkillsSectionType[];
  personalSkills: PersonalSkillsSectionType[];
  summaryStatement: string;
};

const cvDocTemplate = ({
  basics,
  education,
  workExperience,
  volunteerExperience,
  technicalSkills,
  personalSkills,
  summaryStatement
}: CvDocTemplateProps): string => {
  const workSection = workExperience.map((section, i) => (
    `<table width=600 key='work-${i + 1}'>
      <tr>
        <th colspan="4" style="padding: 0px">
          <p style="font-size: 14px; font-weight: bold; padding:5px;">
  2.${i + 1} ${section.companyName}
          </p>
        </th>
      </tr>
      <tr>
        <td style="font-size: 13px; padding: 0px;">
  ${section.companyLocation}
        </td>
        <td style="color: #868E96; font-size: 12px; padding: 0px;">
  ${section.employmentStartDate} - ${section.isStillWorking ? 'Present' : section.employmentEndDate}
        </td>
        <td style="font-size: 14px; color: #868E96; padding: 0px;">
  ${section.jobTitle}
        </td>
        <td style="color: #868E96; font-size: 10px; padding: 0px;">
  Duties: ${section.duties}
        </td>
      </tr>
      <tr>
        <th colspan="4" style="padding: 0px;">
          <p style="font-size: 14px; font-weight: bold; padding:5px;">
  2.${i + 1}.1 References:
          </p>
        </th>
      </tr>
        ${section.references.map((reference, index) => (
          `<tr key='reference-${index + 1}'>
              <td style="font-size: 12px; padding: 0px;">
  ${reference.name}
              </td>
              <td style="font-size: 9px; color: #868E96; padding: 0px;">
  ${reference.phone}
              </td>
              <td style="font-size: 9px; color: #868E96; padding: 0px;">
  ${reference.email}
              </td>
              <td style="font-size: 9px; color: #868E96; padding: 0px;">
  ${reference.reference}
              </td>
          </tr>`
        ))}
      </table>`
  ));
  const educationSection = education.map((section, i) => (
    `<table width=600 key='education-${i + 1}'>
      <tr>
        <th colspan="3">
          <p style="font-size: 14px; font-weight: bold; padding:5px;">
  3.${i + 1} ${section.schoolName}
          </p>
        </th>
      </tr>
        ${section.subjects.map((subject, index) => (
          `<tr key='subject-${index + 1}'>
            <td colspan="3">
              <p style="font-size: 13px; color: #868E96;">
  Subject: ${subject.subject} - ${subject.subjectPercentage} %
              </p>
            </td>
          </tr>`
        ))}
      <tr>
        <td>
          <p style="font-size: 13px;">
  ${section.schoolLocation}
          </p>
        </td>
        <td>
          <p style="font-size: 12px;;">
  ${section.isStillStudying ? 'Present' : section.schoolEndDate}
          </p>
        </td>
        <td>
         <p style="font-size: 12px;">
  Degree: ${section.qualification}
          </p>
        </td>
      </tr>
    </table>`
  ));
  const technicalSkillsSection = technicalSkills.map(({ skillName }, i) => (
    `<table width=600>
      <tr>
        <th>
        <p style="font-size: 13px; font-weight: bold;" key='technicalSkill-${i + 1}'>
  4.${i + 1} ${skillName}
        </p>
        </th>
      </tr>
    </table>`
  ));
  const personalSkillsSection = personalSkills.map(({ skillName, description }, i) => (
    `<table width=600 key='personalSkill-${i + 1}'>
      <tr>
        <td>
          <p style="font-size: 13px;; font-weight: bold;">
  5.${i + 1} ${skillName}
          </p>
        </td>
        <td>
          <p style="color: #868E96; font-size: 11px;;">
  ${description}
          </p>
        </td>
      </tr>
    </table>`
  ));
  const volunteerExperienceSection = volunteerExperience.map((section, i) => (
    `<table width=600 key='volunteer-${i + 1}'>
      <tr>
        <th colspan="4" style="padding: 0px;">
          <p style="font-size: 14px; font-weight: bold;">
  6.${i + 1} ${section.organizationName}
          </p>
        </th>
      </tr>
      <tr>
        <td style="padding: 0px;">
          <p style="font-size: 13px;">
  ${section.organizationLocation}
          </p>
        </td>
        <td style="padding: 0px;">
          <p style="color: #868E96; font-size: 13px;">
  ${section.volunteeringStartDate} - ${section.isStillWorking ? 'Present' : section.volunteeringEndDate}
          </p>
        </td>
        <td style="padding: 0px;">
          <p style="font-size: 13px; color: #868E96;">
  ${section.role}
          </p>
        </td>
        <td style="padding: 0px;">
          <p style="color: #868E96; font-size: 13px;">
  Duties: ${section.duties}
          </p>
        </td>
      </tr>
    </table>`
  ));
  return `
<div>
  <h2>${basics.fullName}</h2>
  <h3>RSA ID: ${basics.idNumber}</h3>
  <p style="color: #868E96; font-size: 13px;">Email: ${basics.email}</p>
  <p style="color: #868E96; font-size: 13px;">Phone: ${basics.phoneNumber}</p>
  <h3>
1. Personal Statement
  </h3>
  <p style="color: #868E96; font-size: 13px;">
    ${summaryStatement}
  </p>
  ${workExperience.length ? (
    `<section>
      <h3 style="padding:5px;">
2. Work history
      </h3>
        ${workSection}
     </section>`
    ) : ''}
   ${education.length ? (
     `<section>
        <h3 style="padding:5px;">
3. Qualifications
        </h3>
      ${educationSection}
      </section>`
    ) : ''}
          ${technicalSkills.length ? (
            `<section>
              <h3 style="padding:5px;">
4. Technical skills
              </h3>
              ${technicalSkillsSection}
            </section>`
          ) : ''}
          ${personalSkills.length ? (
            `<section>
              <h3 style="padding:5px;">
5. Personal skills
              </h3>
              ${personalSkillsSection}
            </section>`
          ) : ''}
          ${volunteerExperience.length ? (
            `<section>
              <h3 style="padding:5px;">
6. Community & volunteer experience
              </h3>
              ${volunteerExperienceSection}
            </section>`
          ) : ''}
          <p style="font-size: 10px; color: #ADB5BD; line-height: 50px;">
${basics.fullName || ''} | ${basics.phoneNumber || ''} | ${basics.email || ''}
          </p>
        </div>`;
      };
export default cvDocTemplate;
