import { colors } from 'common/colors';
import styled from 'styled-components';
import { ReactComponent as BadgeIconWithStars } from 'assets/img/growth_badge.svg';

export const BadgeIconWithStarsWrap = styled(BadgeIconWithStars)<{
  color: string;
}>`
   .left-ribbon {
     fill: ${props => props.color};
   }
  
  .right-ribbon {
    fill: ${props => props.color};
  }

  .ring-with-star {
    fill: ${props => props.color};
  }
  
  .star {
    fill: ${colors.primary_gray_light3};
  }
`;
