import React, { FC } from 'react';
import FormInput from 'common/formInput/FormInput';
import Tooltip from 'stepComponents/tooltip/Tooltip';
import { ReactComponent as MinusIcon } from 'assets/img/minus_icon.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus_icon.svg';
import Radio from 'stepComponents/radio/Radio';
import { useCvBuilder } from 'utils/CvBuilderContext';
import {
  StepWrap, StepHeader, SectionWrap, SectionHeader, AddSectionButton, AddSectionIconWrap, RemoveSectionButton
} from '../styles';

export type EducationSubjectInputPropertiesType = {
  subject: string;
  subjectPercentage: string;
};

export type EducationTextInputPropertiesType = {
  schoolName: string;
  schoolLocation: string;
  qualification: string;
  schoolEndDate: string;
  subjects: EducationSubjectInputPropertiesType[];
};

export type EducationRadioInputPropertiesType = {
  isStillStudying: boolean;
};

export type EducationSectionType = EducationTextInputPropertiesType & EducationRadioInputPropertiesType;

const Education: FC = () => {
  const {
    values: { education },
    handleChange,
    handleAddClick,
    handleRemoveClick
  } = useCvBuilder();

  return (
    <StepWrap>
      <StepHeader>
        Education
      </StepHeader>
      { education.map((section, i) => (
        <SectionWrap key={`entry-${i + 1}`}>
          <SectionHeader>
            Entry {i + 1}
            <RemoveSectionButton
              onClick={() => handleRemoveClick('education', i)}
            >
              <MinusIcon />
            </RemoveSectionButton>
          </SectionHeader>
          <FormInput
            fullWidth
            variant="outlined"
            name="schoolName"
            label="I attend: [type school name]"
            value={section.schoolName}
            onChange={e => handleChange('education')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="schoolLocation"
            label="in [city, country]"
            value={section.schoolLocation}
            onChange={e => handleChange('education')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="qualification"
            label="The qualification I received is:"
            placeholder="[level of qualification]"
            value={section.qualification}
            onChange={e => handleChange('education')(e, i)}
          />
          <SectionWrap>
            {section.subjects.map((subject, insideIndex) => (
              <React.Fragment key={`subject_${insideIndex + 1}`}>
                <SectionHeader>
                  Subject {insideIndex + 1}
                  <RemoveSectionButton
                    onClick={() => handleRemoveClick('education', i, 'subjects', insideIndex)}
                  >
                    <MinusIcon />
                  </RemoveSectionButton>
                </SectionHeader>
                <FormInput
                  fullWidth
                  variant="outlined"
                  name="subject"
                  label="The subject I received is:"
                  value={subject.subject}
                  onChange={e => handleChange('education', 'subjects')(e, i, insideIndex)}
                />
                <FormInput
                  fullWidth
                  variant="outlined"
                  name="subjectPercentage"
                  label="Amount of percentage I received is:"
                  value={subject.subjectPercentage}
                  onChange={e => handleChange('education', 'subjects')(e, i, insideIndex)}
                />
              </React.Fragment>
            ))}
            <AddSectionButton onClick={() => handleAddClick('education', 'subjects', i)}>
              <AddSectionIconWrap>
                <PlusIcon />
              </AddSectionIconWrap>
              Add subject entry
            </AddSectionButton>
          </SectionWrap>
          {i === 0 && (
            <Tooltip
              closeButton
              backgroundColor="primary_aquamarine"
              title="Tip"
              content="For example: Baccalaureate"
            />
          )}
          <Radio
            name="isStillStudying"
            label="I am currently studying here"
            value="true"
            checked={String(section.isStillStudying) === 'true'}
            handleChange={e => handleChange('education')(e, i)}
          />
          <Radio
            name="isStillStudying"
            label="I completed my studies"
            value="false"
            checked={String(section.isStillStudying) === 'false'}
            handleChange={e => handleChange('education')(e, i)}
          />
          {!section.isStillStudying && (
            <FormInput
              fullWidth
              variant="outlined"
              name="schoolEndDate"
              label="I completed my studies in [year]"
              value={section.schoolEndDate}
              onChange={e => handleChange('education')(e, i)}
              disabled={section.isStillStudying}
            />
          )}
        </SectionWrap>
      ))}
      <AddSectionButton onClick={() => handleAddClick('education')}>
        <AddSectionIconWrap>
          <PlusIcon />
        </AddSectionIconWrap>
        Add education entry
      </AddSectionButton>
    </StepWrap>
  );
};

export default Education;
