import React, { FC } from 'react';
import CircleWithIcon from 'common/circleWithIcon/CircleWithIcon';
import { BadgesLevelsEnum, BadgesTypesEnum } from 'pages/badges/BadgesEnum';
import { InfoCardWrap } from './InfoCardStyles';

type InfoCardPropsType = {
  variant: 'certificate' | 'badge';
  badgeLevel?: BadgesLevelsEnum;
  badgeType?: BadgesTypesEnum;
  backgroundColor?: string;
  circleBackgroundColor?: string;
  children?: React.ReactNode;
};

const InfoCard: FC<InfoCardPropsType> = ({
  variant,
  badgeLevel,
  badgeType,
  backgroundColor,
  circleBackgroundColor,
  children
}) => (
  <InfoCardWrap backgroundColor={backgroundColor}>
    <CircleWithIcon
      variant={variant}
      badgeType={badgeType}
      badgeLevel={badgeLevel}
      backgroundColor={circleBackgroundColor}
    />
    {children}
  </InfoCardWrap>
);

export default InfoCard;
