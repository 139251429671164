import axios, { AxiosError } from 'axios';

const isAxiosError = <T>(error: unknown): error is AxiosError<T> => axios.isAxiosError(error);

const handleTypeError = (error: Error) => (error.name === 'TypeError' ? new Error('Unexpected error') : error);

export const handleStrapiError = (error: Error | AxiosError): void => {
  if (isAxiosError(error)) {
    try {
      throw new Error(error.response?.data.message[0].messages[0].message);
    } catch (e) {
      throw handleTypeError(e);
    }
  }
  throw error;
};
