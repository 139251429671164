import styled from 'styled-components';

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  padding: 25px 30px;
  height: 86px;
  position: fixed;
  top: 0;
  left: 0;
`;
