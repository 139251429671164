import React from 'react';
import { Description } from 'stepComponents';
import Quote, { QuotePropTypes } from 'common/quote/Quote';
import { AboutTabWrap } from './AboutTabStyles';

type AboutTabPropsType = {
  content: string[],
  quote: QuotePropTypes
};

function AboutTab({
  content,
  quote
                  }: AboutTabPropsType): JSX.Element {
  return (
    <AboutTabWrap>
      <div className="h5">About Pathway</div>
      { content.map((description, index: number) =>
        // eslint-disable-next-line react/no-array-index-key
        <Description className="body16Regular description" content={description} key={`aboutDescription-${index}`} />)}

      {quote && <Quote content={quote.content} author={quote.author} />}
    </AboutTabWrap>
  );
}

export default AboutTab;
