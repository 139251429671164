import { FunctionComponent } from 'react';
import { CardsListWrap } from './CardsListStyles';
import Card, { CardProps } from './card/Card';

export type CardsListPropsType = {
  cardsListItems: CardProps[];
};

const CardsList: FunctionComponent<CardsListPropsType> = ({ cardsListItems }) => (
  <CardsListWrap>
    {cardsListItems.map(({ title, img, imgDescription }) => (
      <Card title={title} img={img} imgDescription={imgDescription} key={title} />
    ))}
  </CardsListWrap>
);

export default CardsList;
