import styled from 'styled-components';

export const BadgesInfoListWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const BadgeItem = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
`;
