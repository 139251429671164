import styled from 'styled-components';
import { colors } from '../colors';

export const InfoCardWrap = styled.div<{
backgroundColor?: string;
}>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  background-color: ${props => props.backgroundColor || colors.primary_gray_light5};
`;
