import React from 'react';
import { ReactComponent as DefaultImg } from 'assets/img/default_image.svg';
import AppButton from 'common/appButton/AppButton';
import { CertificateTabWrap } from './CertificateTabStyles';

type CertificateTabPropsType = {
  generatedCertificate?: React.ReactNode;
  name: string;
  description: string
};

function CertificateTab({
                          generatedCertificate,
                          name,
                          description
                        }: CertificateTabPropsType): JSX.Element {
  return (
    <CertificateTabWrap>
      { generatedCertificate || <DefaultImg /> }
      <div className="h4 heading">{name}</div>
      <div className="body16Regular content">{description}</div>
      <AppButton text="Download" option="outline_disabled" fullWidth={true} />
    </CertificateTabWrap>
  );
}

export default CertificateTab;
