import { useState } from 'react';
import {
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverEvent,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  SensorDescriptor,
  SensorOptions,
  TouchSensor
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

type ItemsType = {
  root: string[];
  container1: string[];
  container2: string[];
};

type UseDragNDropReturnType = {
  items: ItemsType;
  sensors: SensorDescriptor<SensorOptions>[];
  handleDragStart: (event: DragStartEvent) => void;
  handleDragOver: (event: DragOverEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
};

const useDragNDrop = (rootItems: { name: string }[]): UseDragNDropReturnType => {
  const [, setActiveId] = useState<string | null>();
  const [items, setItems] = useState<ItemsType>({
    root: rootItems.map(el => el.name),
    container1: [],
    container2: []
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    }),
    useSensor(TouchSensor)
  );

  const findContainer = (id: string) => {
    if (id in items) return id;

    return Object.keys(items).find(key => items[key as keyof ItemsType].includes(id));
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const { id } = active;
    setActiveId(id);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const { id } = active;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(over?.id as string);

    if (
      !activeContainer
      || !overContainer
      || activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer as keyof ItemsType];
      const overItems = prev[overContainer as keyof ItemsType];

      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(over?.id as string);

      let newIndex;
      if (over?.id as string in prev) {
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem = over
          && overIndex === overItems.length - 1;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer as keyof ItemsType].filter((item) => item !== active.id)
        ],
        [overContainer]: [
          ...prev[overContainer as keyof ItemsType].slice(0, newIndex),
          items[activeContainer as keyof ItemsType][activeIndex],
          ...prev[overContainer as keyof ItemsType].slice(newIndex, prev[overContainer as keyof ItemsType].length)
        ]
      };
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const { id } = active;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(over?.id as string);

    if (
      !activeContainer
      || !overContainer
      || activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer as keyof ItemsType].indexOf(active.id);
    const overIndex = items[overContainer as keyof ItemsType].indexOf(over?.id as string);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer as keyof ItemsType], activeIndex, overIndex)
      }));
    }
    setActiveId(null);
  };

  return {
    items,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd
  };
};

export default useDragNDrop;
