import styled from 'styled-components';
import { colors } from 'common/colors';

export const EntranceWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 85px 30px 120px;
`;

export const TopicHeader = styled.div`
  margin: 10px 0 30px;
  color: ${colors.primary_gray1};
  
  .topic-name {
    margin-top: 5px;
  }
`;

export const Description = styled.div`
  margin-bottom: 30px;
`;

export const TitledListWrap = styled.div`
  margin-bottom: 25px;
`;

export const InfoCardContent = styled.span`
  margin-bottom: 10px;
`;
