import styled from 'styled-components';
import { colors } from 'common/colors';

export const ProgressBarWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 20px;
`;

export const Label = styled.div`
  margin-bottom: 5px;
  color: ${colors.primary_gray2};
`;

export const ProgressNumber = styled.div`
  margin-bottom: 5px;
`;

export const ProgressWrap = styled.div<{
  frontBarColor?: string;
  backBarColor?: string;
  progress: number;
}>`
  background-color: ${props => props.backBarColor || colors.primary_gray_light3};
  border-radius: 25px;
  height: 15px;
  width: 100%;
  .frontBar {
    background-color: ${props => props.frontBarColor || colors.secondary_green};
    border-radius: 25px;
    height: 15px;
    width: ${props => props.progress}%;
    transition: width 1s ease-in-out;
    z-index: 6;
  }
`;
