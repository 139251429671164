import styled from 'styled-components';

export const CertificateTabWrap = styled.div`
  padding: 25px 0 0;
  display: flex;
  flex-direction: column;
  
  svg {
    align-self: center;
  }
  .heading {
    margin: 15px 0 5px;
  }
  
  .content {
    margin-bottom: 15px;
  }
  
`;
