import { FC } from 'react';
import Description from 'stepComponents/description/Description';
import { StepWrap, StepHeader } from '../styles';

const Greeting: FC = () => (
  <StepWrap>
    <StepHeader isDescription>
      Welcome to the CV Builder.
    </StepHeader>
    <Description
      className="body16Regular"
      content={'The CV is an important component of your job search. It may feel overwhelming to start. '
      + 'This tool will walk you through a template you can use to create a CV that can be shared with employers. '
      + 'Remember to return to the CV Builder when you want to improve or adjust your CV.'}
    />
  </StepWrap>
);

export default Greeting;
