import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import FooterMenu from 'pages/main/footer-menu/FooterMenu';
import { MainRouteWrap } from './ProtectedRouteStyles';

type ProtectedRoutePropTypes = {
  component: FC<RouteComponentProps>;
  isAccessible: boolean;
  isMainRoute: boolean;
  redirectToWhenInaccessible: string;
  exact: boolean;
  path: string;
  strict?: boolean;
};

const render = (
  isAccessible: boolean,
  isMainRoute: boolean,
  Component: FC<RouteComponentProps>,
  redirectToWhenInaccessible: string
) => (props: RouteComponentProps) => {
  if (isAccessible) {
    return isMainRoute ? (
      <MainRouteWrap>
        <Component {...props} />
        <FooterMenu />
      </MainRouteWrap>
    ) : <Component {...props} />;
  }
  return <Redirect to={{ pathname: redirectToWhenInaccessible }} />;
};

const ProtectedRoute: FC<ProtectedRoutePropTypes> = ({
  component, isAccessible, isMainRoute, redirectToWhenInaccessible, exact, path, strict
}) => (
  <Route
    path={path}
    exact={exact}
    render={render(isAccessible, isMainRoute, component, redirectToWhenInaccessible)}
    strict={strict}
  />
);

export default ProtectedRoute;
