import { FC, useEffect, useState } from 'react';
import CertificateCard from 'pages/certificates/CertificateCard';
import { useAuth } from 'utils/AuthContext';
import { useLazyQuery } from '@apollo/client';
import { GET_CERTIFICATES_QUERY } from 'utils/gqlQueries';
import AppSpinner from 'common/appSpinner/AppSpinner';
import parseCertificatesQuery, { ParsedCertificatesQuery } from 'utils/parseCertificatesQuery';
import { CertificatesWrap } from './CertificatesStyles';

const Certificates: FC = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getCertificates, { loading: isRequestInProgress, data }] = useLazyQuery(GET_CERTIFICATES_QUERY);
  const [certificates, setCertificates] = useState<ParsedCertificatesQuery>([]);

  useEffect(() => {
    getCertificates();
  }, []);

  useEffect(() => {
    if (
      !isRequestInProgress
      && data
      && data.certificates
      && user
    ) {
      const parsedCertificates = parseCertificatesQuery(data.certificates)
        .map(certificate => ({
          ...certificate,
          isAcquired: user.certificates.findIndex(userCertificateId => userCertificateId === certificate.id) > -1
        }));
      setCertificates(parsedCertificates);
      setIsLoading(false);
    }
  }, [isRequestInProgress, data]);

  return (
    <CertificatesWrap>
      { isLoading ? <AppSpinner /> : (
        <>
          <h4 className="heading">Certificates</h4>
          { certificates.map(({ name, pathName, isAcquired }) => (
            <CertificateCard
              key={name}
              certName={name}
              pathName={pathName}
              isLocked={!isAcquired}
            />
          ))}
        </>
      )}
    </CertificatesWrap>
  );
};

export default Certificates;
