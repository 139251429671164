import styled from 'styled-components';
import { Checkbox, withStyles } from '@material-ui/core';
import { colors } from 'common/colors';
import checkIcon from 'assets/img/checkIcon.svg';
import { CheckboxListStylesEnum } from 'stepComponents/checkboxList/CheckboxListStyles';

export const CheckboxWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-right: 15px
`;

export const CheckboxIcon = styled.span<{
  checked?: boolean,
  listStyle?: CheckboxListStylesEnum
}>`
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: ${({ checked }) => (
    checked ? `1px solid ${colors.primary_indigo}` : `1px solid ${colors.primary_gray_light1}`
  )};
  ${({ checked, listStyle }) => checked && `
    &:after {
      display: block;
      position: relative;
      width: 24px;
      height: 20px;
      top: 14px;
      left: 11px;
      background-image: url(${checkIcon});
      background-size: contain;
      content: '';
      ${listStyle === CheckboxListStylesEnum.pills
        && `width: 12px;
            height: 10px;
            top: 7px;
            left: 5.5px;`
      }
    }
  `}
  ${({ listStyle }) => listStyle === CheckboxListStylesEnum.pills
  && `width: 24px;
      height: 24px;
      border-radius: 5px;`}
`;

export const StyledCheckboxMUI = withStyles({
  root: {
    padding: 0,
    marginRight: '20px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})(Checkbox);

export const Label = styled.label<{
  listStyle: CheckboxListStylesEnum
}>`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  ${({ listStyle }) => listStyle === CheckboxListStylesEnum.pills
    && `padding: 12px;
        background-color: ${colors.primary_white};
        border: 1px solid ${colors.primary_gray_light2};
        border-radius: 8px;`
  }

  > span {
    display: flex;
    align-items: center;
    min-height: 48px;
    ${({ listStyle }) => listStyle === CheckboxListStylesEnum.pills
      && 'min-height: inherit;'
    }
  }
`;
