import React, { useCallback } from 'react';
import { PathwayCardWrap } from 'pages/pathway/pathwayCard/PathwayCardStyles';
import { ReactComponent as ContinueLearningButtonSvg } from 'assets/img/continue-learning-button-long.svg';
import { ReactComponent as LockedButton } from 'assets/img/locked_button.svg';
import ActivitiesTag from 'common/activitiesTag/ActivitiesTag';
import Description from 'stepComponents/description/Description';
import { useHistory } from 'react-router-dom';
import { usePathState } from 'utils/PathContext';

export interface PathwayBasicInfo {
  id: string;
  coursesNumber: number;
  badgesNumber: number;
  certificatesNumber: number;
  isLocked: boolean;
  isHidden: boolean;
  name: string;
  content: string;
  image: { url: string };
  orderNumber: number;
}

function PathwayCard({
                       id,
                       coursesNumber,
                       badgesNumber,
                       certificatesNumber,
                       isLocked,
                       isHidden,
                       name,
                       content,
                       image,
                     }: Omit<PathwayBasicInfo, 'orderNumber'>): JSX.Element {
  const setPathState = usePathState();
  const history = useHistory();

  const selectPathwayHandler = useCallback(() => {
    if (isLocked) {
      return;
    }
    setPathState({
      pathStateId: id
    });
    localStorage.setItem('pathwayId', id);
    history.push(`pathway/${id}`);
  }, [id]);

  if (isHidden) {
    return <></>;
  }

  return (
    <PathwayCardWrap isLocked={isLocked}>
      <a onClick={selectPathwayHandler}>
        {isLocked ? (
          <LockedButton />
        ) : (
          <ContinueLearningButtonSvg />
        )}

        {image && (
          <div className="imgContainer">
            <img src={image.url} alt="pathway_img" />
          </div>
        )}
        <h3>{name}</h3>
        <Description className="body16Regular" content={content} />
        <div className="activities">
          <ActivitiesTag number={coursesNumber} activityName="courses" />
          <ActivitiesTag number={badgesNumber} activityName="badges" />
          <ActivitiesTag number={certificatesNumber} activityName="certificate" />
        </div>
      </a>
    </PathwayCardWrap>
  );
}

export default PathwayCard;
