import styled from 'styled-components';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core';
import { colors } from 'common/colors';

export const RadioWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export const Icon = styled.span<{
  checked?: boolean
}>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: ${({ checked }) => (
    checked ? `1px solid ${colors.primary_indigo}` : `1px solid ${colors.primary_gray_light1}`
  )};
  ${({ checked }) => checked && `
    &:after {
      display: block;
      position: relative;
      left: 13px;
      top: 13px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: ${colors.primary_indigo};
      content: '';
    }
  `}
`;

export const StyledRadioMUI = withStyles({
  root: {
    padding: 0,
    marginRight: '20px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})((props: RadioProps) => <Radio color="default" {...props} />);

export const Label = styled.label`
  display: flex;
  align-items: flex-start;
`;
