import styled from 'styled-components';
import { colors } from '../colors';

export const CircleWithIconWrap = styled.div<{
  backgroundColor?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 72px;
  border-radius: 100px;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.primary_aquamarine};
`;
