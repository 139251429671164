import { BadgeEarnedDto } from 'utils/userApi';
import { BadgeEarnedType } from 'utils/AuthContext';

export default function parseBadgesEarned(badgesEarnedDto: BadgeEarnedDto[]): BadgeEarnedType[] {
  return badgesEarnedDto.map(badge => ({
    id: badge.id,
    badge: {
      id: badge.badges.id,
      type: badge.badges.type,
      level: badge.badges.level,
      color: badge.badges.color,
    },
    ...(badge.topic && { topicId: badge.topic.id, topicName: badge.topic.name }),
    ...(badge.course && { courseId: badge.course.id, courseName: badge.course.name }),
  }));
}
