import { FunctionComponent, useState } from 'react';
import { handleUserProgressDataChange } from 'pages/courseControler/components/userProgress/userProgressHelper';
import {
  RangeNumberedWrap, Scale, NumberBox, LabelsContainer, Label
} from './RangeNumberedStyles';

export type RangeNumberedPropsType = {
  _id?: string;
  minValue: number;
  maxValue: number;
  beginningLabel: string;
  middleLabel?: string;
  endLabel: string;
  storedValue? : number;
};

const RangeNumbered: FunctionComponent<RangeNumberedPropsType> = ({
  _id,
  minValue,
  maxValue,
  beginningLabel,
  middleLabel,
  endLabel,
  storedValue
}) => {
  const [value, setValue] = useState<number | undefined>(storedValue);
  const range = Array.from({ length: maxValue - minValue + 1 }, (v, k) => k + minValue);
  const labels = [beginningLabel, middleLabel, endLabel].filter(label => label);

  const handleValueChange = (newValue: number) => {
    setValue(newValue);
    handleUserProgressDataChange(`${_id}`, newValue);
  };

  return (
    <RangeNumberedWrap>
      <Scale>
        {range.map(item => (
          <NumberBox
            key={item}
            isActive={item === value}
            onClick={() => handleValueChange(item)}
          >
            {item}
          </NumberBox>
        ))}
      </Scale>
      {labels ? (
        <LabelsContainer>
          {labels.map(label => (
            <Label key={label}>{label}</Label>
          ))}
        </LabelsContainer>
      ) : null}
    </RangeNumberedWrap>
  );
};

export default RangeNumbered;
