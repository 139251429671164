import { FC } from 'react';
import { StyledListWrap } from './StyledListStyles';

export type StyledListPropsType = {
  listItems: { name: string }[];
  markerImg?: { url: string } | null;
};

const StyledList: FC<StyledListPropsType> = ({ listItems, markerImg }) => (
  <StyledListWrap markerImgUrl={markerImg?.url}>
    {
      listItems.map(item => (
        <li key={item.name}>{item.name}</li>
      ))
    }
  </StyledListWrap>
);

export default StyledList;
