import ReactMarkdown from 'react-markdown';
import { DescriptionWrap } from './DescriptionStyles';

export type DescriptionPropsType = {
  className: string;
  content: string;
};

function Description({
  content,
  className
}: DescriptionPropsType):JSX.Element {
  return (
    <DescriptionWrap className={className}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </DescriptionWrap>
  );
}

export default Description;
