import fastLuhn from 'fast-luhn';

const isValidCitizenship = (rsaId: string): boolean =>
  RegExp('^.{10}[0-1]').test(rsaId);

const isValidRsaId = (rsaId: string): boolean =>
  RegExp('^[0-9]{13}$').test(rsaId)
  && isValidCitizenship(rsaId)
  && fastLuhn(rsaId);

export { isValidRsaId };
