/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
 ChangeEvent, createContext, FC, useContext, useEffect, useState
} from 'react';
import { BasicsSectionType } from 'pages/cvBuilder/steps/basics/Basics';
import { EducationSectionType, EducationSubjectInputPropertiesType } from 'pages/cvBuilder/steps/education/Education';
import {
  WorkExperienceReferencesTextInputPropertiesType,
  WorkExperienceSectionType
} from 'pages/cvBuilder/steps/workExperience/WorkExperience';
import { VolunteerExperienceSectionType } from 'pages/cvBuilder/steps/volunteerExperience/VolunteerExperience';
import { useAuth, UserType } from 'utils/AuthContext';
import userApi from 'utils/userApi';
import { PersonalSkillsSectionType } from 'pages/cvBuilder/steps/personalSkills/PersonalSkills';
import { TechnicalSkillsSectionType } from 'pages/cvBuilder/steps/technicalSkills/TechnicalSkills';
import { parseFullName } from 'utils/parseFullName';
import { isValidRsaId } from './regex';

export type CvBuilderValues = {
  basics: BasicsSectionType;
  education: EducationSectionType[];
  workExperience: WorkExperienceSectionType[];
  volunteerExperience: VolunteerExperienceSectionType[];
  technicalSkills: TechnicalSkillsSectionType[];
  personalSkills: PersonalSkillsSectionType[];
  summaryStatement: string;
  isDownloaded: boolean;
  isDownloadedTimestamp: Date | null;
};

type ArrayValues = Omit<CvBuilderValues, 'basics' | 'summaryStatement' | 'isDownloaded' | 'isDownloadedTimestamp'>;

type CVBuilderValuesExpanded = {
  subjects: EducationSubjectInputPropertiesType[]
  references: WorkExperienceReferencesTextInputPropertiesType[]
};

type CvBuilderStateType = {
  values: CvBuilderValues;
  isUpdatingCvInProgress: boolean;
  isValidRSAID: boolean;
  handleChange: (key: keyof CvBuilderValues, neastedKey?: keyof CVBuilderValuesExpanded) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number, insideIndex?: number) => void;
  handleRemoveClick: (
    key: keyof ArrayValues, index: number, neastedKey?: keyof CVBuilderValuesExpanded, insideIndex?: number
  ) => void;
  handleAddClick: (key: keyof ArrayValues, expanded_data?: string, i?: number) => void;
  handleUpdateCv: (downloadedCV?: boolean) => Promise<void>;
  handleDownloadedChange: () => void;
};

export const CvBuilderContext = createContext<CvBuilderStateType | undefined>(undefined);

export const useCvBuilder: () => CvBuilderStateType = () => {
  const context = useContext(CvBuilderContext);
  if (!context) {
    throw new Error('useCvBuilder must be used within CvBuilderContext');
  }
  return context;
};

const basicsSectionTemplate: BasicsSectionType = {
  fullName: '',
  idNumber: '',
  city: '',
  email: '',
  phoneNumber: ''
};

const educationSectionSubjectTemplate: EducationSubjectInputPropertiesType = {
  subject: '',
  subjectPercentage: ''
};

const educationSectionTemplate: EducationSectionType = {
  schoolName: '',
  schoolLocation: '',
  qualification: '',
  isStillStudying: true,
  schoolEndDate: '',
  subjects: [educationSectionSubjectTemplate]
};

const workExperienceSectionReferencesTemplate: WorkExperienceReferencesTextInputPropertiesType = {
  name: '',
  phone: '',
  email: '',
  reference: ''
};

const workExperienceSectionTemplate: WorkExperienceSectionType = {
  companyName: '',
  companyLocation: '',
  jobTitle: '',
  employmentStartDate: '',
  isStillWorking: true,
  employmentEndDate: '',
  duties: '',
  references: [workExperienceSectionReferencesTemplate]
};

const volunteerExperienceSectionTemplate: VolunteerExperienceSectionType = {
  organizationName: '',
  organizationLocation: '',
  role: '',
  volunteeringStartDate: '',
  isStillWorking: true,
  volunteeringEndDate: '',
  duties: ''
};

const initialState = {
  basics: { ...basicsSectionTemplate },
  education: [],
  workExperience: [],
  volunteerExperience: [],
  technicalSkills: [],
  personalSkills: [],
  summaryStatement: '',
  isDownloaded: false,
  isDownloadedTimestamp: null
};

const isFlatValue = (key: keyof CvBuilderValues) => (key === 'summaryStatement');
const isArrayValue = (key: keyof CvBuilderValues) => (
  key === 'education'
  || key === 'workExperience'
  || key === 'volunteerExperience'
  || key === 'technicalSkills'
  || key === 'personalSkills'
);

const isArraySubjectValue = (
  key: keyof EducationSubjectInputPropertiesType | keyof WorkExperienceReferencesTextInputPropertiesType
) => (
  key === 'subject'
  || key === 'subjectPercentage'
  || key === 'name'
  || key === 'phone'
  || key === 'email'
  || key === 'reference'
);

const isRadioValue = (name: string) => (name === 'isStillStudying' || name === 'isStillWorking');

export const CvBuilderProvider: FC = ({ children }) => { // TODO: add delete record fn on isEverySectionFieldEmpty
  const { user, setUser } = useAuth();
  const [values, setValues] = useState<CvBuilderValues>(initialState);
  const [isUpdatingCvInProgress, setIsUpdatingCvInProgress] = useState<boolean>(false);
  const [isValidRSAID, setIsValidRSAID] = useState(true);

  useEffect(() => {
    if (!user) {
      setValues(initialState);
      return;
    }
    if (!user.cv) {
      if (user.rsaId) {
        const { rsaId } = user;
        setIsValidRSAID(isValidRsaId(rsaId));
        setValues(prev => ({ ...prev, basics: { ...prev.basics, idNumber: rsaId } }));
      }
      setValues(prev => ({
        ...prev,
        basics: {
          ...prev.basics,
          fullName: parseFullName(user),
          email: user.email,
          phoneNumber: user.phone ?? '',
        }
      }));
      return;
    }
    const rsaId = user.cv.basics.idNumber ?? user.rsaId;
    if (rsaId) {
      setIsValidRSAID(isValidRsaId(rsaId));
    }
    setValues({
      basics: {
        ...user.cv.basics,
        idNumber: rsaId,
        email: user.cv.basics.email ?? user.email,
        phoneNumber: user.cv.basics.phoneNumber ?? user.phone,
      },
      education: user.cv.education,
      workExperience: user.cv.workExperience,
      volunteerExperience: user.cv.volunteerExperience,
      technicalSkills: user.cv.technicalSkills,
      personalSkills: user.cv.personalSkills,
      summaryStatement: user.cv.summaryStatement,
      isDownloaded: user.cv.isDownloaded,
      isDownloadedTimestamp: user.cv.isDownloadedTimestamp,
    });
  }, [user]);

  const handleDownloadedChange = () => {
    setValues({
      ...values,
      isDownloaded: true,
      isDownloadedTimestamp: new Date()
    });
  };

  const handleChange = (key: keyof CvBuilderValues, neastedKey?: keyof CVBuilderValuesExpanded) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number, insideIndex?: number) => {
      const { name, value: newValue } = e.target;

      if (name === 'idNumber') {
        const validRSAID = isValidRsaId(newValue);
        setIsValidRSAID(validRSAID);
      }

      if (isArrayValue(key) && index !== undefined) {
        const updatedList = [...values[key as keyof ArrayValues]];

        if (isRadioValue(name)) {
          // @ts-ignore
          updatedList[index][name] = newValue === 'true';
        } else if (isArraySubjectValue(
          name as keyof EducationSubjectInputPropertiesType | keyof WorkExperienceReferencesTextInputPropertiesType
        )) {
          // @ts-ignore
          updatedList[index][neastedKey][insideIndex][name] = newValue;
        } else {
          // @ts-ignore
          updatedList[index][name] = newValue;
        }

        setValues({
          ...values,
          [key]: updatedList
        });
      } else if (isFlatValue(key as keyof CvBuilderValues)) {
        return setValues({
          ...values,
          [key]: newValue
        });
      } else {
        const updatedSection = {
          ...values[key as keyof Pick<CvBuilderValues, 'basics'>]
        };
        updatedSection[name as keyof BasicsSectionType] = newValue;

        setValues({
          ...values,
          [key]: updatedSection
        });
      }
    };

  const handleRemoveClick = (
    key: keyof ArrayValues, index: number, neastedKey?: keyof CVBuilderValuesExpanded, insideIndex?: number
  ) => {
    let updatedList;
    if (neastedKey === undefined) {
      updatedList = [...values[key]];
      updatedList.splice(index, 1);
    } else {
      updatedList = [...values[key]];
      // @ts-ignore
      updatedList[index][neastedKey].splice(insideIndex, 1);
    }

    setValues({
      ...values,
      [key]: updatedList
    });
  };

  const handleAddClick = (key: keyof ArrayValues, expanded_data?: string, i?: number) => {
    let template;
    switch (key) {
      case 'education':
        if (expanded_data) {
          template = { ...educationSectionSubjectTemplate };
        } else {
          template = { ...educationSectionTemplate };
        }
        break;
      case 'workExperience':
        if (expanded_data) {
          template = { ...workExperienceSectionReferencesTemplate };
        } else {
          template = { ...workExperienceSectionTemplate };
        }
        break;
      case 'volunteerExperience':
        template = { ...volunteerExperienceSectionTemplate };
        break;
      case 'technicalSkills':
        template = { skillName: '' };
        break;
      case 'personalSkills':
        template = { skillName: '', description: '' };
        break;
      default:
        template = {};
        break;
    }

    if (expanded_data && i !== undefined) {
      const updatedSubjectArr = { ...values };
      // @ts-ignore
      updatedSubjectArr[key][i][expanded_data].push(template);
      setValues(updatedSubjectArr);
    } else {
      setValues({
        ...values,
        [key]: [...values[key], template]
      });
    }
  };

  const handleUpdateCv = async (downloadedCV?: boolean) => {
    if (user) {
      try {
        setIsUpdatingCvInProgress(true);
        const res = await userApi.updateCv({
          cv: downloadedCV ? { ...values, isDownloaded: true, isDownloadedTimestamp: new Date() } : { ...values }
        });

        if (res.cv) {
          const updatedUser: UserType = {
            ...user,
            cv: res.cv
          };
          setUser(updatedUser);
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        setIsUpdatingCvInProgress(false);
      }
    }
  };

  return (
    <CvBuilderContext.Provider
      value={{
        values,
        isUpdatingCvInProgress,
        isValidRSAID,
        handleChange,
        handleRemoveClick,
        handleAddClick,
        handleUpdateCv,
        handleDownloadedChange
      }}
    >
      {children}
    </CvBuilderContext.Provider>
  );
};
