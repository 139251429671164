import styled from 'styled-components';
import { colors } from 'common/colors';

export const DraggableItemWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  margin-left: 15px;
  border-radius: 10px;
  border: 1px solid ${colors.primary_gray_light1};
  color: ${colors.primary_indigo};
  user-select: none;
  background-color: ${colors.primary_white};
`;
