import styled from 'styled-components';
import { colors } from 'common/colors';

export const PathwayWrap = styled.div`
  padding: 85px 30px 120px;
  display: flex;
  flex-direction: column;
  
  .header {
    margin: 15px 0;
    color: ${colors.primary_gray1};
  }
  
  img {
    max-height: 139px;
    width: fit-content;
    align-self: center;
    margin-top: 15px;
  }
`;

export const PathwayLabelWrap = styled.div`
  color: ${colors.primary_gray4};
`;

export const ScoresWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
