import { useAuth } from 'utils/AuthContext';
import {
  useState, SyntheticEvent, useMemo, useEffect, FC
} from 'react';
import useForm from 'utils/useForm';
import userApi from 'utils/userApi';
import { ReactComponent as HeroesIcon } from 'assets/img/heroes.svg';
import FormInput from 'common/formInput/FormInput';
import Button from 'common/button/Button';
import DotsLoader from 'common/dotsLoader/DotsLoader';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'utils/SnackbarContext';
import {
 StyledForm, StyledLink, ErrorWrap, Header, Wrapper
} from 'common/authStyles/AuthStyles';

const ResetPassword: FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { openSnackbar } = useSnackbar();
  const { requestError, setRequestError } = useAuth();
  const [isResetInProgress, setResetInProgress] = useState(false);
  const {
    values,
    handleInputChange,
  } = useForm({
    password: '',
    repeatPassword: ''
  });

  const privateCode = useMemo(() => new URLSearchParams(search).get('code'), [search]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!privateCode) {
      setRequestError('Something went wrong. Generate new reset link.');
      return;
    }
    try {
      setRequestError('');
      setResetInProgress(true);
      await userApi.resetPassword({
        password: values.password, passwordConfirmation: values.repeatPassword, code: privateCode
      });
      openSnackbar({ message: 'Your password has been reset' });
      history.push('/');
    } catch (err) {
      setRequestError(err.message);
    } finally {
      setResetInProgress(false);
    }
  };

  useEffect(() => {
    if (!privateCode) {
      history.push('/');
    }
  }, [search]);

  useEffect(() => () => setRequestError(''), []);

  return (
    <Wrapper>
      <HeroesIcon />
      <Header isRequestError={!!requestError}>Reset Password</Header>
      {requestError ? <ErrorWrap>{requestError}</ErrorWrap> : null}
      <StyledForm onSubmit={handleSubmit}>
        <FormInput
          fullWidth
          variant="outlined"
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
          required
          onChange={handleInputChange}
          value={values.password}
        />
        <FormInput
          fullWidth
          variant="outlined"
          name="repeatPassword"
          type="password"
          label="Repeat Password"
          placeholder="Repeat Password"
          required
          onChange={handleInputChange}
          value={values.repeatPassword}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
        >
          {isResetInProgress ? <DotsLoader /> : 'Save'}
        </Button>
      </StyledForm>
      <StyledLink to="/">Back</StyledLink>
    </Wrapper>
  );
};

export default ResetPassword;
