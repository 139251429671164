import React from 'react';
import { AppButtonWrap, OptionType } from './AppButtonStyles';

export type AppButtonPropsType = {
  text: string;
  option?: OptionType;
  fullWidth?: boolean;
  onButtonClick?: (payload: unknown) => unknown;
};

function AppButton({
  text,
  option,
  fullWidth,
  onButtonClick
}: AppButtonPropsType): JSX.Element {
  return (
    <AppButtonWrap
      className="button16Semibold"
      option={option || 'fill'}
      fullWidth={fullWidth}
      onClick={onButtonClick}
    >
      {text}
    </AppButtonWrap>
);
}

export default AppButton;
