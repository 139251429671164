import React from 'react';
import { ReactComponent as ProfileIcon } from 'assets/img/footermenu-icons/profile-icon.svg';
import { MenuButtonWrapAsRouterLink } from './MenuButtonStyles';

function MenuButtonProfile(): JSX.Element {
  return (
    <MenuButtonWrapAsRouterLink className="profile" to="/profile">
      <ProfileIcon />
      <span className="label12Bold">Profile</span>
    </MenuButtonWrapAsRouterLink>
  );
}

export default MenuButtonProfile;
