import styled from 'styled-components';
import { colors } from 'common/colors';

export const CardNumberedWrap = styled.div`
  border: 1px solid ${colors.primary_gray_light2}; 
  border-radius: 10px;
  padding: 20px;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Subtitle = styled.span`
  margin-top: 15px;
  color: ${colors.primary_gray4};
`;

export const Divider = styled.span`
  width: 100%;
  margin: 10px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.primary_gray_light3};
`;
