import { FunctionComponent } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import ListItem from 'stepComponents/dnd/list/ListItem';
import { useDroppable } from '@dnd-kit/core';
import styled from 'styled-components';

const UnassignedListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const UnassignedList: FunctionComponent<{ id: string, items: string[] }> = (
  {
    id,
    items
  }
) => {
  const { setNodeRef } = useDroppable({ id });
  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <UnassignedListWrap ref={setNodeRef}>
        {items.map(item => (
          <ListItem key={item} id={item} />
        ))}
      </UnassignedListWrap>
    </SortableContext>
  );
};

export default UnassignedList;
