import styled from 'styled-components';
import { colors } from 'common/colors';

export const ActivitiesTagWrap = styled.div`
  background-color: ${colors.primary_white};
  border: 1px solid ${colors.primary_gray_light1};
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 15px;
  margin-right: 5px;
  
  color: ${colors.primary_black};
`;
