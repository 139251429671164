import {
 FC, SyntheticEvent, useEffect, useState
} from 'react';
import {
 ErrorWrap, Header, StyledForm, Wrapper
} from 'common/authStyles/AuthStyles';
import { ReactComponent as PadlockIcon } from 'assets/img/padlock_icon.svg';
import { useAuth } from 'utils/AuthContext';
import FormInput from 'common/formInput/FormInput';
import useForm from 'utils/useForm';
import Button from 'common/button/Button';
import DotsLoader from 'common/dotsLoader/DotsLoader';
import { useHistory } from 'react-router-dom';
import userApi from 'utils/userApi';
import parseUserData from 'utils/parseUserData';
import { isValidRsaId } from 'utils/regex';

const AccountDetails: FC = () => {
  const history = useHistory();
  const {
    requestError, setRequestError, logout, user, setComplete, setUser
  } = useAuth();
  const { values, handleInputChange } = useForm({
    rsaId: user?.rsaId ?? '',
    phone: user?.phone ?? '',
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? ''
  });
  const [isSaving, setSaving] = useState(false);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!user) {
      setRequestError('No user in memory. Try to log in again');
      return;
    }
    if (!isValidRsaId(values.rsaId)) {
      setRequestError('Invalid RSA ID');
      return;
    }
    if (values.phone.length !== 10) {
      setRequestError('Invalid phone number');
      return;
    }
    setRequestError('');
    setSaving(true);
    try {
      const updatedUser = await userApi.updateUser(user.id, values);
      const parsedUser = parseUserData(updatedUser);
      setUser(parsedUser);
      setComplete(true);
      history.push('/');
    } catch (err) {
      setRequestError(err.message);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => () => setRequestError(''), []);

  return (
    <Wrapper>
      <PadlockIcon />
      <Header isRequestError={!!requestError}>Fill in your account details</Header>
      {requestError ? <ErrorWrap>{requestError}</ErrorWrap> : null}
      <StyledForm onSubmit={handleSubmit}>
        <FormInput
          fullWidth
          variant="outlined"
          name="rsaId"
          label="RSA ID"
          placeholder="RSA ID"
          required
          onChange={handleInputChange}
          value={values.rsaId}
        />
        <FormInput
          fullWidth
          variant="outlined"
          name="firstName"
          label="First Name"
          placeholder="First Name"
          required
          onChange={handleInputChange}
          value={values.firstName}
        />
        <FormInput
          fullWidth
          variant="outlined"
          name="lastName"
          label="Last Name"
          placeholder="Last Name"
          required
          onChange={handleInputChange}
          value={values.lastName}
        />
        <FormInput
          fullWidth
          variant="outlined"
          name="phone"
          type="number"
          label="Phone Number"
          placeholder="012 345 6789"
          required
          onChange={handleInputChange}
          value={values.phone}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
        >
          {isSaving ? <DotsLoader /> : 'Save'}
        </Button>
      </StyledForm>
      <Button
        variant="text"
        fullWidth
        onClick={logout}
      >
        Log out
      </Button>
    </Wrapper>
  );
};

export default AccountDetails;
