import { FC } from 'react';
import { DndContext } from '@dnd-kit/core';
import useDndLetters, { ItemType } from 'stepComponents/dndLetters/useDndLetters';
import LettersList from 'stepComponents/dndLetters/lettersList/LettersList';
import LetterCard from 'stepComponents/dndLetters/letterCard/LetterCard';

export type DndLettersPropsType = {
  cardItems: {
    letter: string;
    title: string;
    description: string;
    storedValue? : unknown;
  }[];
};

const DndLetters: FC<DndLettersPropsType> = ({ cardItems }) => {
  const {
    items: { root: rootItems, ...restContainers },
    sensors,
    handleDragStart,
    handleDragEnd
  } = useDndLetters(cardItems);
  const containers = Object.entries(restContainers);
  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div>
        <LettersList items={rootItems as ItemType[]} />
        {containers.map(([id, item]) => (
          <LetterCard key={id} id={id} item={item as ItemType} />
        ))}
      </div>
    </DndContext>
  );
};

export default DndLetters;
