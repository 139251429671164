import { FC, useEffect } from 'react';
import Badges from 'pages/badges/Badges';
import PathwaysList from 'pages/pathway/pathwaysList/PathwaysList';
import StepDisplay from 'pages/courseControler/components/stepDisplay/StepDisplay';
import { AppWrap } from 'AppStyles';
import Pathway from 'pages/courseControler/components/pathway/Pathway';
import CourseEntry from 'pages/courseControler/components/courseEntry/CourseEntry';
import TopicEntrance from 'pages/courseControler/components/topicEntrance/TopicEntrance';
import { useAuth } from 'utils/AuthContext';
import LoginPage from 'pages/login/LoginPage';
import RegisterPage from 'pages/register/RegisterPage';
import ProtectedRoute from 'common/protectedRoute/ProtectedRoute';
import AppSpinner from 'common/appSpinner/AppSpinner';
import ProfilePage from 'pages/profile/ProfilePage';
import Certificates from 'pages/certificates/Certificates';
import CvBuilder from 'pages/cvBuilder/CvBuilder';
import { CompletedCourse, CompletedKnowledgeBite, CompletedTopic } from 'stepComponents';
import AccountDetails from 'pages/accountDetails/AccountDetails';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import ResetPassword from 'pages/resetPassword/ResetPassword';
import { useHistory } from 'react-router-dom';
import CompletedPath from './pages/courseControler/components/completedPath/CompletedPath';
import MindfulnessBreak from './pages/courseControler/components/mindfulnessBreak/MindfulnessBreak';

const App: FC = () => {
  const { isSignInInProgress, isAuthenticated, isComplete } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated && !isComplete) {
      history.push('/update-details');
    }
  }, [isAuthenticated, isComplete]);

  return (
    <AppWrap data-testid="app-wrap">
      {isSignInInProgress ? <AppSpinner /> : (
        <>
          <ProtectedRoute
            path="/badges"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={Badges}
            isMainRoute
            exact
          />
          <ProtectedRoute
            path="/profile"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={ProfilePage}
            isMainRoute
            exact
          />
          <ProtectedRoute
            path="/update-details"
            isAccessible={isAuthenticated && !isComplete}
            redirectToWhenInaccessible="/"
            component={AccountDetails}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/cv-builder"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={CvBuilder}
            isMainRoute
            exact
          />
          <ProtectedRoute
            path="/certificates"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={Certificates}
            isMainRoute
            exact
          />
          <ProtectedRoute
            path="/pathway/:id"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={Pathway}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={PathwaysList}
            isMainRoute
            exact
            strict={true}
          />
          <ProtectedRoute
            path="/login"
            isAccessible={!isAuthenticated}
            redirectToWhenInaccessible="/"
            component={LoginPage}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/forgot-password"
            isAccessible={!isAuthenticated}
            redirectToWhenInaccessible="/"
            component={ForgotPassword}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/reset-password"
            isAccessible={!isAuthenticated}
            redirectToWhenInaccessible="/"
            component={ResetPassword}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/register"
            isAccessible={!isAuthenticated}
            redirectToWhenInaccessible="/"
            component={RegisterPage}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/course/:courseName"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={CourseEntry}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/step/:stepId"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={StepDisplay}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/topic/:topicName"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={TopicEntrance}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/completedKnowledgeBite/:id"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={CompletedKnowledgeBite}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/completedTopic/:topicName"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={CompletedTopic}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/completedCourse/:courseName"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={CompletedCourse}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/completedPathway/:pathwayName"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={CompletedPath}
            isMainRoute={false}
            exact
          />
          <ProtectedRoute
            path="/mindfulness-break/:mbId"
            isAccessible={isAuthenticated}
            redirectToWhenInaccessible="/login"
            component={MindfulnessBreak}
            isMainRoute={false}
            exact
          />
        </>
      )}
    </AppWrap>
  );
};

export default App;
