import styled, { keyframes } from 'styled-components';
import { colors } from 'common/colors';

export const DotsLoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
`;

const scaleDown = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

export const Dot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${colors.primary_white};
  position: relative;
  animation: ${scaleDown} 1.2s ease-in-out infinite;
  &:nth-child(2) {
    margin: 0 15px;
    animation: ${scaleDown} 1.2s ease-in-out infinite .15555s;
  }
  &:nth-child(3) {
    animation: ${scaleDown} 1.2s ease-in-out infinite .3s;
  }
`;
