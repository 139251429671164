import { FC } from 'react';
import FormInput from 'common/formInput/FormInput';
import Tooltip from 'stepComponents/tooltip/Tooltip';
import { useCvBuilder } from 'utils/CvBuilderContext';
import Description from 'stepComponents/description/Description';
import { ReactComponent as PlusIcon } from 'assets/img/plus_icon.svg';
import {
  AddSectionIconWrap, StepHeader, StepWrap, RemoveItemButton, AddItemButton, ItemWrap
} from 'pages/cvBuilder/steps/styles';

export type TechnicalSkillsSectionType = {
  skillName: string;
};

const TechnicalSkills: FC = () => {
  const {
    values: { technicalSkills },
    handleChange,
    handleAddClick,
    handleRemoveClick
  } = useCvBuilder();

  return (
    <StepWrap>
      <StepHeader isDescription>
        Technical Skills
      </StepHeader>
      <Description
        className="body16Regular"
        content={'Add your technical skills here. These are skills like the ability to use a computer, '
        + 'or knowing how to drive.'}
      />
      {technicalSkills.map(({ skillName }, i) => (
        <ItemWrap key={`skill-${i + 1}`}>
          <RemoveItemButton
            onClick={() => handleRemoveClick('technicalSkills', i)}
          >
            Remove
          </RemoveItemButton>
          <FormInput
            fullWidth
            variant="outlined"
            name="skillName"
            placeholder="Type your skill here"
            value={skillName}
            onChange={e => handleChange('technicalSkills')(e, i)}
          />
        </ItemWrap>
      ))}
      <AddItemButton
        onClick={() => handleAddClick('technicalSkills')}
      >
        <AddSectionIconWrap>
          <PlusIcon />
        </AddSectionIconWrap>
        Add technical skill
      </AddItemButton>
      <Tooltip
        closeButton
        backgroundColor="primary_aquamarine"
        title="Tip"
        content={'Tailor your work experience to the job you are applying for (you might have learned a skill at one '
        + 'job—like customer service—that applies to the job you are applying for)'}
      />
    </StepWrap>
  );
};

export default TechnicalSkills;
