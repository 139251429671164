import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from 'utils/AuthContext';

const AuthInterceptor: FC = ({ children }) => {
  const { setIsAuthenticated } = useAuth();
  const history = useHistory();

  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('Authorization');
      if (token) {
        const { jwt } = JSON.parse(token);
        config.headers.Authorization = `Bearer ${jwt}`; // eslint-disable-line
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        setIsAuthenticated(false);
        history.push('/login');
      }
      return Promise.reject(error);
    }
  );
  return (
    <>
      {children}
    </>
  );
};

export default AuthInterceptor;
