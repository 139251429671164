import React, { FunctionComponent } from 'react';
import { CloseButtonWrap } from './CloseButtonStyles';

type Props = {
  onClose: () => void;
};

const CloseButton: FunctionComponent<Props> = ({ onClose }) => (
  <CloseButtonWrap onClick={onClose}>
    x
  </CloseButtonWrap>
);

export default CloseButton;
