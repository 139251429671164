import styled from 'styled-components';
import { colors } from 'common/colors';
import { Link } from 'react-router-dom';

export const ProfilePageWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 45px 30px 30px 30px;
  background-color: ${colors.primary_white};
  height: 100%;
  width: 100%;
`;

export const Header = styled.h4`
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const StyledLink = styled(Link)`
  font-family: 'AppMulish', sans-serif;
  font-weight: 600;
  text-decoration: none;
  color: ${colors.primary_indigo};
  margin-bottom: 20px;
`;
