import { FC } from 'react';
import FormInput from 'common/formInput/FormInput';
import { useCvBuilder } from 'utils/CvBuilderContext';
import Radio from 'stepComponents/radio/Radio';
import Description from 'stepComponents/description/Description';
import { ReactComponent as MinusIcon } from 'assets/img/minus_icon.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus_icon.svg';
import {
  StepWrap, StepHeader, SectionWrap, SectionHeader, AddSectionButton, AddSectionIconWrap, RemoveSectionButton
} from '../styles';

export type VolunteerExperienceTextInputPropertiesType = {
  role: string;
  organizationLocation: string;
  organizationName: string;
  volunteeringStartDate: string;
  volunteeringEndDate: string;
  duties: string
};

export type VolunteerExperienceRadioInputPropertiesType = {
  isStillWorking: boolean;
};

export type VolunteerExperienceSectionType = VolunteerExperienceTextInputPropertiesType
  & VolunteerExperienceRadioInputPropertiesType;

const VolunteerExperience: FC = () => {
  const {
    values: { volunteerExperience },
    handleChange,
    handleAddClick,
    handleRemoveClick
  } = useCvBuilder();

  return (
    <StepWrap>
      <StepHeader isDescription>
        Volunteer Experience
      </StepHeader>
      <Description
        className="body16Regular"
        content="Now add your community or volunteer experience. If you don&apos;`t have any, skip this section."
      />
      {volunteerExperience.map((section, i) => (
        <SectionWrap key={`volunteer-${i + 1}`}>
          <SectionHeader>
            Volunteer Experience {i + 1}
            <RemoveSectionButton
              onClick={() => handleRemoveClick('volunteerExperience', i)}
            >
              <MinusIcon />
            </RemoveSectionButton>
          </SectionHeader>
          <FormInput
            fullWidth
            variant="outlined"
            name="role"
            label="My role is [role name]"
            value={section.role}
            onChange={e => handleChange('volunteerExperience')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="organizationLocation"
            label="in [city, country]"
            value={section.organizationLocation}
            onChange={e => handleChange('volunteerExperience')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="organizationName"
            label="For [community group/organization]"
            value={section.organizationName}
            onChange={e => handleChange('volunteerExperience')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="volunteeringStartDate"
            label="I started [month, year]"
            value={section.volunteeringStartDate}
            onChange={e => handleChange('volunteerExperience')(e, i)}
          />
          <Radio
            name="isStillWorking"
            label="I am still working there"
            value="true"
            checked={String(section.isStillWorking) === 'true'}
            handleChange={e => handleChange('volunteerExperience')(e, i)}
          />
          <Radio
            name="isStillWorking"
            label="I stopped working there"
            value="false"
            checked={String(section.isStillWorking) === 'false'}
            handleChange={e => handleChange('volunteerExperience')(e, i)}
          />
          {!section.isStillWorking && (
            <FormInput
              fullWidth
              variant="outlined"
              name="volunteeringEndDate"
              label="I stopped [month, year]"
              value={section.volunteeringEndDate}
              onChange={e => handleChange('volunteerExperience')(e, i)}
              disabled={section.isStillWorking}
            />
          )}
          <FormInput
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            name="duties"
            label="My duties and responsibilities at this role include: [list duties]"
            value={section.duties}
            onChange={e => handleChange('volunteerExperience')(e, i)}
          />
        </SectionWrap>
      ))}
      <AddSectionButton onClick={() => handleAddClick('volunteerExperience')}>
        <AddSectionIconWrap>
          <PlusIcon />
        </AddSectionIconWrap>
        Add volunteer experience
      </AddSectionButton>
    </StepWrap>
  );
};

export default VolunteerExperience;
