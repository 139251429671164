import { BadgesLevelsEnum, BadgesTypesEnum } from 'pages/badges/BadgesEnum';

type BadgeDto = {
  __typename: 'Badges';
  color: string;
  id: string;
  level: BadgesLevelsEnum;
  type: BadgesTypesEnum;
};

type TopicDto = {
  __typename: 'Topics';
  id: string;
  name: string;
  badges: BadgeDto | null;
};

type CourseDto = {
  __typename: 'Course';
  id: string;
  name: string;
  badges: BadgeDto | null;
  topics: TopicDto[];
};

type PathsDto = {
  name: string;
  __typename: 'Path';
  courses: CourseDto[];
}[];

export enum BadgeOrigin {
  COURSE = 'course',
  TOPIC = 'topic'
}

export type ParsedBadge = {
  origin: BadgeOrigin;
  color: string;
  id: string;
  level: BadgesLevelsEnum;
  type: BadgesTypesEnum;
  courseId?: string;
  courseName?: string;
  topicId?: string;
  topicName?: string;
  isAcquired?: boolean;
} | undefined;

export type ParsedBadgesQuery = { pathName: string, pathBadges: ParsedBadge[] }[];

export default function parseBadgesQuery(pathsDto: PathsDto): ParsedBadgesQuery {
  return pathsDto.map(({ name: pathName, courses }) => {
    const pathBadges = courses.flatMap((course: CourseDto) => {
      const badgesArray = [];
      if (course.badges) {
        const { __typename, ...courseBadgeProperties } = course.badges; // eslint-disable-line @typescript-eslint/naming-convention
        badgesArray.push({
          origin: BadgeOrigin.COURSE,
          courseId: course.id,
          courseName: course.name,
          ...courseBadgeProperties
        });
      }
      if (course.topics) {
        const topicBadges = course.topics.flatMap(
          (topic: TopicDto) => { // eslint-disable-line array-callback-return
            if (topic.badges) {
              const { __typename, ...topicBadgeProperties } = topic.badges; // eslint-disable-line @typescript-eslint/naming-convention
              return {
                origin: BadgeOrigin.TOPIC,
                topicId: topic.id,
                topicName: topic.name,
                ...topicBadgeProperties
              };
            }
          }
        );
        badgesArray.push(...topicBadges);
      }
      return badgesArray;
    })
      .filter(badge => badge);
    return {
      pathName,
      pathBadges
    };
  });
}
