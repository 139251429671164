import {
  ChangeEvent,
  FC,
  useState
} from 'react';
import Radio from 'stepComponents/radio/Radio';
import { handleUserProgressDataChange } from '../../pages/courseControler/components/userProgress/userProgressHelper';

type RadioItem = {
  label: string;
  value: string;
  tooltipContentOnSuccess: string;
  tooltipContentOnError: string;
  correct: boolean;
};

export type RadioListPropsType = {
  _id?: string,
  storedValue? : {
    label?: string, value?: string, selectedValue?: string
  };
  radioItems: RadioItem[];
};

const RadioList: FC<RadioListPropsType> = ({ radioItems, _id, storedValue }) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(storedValue?.label);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, label?: string, value?: string) => {
    setSelectedValue((event.target as HTMLInputElement).value);
    handleUserProgressDataChange(`chosenRadio-${_id}`, { label, value, selectedValue: event.target.value });
  };

  return (
    <div>
      {radioItems.map(({ label, value }) => (
        <Radio
          key={value}
          label={label}
          value={value}
          checked={selectedValue === value}
          handleChange={(event) => handleChange(event, value, label)}
        />
      ))}
    </div>
  );
};

export default RadioList;
