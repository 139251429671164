import styled from 'styled-components';
import { colors } from 'common/colors';

export const LabelWrap = styled.div`
  color: ${colors.primary_gray4};
`;

export const ScoresWrap = styled.div`
  min-width: 95px;
  min-height: 45px;
  display: flex;
  flex-direction: column;
`;
