import { FC } from 'react';
import CircleWithChar from 'common/circleWithChar/CircleWithChar';
import DraggableItem from 'stepComponents/dndOrder/draggableItem/DraggableItem';
import { OrderCardWrap } from './OrderCardStyles';

type OrderCardPropsType = {
  cardNumber: number;
  item: string;
};

const OrderCard: FC<OrderCardPropsType> = ({ cardNumber, item }) => (
  <OrderCardWrap>
    <div>
      <CircleWithChar char={cardNumber} />
    </div>
    <DraggableItem content={item} />
  </OrderCardWrap>
);

export default OrderCard;
