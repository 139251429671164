import { FC } from 'react';
import { Tooltip } from 'stepComponents/index';
import { TooltipColorType } from 'stepComponents/tooltip/Tooltip';
import { CardWrap, AnswerWrap, LetterWrap } from './AnswerCardStyles';

type AnswerCardPropsType = {
  index: number;
  content: string;
  onClick: () => void;
  selected: boolean;
  showTooltip?: boolean;
  tooltipColor?: TooltipColorType;
  tooltipContent?: string;
};

const AnswerCard: FC<AnswerCardPropsType> = ({
  index,
  content,
  onClick,
  selected,
  showTooltip,
  tooltipColor,
  tooltipContent
}) => (
  <>
    <CardWrap
      onClick={onClick}
      selected={selected}
    >
      <LetterWrap>
        {`${String.fromCharCode(index + 65)}.`}
      </LetterWrap>
      <AnswerWrap>
        {content}
      </AnswerWrap>
    </CardWrap>
    {showTooltip ? (
      <Tooltip
        backgroundColor={tooltipColor}
        content={tooltipContent || ''}
      />
    ) : null}
  </>
);

export default AnswerCard;
