import { Step } from './Step';
import { MindfulnessBreakInterface } from './pathway.interface';

export class KnowledgeBites {
  name: string;

  id: string;

  steps: Step[];

  mindfulness_break: MindfulnessBreakInterface;

  constructor(name: string, steps?: Step[], id?: string, mindfulnessBreak?: MindfulnessBreakInterface) {
    this.name = name;
    this.steps = steps || [new Step('1')];
    this.id = id || 'noId';
    this.mindfulness_break = mindfulnessBreak || { description: undefined, videoUrl: undefined };
  }
}
