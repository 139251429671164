import styled, { keyframes } from 'styled-components';
import { colors } from 'common/colors';

const svgAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

const circleAnimation = keyframes`
  0%, 25% {
    stroke-dashoffset: 280;
    transform: rotate(0deg);
  }
  50%, 75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`;

export const AppSpinnerSvgWrap = styled.svg`
  animation: 2s linear infinite ${svgAnimation};
  max-width: 100px;
  
  circle {
    animation: 1.4s ease-in-out infinite both ${circleAnimation};
    display: block;
    fill: transparent;
    stroke: ${colors.primary_indigo};
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 180;
    stroke-width: 3px;
    transform-origin: 50% 50%;
  }
`;

export const AppSpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
