import { FC, useState } from 'react';
import defaultImg from 'assets/img/default_image.svg';
import { ReactComponent as CheckIcon } from 'assets/img/check_icon_thin.svg';
import { ReactComponent as IncorrectIcon } from 'assets/img/incorrect_icon.svg';
import { handleUserProgressDataChange } from 'pages/courseControler/components/userProgress/userProgressHelper';
import { colors } from 'common/colors';
import {
  CardWrap, ImgContainer, DotsContainer, Dot, InfoWrap, ImgTitle, ImgWrap, ControlArrow, ButtonRow, RoundButton
} from './CvReviewerStyles';

export type CvReviewerPropsType = {
  _id?: string;
  isButtons: boolean;
  cardTitle: string;
  images: {
    url: string;
  }[];
};

enum ReviewStatusEnum {
  Keep = 'keep',
  Reject = 'reject'
}

const CvReviewer: FC<CvReviewerPropsType> = ({
  _id, isButtons, cardTitle, images
}) => {
  const [currentImgIndex, setCurrentImgIndex] = useState<number>(0);
  const [reviewStatus, setReviewStatus] = useState<ReviewStatusEnum[]>([]);

  const handleBack = () => {
    if (currentImgIndex === 0) return;
    setCurrentImgIndex(prevIdx => prevIdx - 1);
  };

  const handleNext = () => {
    if (currentImgIndex === images.length - 1) return;
    setCurrentImgIndex(prevIdx => prevIdx + 1);
  };

  const handleButtonClick = (status: ReviewStatusEnum) => () => {
    // heve to spread array to create new object reference
    const newStatus = [...reviewStatus];
    newStatus[currentImgIndex] = status;
    handleUserProgressDataChange(`${_id}`, newStatus);
    setReviewStatus(newStatus);
    handleNext();
  };

  return (
    <div>
      <CardWrap>
        <ImgWrap>
          <ImgContainer src={images[currentImgIndex].url || defaultImg} alt={cardTitle} />
          {images.length > 1 && (
            <>
              <DotsContainer>
                {images.map((img, idx) => (
                  <Dot key={`dot-${idx + 1}`} isActive={idx === currentImgIndex} />
                ))}
              </DotsContainer>
              <ControlArrow
                position="left"
                onClick={handleBack}
              />
              <ControlArrow
                position="right"
                onClick={handleNext}
              />
            </>
          )}
        </ImgWrap>
        <InfoWrap>
          <ImgTitle>
            {cardTitle}
          </ImgTitle>
        </InfoWrap>
      </CardWrap>
      {isButtons && (
        <ButtonRow>
          <RoundButton
            variant="outlined"
            onClick={handleButtonClick(ReviewStatusEnum.Reject)}
            style={
              reviewStatus[currentImgIndex] === ReviewStatusEnum.Reject
                ? { backgroundColor: colors.primary_indigo_shade2 } : undefined
            }
          >
            <IncorrectIcon />
            Reject
          </RoundButton>
          <RoundButton
            variant="outlined"
            onClick={handleButtonClick(ReviewStatusEnum.Keep)}
            style={
              reviewStatus[currentImgIndex] === ReviewStatusEnum.Keep
                ? { backgroundColor: colors.primary_indigo_shade2 } : undefined
            }
          >
            <CheckIcon />
            Keep
          </RoundButton>
        </ButtonRow>
      )}
    </div>
  );
};

export default CvReviewer;
