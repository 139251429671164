import React, { FC } from 'react';
import FormInput from 'common/formInput/FormInput';
import Tooltip from 'stepComponents/tooltip/Tooltip';
import Radio from 'stepComponents/radio/Radio';
import { useCvBuilder } from 'utils/CvBuilderContext';
import Description from 'stepComponents/description/Description';
import { ReactComponent as MinusIcon } from 'assets/img/minus_icon.svg';
import { ReactComponent as PlusIcon } from 'assets/img/plus_icon.svg';
import {
  StepWrap,
  StepHeader,
  SectionWrap,
  SectionHeader,
  AddSectionButton,
  AddSectionIconWrap,
  RemoveSectionButton
} from '../styles';

export type WorkExperienceReferencesTextInputPropertiesType = {
  name: string;
  phone: string;
  email: string;
  reference: string;
};

export type WorkExperienceTextInputPropertiesType = {
  companyName: string;
  companyLocation: string;
  jobTitle: string;
  employmentStartDate: string;
  employmentEndDate: string;
  duties: string;
  references: WorkExperienceReferencesTextInputPropertiesType[];
};

export type WorkExperienceRadioInputPropertiesType = {
  isStillWorking: boolean;
};

export type WorkExperienceSectionType = WorkExperienceTextInputPropertiesType & WorkExperienceRadioInputPropertiesType;

const WorkExperience: FC = () => {
  const {
    values: { workExperience },
    handleChange,
    handleAddClick,
    handleRemoveClick
  } = useCvBuilder();

  return (
    <StepWrap>
      <StepHeader isDescription>
        Work Experience
      </StepHeader>
      <Description
        className="body16Regular"
        content="Add your paid work experience here. If you don&apos;t have any, skip this section."
      />
      {workExperience.map((section, i) => (
        <SectionWrap key={`workplace-${i + 1}`}>
          <SectionHeader>
            Workplace {i + 1}
            <RemoveSectionButton
              onClick={() => handleRemoveClick('workExperience', i)}
            >
              <MinusIcon />
            </RemoveSectionButton>
          </SectionHeader>
          <FormInput
            fullWidth
            variant="outlined"
            name="companyName"
            label="I work at [type company name]"
            value={section.companyName}
            onChange={e => handleChange('workExperience')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="companyLocation"
            label="in [city, country]"
            value={section.companyLocation}
            onChange={e => handleChange('workExperience')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="jobTitle"
            label="My role is [job title]"
            value={section.jobTitle}
            onChange={e => handleChange('workExperience')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="employmentStartDate"
            label="I started [month, year]"
            value={section.employmentStartDate}
            onChange={e => handleChange('workExperience')(e, i)}
          />
          <Radio
            name="isStillWorking"
            label="I am still working there"
            value="true"
            checked={String(section.isStillWorking) === 'true'}
            handleChange={e => handleChange('workExperience')(e, i)}
          />
          <Radio
            name="isStillWorking"
            label="I stopped working there"
            value="false"
            checked={String(section.isStillWorking) === 'false'}
            handleChange={e => handleChange('workExperience')(e, i)}
          />
          {!section.isStillWorking && (
            <FormInput
              fullWidth
              variant="outlined"
              name="employmentEndDate"
              label="I stopped [month, year]"
              value={section.employmentEndDate}
              onChange={e => handleChange('workExperience')(e, i)}
              disabled={section.isStillWorking}
            />
          )}
          <FormInput
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            name="duties"
            label="My duties and responsibilities at this job include: [list duties]"
            value={section.duties}
            onChange={e => handleChange('workExperience')(e, i)}
          />
          {(i === workExperience.length - 1) && (
            <Tooltip
              closeButton
              backgroundColor="primary_aquamarine"
              title="Tip"
              content="Use action words such as: negotiated, managed, improved, developed, supervised"
            />
          )}
          <SectionWrap>
            {section.references.map((reference, insideIndex) => (
              <React.Fragment key={`reference_${insideIndex + 1}`}>
                <SectionHeader>
                  References {insideIndex + 1}
                  <RemoveSectionButton
                    onClick={() => handleRemoveClick('workExperience', i, 'references', insideIndex)}
                  >
                    <MinusIcon />
                  </RemoveSectionButton>
                </SectionHeader>
                <FormInput
                  fullWidth
                  variant="outlined"
                  name="name"
                  label="Name of person giving reference:"
                  value={reference.name}
                  onChange={e => handleChange('workExperience', 'references')(e, i, insideIndex)}
                />
                <FormInput
                  fullWidth
                  variant="outlined"
                  name="phone"
                  label="Phone Number:"
                  value={reference.phone}
                  onChange={e => handleChange('workExperience', 'references')(e, i, insideIndex)}
                />
                <FormInput
                  fullWidth
                  variant="outlined"
                  name="email"
                  label="Email:"
                  value={reference.email}
                  onChange={e => handleChange('workExperience', 'references')(e, i, insideIndex)}
                />
                <FormInput
                  fullWidth
                  multiline
                  rows={5}
                  variant="outlined"
                  name="reference"
                  label="Content of reference"
                  value={reference.reference}
                  onChange={e => handleChange('workExperience', 'references')(e, i, insideIndex)}
                />
              </React.Fragment>
            ))}
            <AddSectionButton onClick={() => handleAddClick('workExperience', 'references', i)}>
              <AddSectionIconWrap>
                <PlusIcon />
              </AddSectionIconWrap>
              Add reference entry
            </AddSectionButton>
          </SectionWrap>
        </SectionWrap>
      ))}
      <AddSectionButton onClick={() => handleAddClick('workExperience')}>
        <AddSectionIconWrap>
          <PlusIcon />
        </AddSectionIconWrap>
        Add work entry
      </AddSectionButton>
    </StepWrap>
  );
};

export default WorkExperience;
