import React from 'react';
import { FooterMenuWrap } from './FooterMenuStyles';
import MenuButtonPathways from './menu-buttons/MenuButtonPathways';
import MenuButtonProfile from './menu-buttons/MenuButtonProfile';
import MenuButtonBadges from './menu-buttons/MenuButtonBadges';
import MenuButtonCertificates from './menu-buttons/MenuButtonCertificates';

function FooterMenu(): JSX.Element {
  return (
    <FooterMenuWrap>
      <MenuButtonPathways />
      <MenuButtonBadges />
      <MenuButtonCertificates />
      <MenuButtonProfile />
    </FooterMenuWrap>
  );
}

export default FooterMenu;
