import styled from 'styled-components';

export const CardQuizWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionWrap = styled.h4`
  margin-bottom: 15px;
`;
