import {
  Document, Image, Page, StyleSheet, Text, View
} from '@react-pdf/renderer';
import { colors } from 'common/colors';
import backgroundImg from 'assets/img/certificate_pdf_background.png';
import institutionLogoPlaceholder from 'assets/img/institution_logo_placeholder.png';

type CertificateTemplateProps = {
  username: string;
  pathName: string;
  certName: string;
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    position: 'absolute',
    width: '80%',
    height: '80%',
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bgImg: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  img: {
    position: 'absolute',
    width: 60,
    height: 60,
    top: 30,
  },
  institutionName: {
    position: 'absolute',
    fontSize: 14,
    color: colors.primary_gray4,
    fontWeight: 700,
    top: 100
  },
  certificate: {
    position: 'absolute',
    fontSize: 26,
    fontWeight: 700,
    textTransform: 'uppercase',
    top: 130
  },
  ofAchievement: {
    position: 'absolute',
    fontSize: 10,
    fontWeight: 500,
    textTransform: 'uppercase',
    top: 165
  },
  name: {
    position: 'absolute',
    fontSize: 24,
    fontWeight: 'bold',
    top: 200
  },
  dots: {
    position: 'absolute',
    color: colors.primary_gray4,
    top: 215
  },
  hasCompleted: {
    position: 'absolute',
    fontSize: 18,
    fontWeight: 600,
    color: 'black',
    top: 250
  },
  pathwayName: {
    position: 'absolute',
    fontSize: 24,
    fontWeight: 500,
    color: 'black',
    top: 280
  },
  signedContainer: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    top: 380,
    left: 80
  },
  dateContainer: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexGrow: 1,
    top: 380,
    right: 80
  },
  bottomDots: {
    color: colors.primary_gray4,
  },
  label: {
    fontSize: 12,
    fontWeight: 400
  }
});

const CertificateTemplate = ({
  certName,
  pathName,
  username
}: CertificateTemplateProps): JSX.Element => (
  <Document>
    <Page size="A4" orientation="landscape">
      <View style={styles.container}>
        <Image src={backgroundImg} />
        <View style={styles.box}>
          <Image src={institutionLogoPlaceholder} style={styles.img} />
          <Text style={styles.institutionName}>Name of Institution</Text>
          <Text style={styles.certificate}>Certificate</Text>
          <Text style={styles.ofAchievement}>of {certName}</Text>
          <Text style={styles.name}>{username}</Text>
          <Text style={styles.dots}>
            .......................................................................................................
          </Text>
          <Text style={styles.hasCompleted}>has completed</Text>
          <Text style={styles.pathwayName}>Pathway - {pathName}</Text>
          <View style={styles.signedContainer}>
            <Text style={styles.bottomDots}>
              ....................
            </Text>
            <Text style={styles.label}>
              Signed
            </Text>
          </View>
          <View style={styles.dateContainer}>
            <Text style={styles.bottomDots}>
              ....................
            </Text>
            <Text style={styles.label}>
              Date
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default CertificateTemplate;
