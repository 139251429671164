import styled from 'styled-components';
import { colors } from 'common/colors';

export const LetterBoxContainerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: 1px dashed ${colors.primary_gray5};
  user-select: none;
  background-color: ${colors.primary_gray_light4};
`;
