import styled from 'styled-components';
import { colors } from 'common/colors';

export type OptionType = 'fill' | 'outline' | 'fill_disabled' | 'outline_disabled';

export const AppButtonWrap = styled.button<{
  option: OptionType,
  fullWidth?: boolean,
}>`
  border-radius: 10px;
  padding: 12px 15px;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  font-family: 'AppMulish',serif;
  
  ${props => props.option === 'fill' && `
  background-color: ${colors.primary_indigo};
  color: ${colors.primary_white};
  
  &:hover, &:focus, &:active {
  outline: none;
  box-shadow: 0px 4px 9px rgba(54, 52, 163, 0.3);
  }
  `}
  ${props => props.option === 'fill_disabled' && `
  background-color: ${colors.primary_gray_light2};
  color: ${colors.primary_gray5};
`}
  ${props => props.option === 'outline' && `
  background-color: ${colors.primary_white};
  color: ${colors.primary_indigo};
  border: 1px solid ${colors.primary_gray_light2};
  
   &:hover, &:focus, &:active {
  outline: none;
  box-shadow: 0px 4px 9px rgba(54, 52, 163, 0.3);
  }  
`}

  ${props => props.option === 'outline_disabled' && `
  background-color: ${colors.primary_white};
  color: ${colors.primary_gray4};
  border-style: none;
  border: 1px solid ${colors.primary_gray_light4};
`}
`;
