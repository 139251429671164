import { Snackbar } from '@material-ui/core';
import {
  FC, useState, createContext, useContext
} from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';

const StyledSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root .MuiSnackbarContent-root {
    justify-content: center;
    background: ${colors.primary_indigo};
    font-family: AppMulish;
    font-weight: 600;
    font-size: 16px,
  }
`;

type SetSnackbarParams = {
  message: string;
};

type SnackbarStateType = {
  openSnackbar: ({ message }: SetSnackbarParams) => void;
};

export const SnackbarContext = createContext<SnackbarStateType | undefined>(undefined);

export const useSnackbar: () => SnackbarStateType = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useAuth must be used within SnackbarContext');
  }
  return context;
};

export const SnackbarProvider: FC = ({ children }) => {
  const [snackbarOptions, setSnackbarOptions] = useState({ open: false, message: '' });

  const openSnackbar = ({ message }: SetSnackbarParams) => {
    setSnackbarOptions({ open: true, message });
  };

  const closeSnackbar = () => {
    setSnackbarOptions({ open: false, message: '' });
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      <StyledSnackbar
        autoHideDuration={4000}
        onClose={closeSnackbar}
        open={snackbarOptions.open}
        message={snackbarOptions.message}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      />
      {children}
    </SnackbarContext.Provider>
  );
};
