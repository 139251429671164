import { StepComponentInterface } from './pathway.interface';
import { StepTypeEnum } from '../courseControler/components/stepBuilder/StepTypeEnum';

export class Step {
  id: string;

  name: string;

  stepType?: StepTypeEnum | string;

  stepComponents: StepComponentInterface[];

  constructor(id?: string, name?: string, stepType?: StepTypeEnum | string, stepComponents?: StepComponentInterface[]) {
    this.id = id || '00000000000';
    this.stepComponents = stepComponents || [];
    this.stepType = stepType || '';
    this.name = name || 'noName';
  }
}
