import Pathway from 'pages/pathway/Pathway';
import { Course } from 'pages/pathway/Course';
import { Topic } from 'pages/pathway/Topic';
import { BadgeInterface, MindfulnessBreakInterface } from 'pages/pathway/pathway.interface';

type DataLevelType = 'pathway' | 'course' | 'topic' | 'step' | 'completedKnowledgeBite' |
  'completedTopic' | 'completedCourse' | 'completedPathway';

type BasicPathwayDataType = {
  id: string,
  dataLevel: DataLevelType,
  name: string
};

type PathwayDataType = BasicPathwayDataType & {
  imgUrl: string,
  content: string,
  quote: {
  content: string;
  author: string
  },
  courses: Course[],
  certificate: {
    name: string;
    content: string;
  }
};

type CourseDataType = BasicPathwayDataType & {
  pathwayName: string,
  courseOrder: number,
  content: string
  topics: Topic[],
  badges: BadgeInterface,
};

type TopicDataType = BasicPathwayDataType & {
  pathwayName: string,
  courseName: string,
  courseId: string,
  currentTopicOrder: number,
  description: string,
  whatYouWillLearn: { name: string }[],
  whatYouWillDo: { name: string }[],
  badges: BadgeInterface
};

type StepDataType = BasicPathwayDataType & {
  pathwayName: string,
  courseName: string,
  topicName: string,
  knowledgeBiteId: number
};

export type CompletedKnowledgeBiteDataType = BasicPathwayDataType & {
  pathwayName: string,
  courseName: string,
  topicName: string,
  kbInTopic: number,
  currentKBOrder: number,
  mindfulnessBreak: MindfulnessBreakInterface
};

type CompletedTopicDataType = BasicPathwayDataType & {
  pathwayName: string,
  courseName: string,
  currentTopicOrder: number,
  badges: BadgeInterface,
  mindfulnessBreak: MindfulnessBreakInterface
};

type CompletedCourseDataType = BasicPathwayDataType & {
  pathwayName: string,
  name: string,
  badges: BadgeInterface,
  courseOrder: number,
  mindfulnessBreak: MindfulnessBreakInterface
};

type CompletedPathway = BasicPathwayDataType & {
  certificate: { name: string, content: string },
  mindfulnessBreak: MindfulnessBreakInterface
};

export type SingleLevelThreadPossibleTypes = PathwayDataType | CourseDataType | TopicDataType | StepDataType |
  CompletedKnowledgeBiteDataType | CompletedTopicDataType | CompletedCourseDataType | CompletedPathway;

let stepsInPathway: { id: string, name: string, pathwayId: string, courseName: string, topicName: string }[] = [];

const navigationSingleLevelTread = (pathway: Pathway): SingleLevelThreadPossibleTypes[] => {
  const viewsArray = [];
  stepsInPathway = [];
  const sortedCourses = [...pathway.courses].sort((a, b) => a.orderNumber - b.orderNumber);
  viewsArray.push({
    id: pathway.id,
    dataLevel: 'pathway' as DataLevelType,
    name: pathway.name,
    imgUrl: pathway.image.url,
    content: pathway.content,
    quote: pathway.quote,
    courses: sortedCourses,
    certificate: pathway.certificate
  });
  sortedCourses.forEach((course, index) => {
    const sortedTopics = [...course.topics].sort((a, b) => a.orderNumber - b.orderNumber);
    viewsArray.push({
      id: course.id,
      dataLevel: 'course' as DataLevelType,
      pathwayName: pathway.name,
      name: course.name,
      courseOrder: index,
      content: course.content,
      topics: sortedTopics,
      badges: course.badges,
    });
    if (sortedTopics.length > 0) {
      sortedTopics.forEach((topic, idx) => {
        viewsArray.push({
          id: topic.id,
          dataLevel: 'topic' as DataLevelType,
          pathwayName: pathway.name,
          courseName: course.name,
          courseId: course.id,
          name: topic.name,
          currentTopicOrder: idx,
          description: topic.description,
          whatYouWillLearn: topic.what_you_will_learn,
          whatYouWillDo: topic.what_you_will_do,
          badges: topic.badges
        });
        if (topic.knowledgeBites.length > 0) {
          const sortedKnowledgeBites = [...topic.knowledgeBites].sort((a, b) => (a.name < b.name ? -1 : 1));
          sortedKnowledgeBites.forEach((kb, kbIdx) => {
            if (kb.steps && kb.steps.length > 0) {
              const sortedSteps = [...kb.steps].sort((a, b) => (a.name < b.name ? -1 : 1));
              sortedSteps.forEach(step => {
                viewsArray.push({
                  id: step.id,
                  dataLevel: 'step' as DataLevelType,
                  name: step.name,
                  pathwayName: pathway.name,
                  courseName: course.name,
                  topicName: topic.name,
                  knowledgeBiteId: kb.id
                });
                stepsInPathway.push({
                  id: step.id,
                  name: step.name,
                  pathwayId: pathway.id,
                  courseName: course.name,
                  topicName: topic.name
                });
              });
            }
            viewsArray.push({
              id: kb.id,
              name: kb.name,
              dataLevel: 'completedKnowledgeBite' as DataLevelType,
              pathwayName: pathway.name,
              courseName: course.name,
              topicName: topic.name,
              kbInTopic: topic.knowledgeBites.length,
              currentKBOrder: kbIdx,
              mindfulnessBreak: kb.mindfulness_break
            });
          });
        }
        viewsArray.push({
          id: topic.id,
          dataLevel: 'completedTopic' as DataLevelType,
          pathwayName: pathway.name,
          courseName: course.name,
          name: topic.name,
          currentTopicOrder: idx,
          badges: topic.badges,
          mindfulnessBreak: topic.mindfulness_break
        });
      });
    }
    viewsArray.push({
      id: course.id,
      dataLevel: 'completedCourse' as DataLevelType,
      pathwayName: pathway.name,
      name: course.name,
      badges: course.badges,
      courseOrder: index,
      mindfulnessBreak: course.mindfulness_break
    });
  });
  viewsArray.push({
    id: pathway.id,
    dataLevel: 'completedPathway' as DataLevelType,
    name: pathway.name,
    certificate: pathway.certificate,
    mindfulnessBreak: pathway.mindfulness_break
  });
  return viewsArray;
};

const getAllStepsInPathway = (): {
  id: string,
  pathwayId: string,
  courseName: string,
  topicName: string
}[] => stepsInPathway;

export {
  navigationSingleLevelTread,
  getAllStepsInPathway
};
