import styled from 'styled-components';

export const CertificatesWrap = styled.div`
  width: 100%;
  padding: 25px 30px;
  user-select: none;
  .heading {
    margin-bottom: 40px;
  }
`;
