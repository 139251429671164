import { FunctionComponent } from 'react';
import {
  CardWrap, CardImg, ImgPlaceholder, Title
} from './CardStyles';

export type CardProps = {
  img?: { url: string };
  imgDescription?: string;
  title: string;
};

const Card: FunctionComponent<CardProps> = ({ img, imgDescription, title }) => (
  <CardWrap>
    <CardImg>
      {img ? <img src={img.url} alt={imgDescription || ''} /> : <ImgPlaceholder />}
    </CardImg>
    <Title>{title}</Title>
  </CardWrap>
);

export default Card;
