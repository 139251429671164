import { FC } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import useDndOrder from 'stepComponents/dndOrder/useDndOrder';
import { DndContext } from '@dnd-kit/core';
import OrderCard from 'stepComponents/dndOrder/orderCard/OrderCard';
import { DndOrderWrap } from './DndOrderStyles';

export type DndOrderPropsType = {
  cardItems: { name: string }[];
};

const DndOrder: FC<DndOrderPropsType> = ({ cardItems }) => {
  const {
    items,
    sensors,
    handleDragEnd
  } = useDndOrder(cardItems);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        id="dnd-order"
        items={items}
        strategy={verticalListSortingStrategy}
      >
        <DndOrderWrap>
          {items.map((item, idx) => (
            <OrderCard cardNumber={idx + 1} item={item} />
          ))}
        </DndOrderWrap>
      </SortableContext>
    </DndContext>
  );
};

export default DndOrder;
