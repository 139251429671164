import { DocumentNode, gql } from '@apollo/client';
import { ComponentsTypesEnum } from 'stepComponents/enums/ComponentsTypesEnum';

const GQL_COMPONENTS = `... on ${ComponentsTypesEnum.description} {
  content,
  className
}
... on ${ComponentsTypesEnum.rangeSlider} {
  _id,
  header,
  beginningLabel
  middleLabel,
  endLabel,
  minValue,
  maxValue,
  rangeContent{
    minValue,
    maxValue,
    tooltipColor,
    content
  }
} 
... on ${ComponentsTypesEnum.image} {
  name,
  image{
    url
  }
}
... on ${ComponentsTypesEnum.checkboxList} {
  _id,
  listStyle,
  checkboxItems{
    name,
    label,
    tooltipContentOnSuccess,
    tooltipContentOnError,
    correct
  }
}
... on ${ComponentsTypesEnum.radioList} {
  _id,
  radioItems{
    value,
    label,
    tooltipContentOnSuccess,
    tooltipContentOnError,
    correct
  }
}
... on ${ComponentsTypesEnum.video} {
  url
}
... on ${ComponentsTypesEnum.input} {
  _id,
  label,
  placeholder
}
... on ${ComponentsTypesEnum.announceCard} {
  name,
  description
}
... on ${ComponentsTypesEnum.rangeNumbered} {
  _id,
  minValue,
  maxValue,
  beginningLabel,
  middleLabel,
  endLabel
}
... on ${ComponentsTypesEnum.cardQuiz} {
  question,
  answerItems {
    answer,
    value,
    tooltipColor,
    tooltipContent
  }
}
... on ${ComponentsTypesEnum.styledList} {
  listItems {
    name
  },
  markerImg {
    url
  }
}
... on ${ComponentsTypesEnum.dnd} {
  dndItems {
    name
  },
  containerNames {
    name
  }
}
... on ${ComponentsTypesEnum.tooltip} {
  backgroundColor,
  icon,
  iconPosition,
  closeButton,
  title,
  content
}
... on ${ComponentsTypesEnum.cardsList} {
  cardsListItems {
    title,
    imgDescription,
    img {
      url
    }
  }
}
... on ${ComponentsTypesEnum.cardNumbered} {
  _id,
  cardNumber,
  title,
  subtitle,
  content,
  isInput,
  inputLabel,
  inputPlaceholder
}
... on ${ComponentsTypesEnum.dropdownList} {
  dropdownListItems {
    name
  },
  dropdownLabel
}
... on ${ComponentsTypesEnum.dndLetters} {
  cardItems {
    letter,
    title,
    description
  }
}
... on ${ComponentsTypesEnum.dndOrder} {
  cardItems {
    name
  }
}
... on ${ComponentsTypesEnum.cvReviewer} {
  _id,
  cardTitle,
  images {
    url
  },
  isButtons
}`;

const MINDFULNESS_BREAK = `
  mindfulness_break {
    description,
    videoUrl,
    image {
      name
      url
    }
  }
`;

export const GET_PATHS_QUERY = gql`
{
  paths {
   id
   name
   content
   isLocked
   isHidden
   image {
     url
   },
  orderNumber
  }
}
`;

export const GET_SINGLE_PATHWAY_QUERY: (id: string) => DocumentNode = (id: string) => gql`
    {
        path(id: "${id}") {
            id,
            name,
            content,
            quote {
                author,
                content
            }
            image {
                name,
                url
            },
            certificate {
                id,
                name,
                content
            }
            ${MINDFULNESS_BREAK},
            courses {
                id,
                name,
                orderNumber,
                content,
                ${MINDFULNESS_BREAK},
                badges {
                    type,
                    level,
                    color
                },
                topics {
                    id
                    name,
                    orderNumber,
                    description,
                    ${MINDFULNESS_BREAK},
                    what_you_will_do {
                        name
                    },
                    what_you_will_learn {
                        name
                    },
                    knowledgeBites {
                        id,
                        name,
                        ${MINDFULNESS_BREAK},
                        steps {
                            id,
                            name
                        }
                    }
                    badges {
                        type,
                        level,
                        color
                    }
                }
            }
        }
    }
`;

export const GET_SINGLE_STEP_QUERY: (id: string) => DocumentNode = (id) => gql`
  {
    step(id: "${id}"){
      id,
      name,
      stepType,
      stepComponents{
        __typename,
        ${GQL_COMPONENTS}
        ...on ${ComponentsTypesEnum.tabs}{
          id,
          tabs{
            id,
            name,
            content{
              __typename
              ${GQL_COMPONENTS}              
            }
          }
        }
      }
    }
  }
`;

export const GET_BADGES_QUERY = gql`
  {
    paths {
      name
      courses {
        id
        name
        badges {
          id
          type
          level
          color
        }
        topics {
          id
          name
          badges {
            id
            type
            level
            color
          } 
        }
      }
    }
  }
`;

export const GET_CERTIFICATES_QUERY = gql`
  {
    certificates {
      id,
      name,
      path {
        name
      }
    }
  }
`;
