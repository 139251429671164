import { Suspense, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import StepBuilder from 'pages/courseControler/components/stepBuilder/StepBuilder';
import AppSpinner from 'common/appSpinner/AppSpinner';
import { GET_SINGLE_STEP_QUERY } from 'utils/gqlQueries';
import useScrollToTop from 'utils/useScrollToTop';
import { Step } from 'pages/pathway/Step';
import WithNavigation from 'pages/courseControler/components/navigation/WithNavigation';
import { Error } from 'common/error/Error';
import { StepDisplayWrap, StepWrap } from './StepDisplayStyles';

// TODO create empty step as error fallback

function StepDisplay(): JSX.Element {
  const { stepId } = useParams<{ stepId: string }>();
  const [queryExecutor, { data, loading, error }] = useLazyQuery<{ step: Step }>(GET_SINGLE_STEP_QUERY(stepId));
  const [step, setStep] = useState<Step>(new Step());

  useEffect(() => {
    if (stepId) {
      queryExecutor();
    }
  }, [stepId]);

  useEffect(() => {
    if (!loading && data && data.step) {
      setStep(data.step);
    }
  }, [data, loading, stepId]);

  const ref = useScrollToTop(stepId);

  if (error) {
    return <Error>Error occurred! Wrong stepId: {stepId}.</Error>;
  }

  return (
    <WithNavigation level="step">
      <StepDisplayWrap>
        <Suspense fallback={<AppSpinner />}>
          <StepWrap ref={ref}>
            {loading ? <AppSpinner /> : <StepBuilder step={step} />}
          </StepWrap>
        </Suspense>
      </StepDisplayWrap>
    </WithNavigation>
  );
}

export default StepDisplay;
