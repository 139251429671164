import React, { FC } from 'react';
import BadgeIcon from 'common/badgeIcon/BadgeIcon';
import { Topic } from 'pages/pathway/Topic';
import { ReactComponent as CertificateMarker } from 'assets/img/cert_marker.svg';
import {
  TopicItemsRowWrap,
  TopicsListWrap,
  IsCompleted,
  TopicListElement,
  IsCompletedWrap
} from './AppTopicsListStyles';

type AppTopicsListPropsType = {
  topics: Topic[],
  completedTopics?: { id: string }[]
};

const AppTopicsList: FC<AppTopicsListPropsType> = ({ topics, completedTopics }) => {
  const isCompleted = (id: string): boolean =>
    !!completedTopics && completedTopics.findIndex(element => element.id === id) >= 0;
  return (
    <TopicsListWrap>
      {
        topics.map((topic) => (
          <TopicListElement key={topic.id}>
            <li className="h5 list" key={topic.name}>
              {topic.name}
              <div>
                <TopicItemsRowWrap>
                  <CertificateMarker />
                  {topic.knowledgeBites.length}
                  <span>Knowledge Bites</span>
                </TopicItemsRowWrap>
                <TopicItemsRowWrap>
                  <BadgeIcon
                    badgeType={topic.badges?.type}
                    badgeLevel={topic.badges?.level}
                  />
                  1
                  <span>{`Badge of ${topic.badges?.type}`}</span>
                </TopicItemsRowWrap>
              </div>
            </li>
            <IsCompletedWrap>
              { isCompleted(topic.id) && <IsCompleted />}
            </IsCompletedWrap>
          </TopicListElement>
        ))
      }
    </TopicsListWrap>
  );
};

export default AppTopicsList;
