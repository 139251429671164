import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'common/button/Button';
import { ReactComponent as ArrowLeftIcon } from 'assets/img/arrow_left.svg';
import { ReactComponent as BellIcon } from 'assets/img/bell.svg';
import { HeaderWrap } from './HeaderStyles';

const Header: FC = () => {
  const history = useHistory();
  const onBackButtonClick = () => {
    if (history) history.push('/');
  };
  return (
    <HeaderWrap>
      <Button
        variant="outlined"
        onClick={onBackButtonClick}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        variant="outlined"
        disabled
      >
        <BellIcon />
      </Button>
    </HeaderWrap>
  );
};

export default Header;
