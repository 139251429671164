import { FC, useEffect, useState } from 'react';
import { ReactComponent as SaveIcon } from 'assets/img/save_material_icon.svg';
import { ReactComponent as SavedIcon } from 'assets/img/saved_material_icon.svg';
import { SaveMaterialWrap } from './SaveMaterialStyles';
import { addSavedMaterialInPathway, isMaterialSaved } from '../../userProgress/userProgressHelper';

type SaveMaterialPropsType = {
  stepId: string
};

const SaveMaterial: FC<SaveMaterialPropsType> = ({ stepId }) => {
  const isSaved = isMaterialSaved(stepId);
  const [saved, setSaved] = useState(isSaved);

  useEffect(() => {
    setSaved(isSaved);
  }, [stepId]);

  function saveMaterial() {
    addSavedMaterialInPathway(stepId);
    setSaved(!saved);
  }
  return (
    <>
      { saved
        ? (
          <SaveMaterialWrap>
            <SavedIcon /><span className="button16Semibold">Material saved</span>
          </SaveMaterialWrap>
        )
        : (
          <SaveMaterialWrap
            onClick={() => saveMaterial()}
          >
            <SaveIcon />
            <span className="button16Semibold">Save this material</span>
          </SaveMaterialWrap>
        )}
    </>
  );
};

export default SaveMaterial;
