import styled from 'styled-components';
import { colors } from 'common/colors';

export const LetterWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  border-radius: 10px;
  border: 1px solid ${colors.primary_gray_light1};
  user-select: none;
  background-color: ${colors.primary_white};
`;

export const Letter = styled.h4`
  color: ${colors.primary_indigo};
  text-transform: uppercase;
`;
