import styled from 'styled-components';
import { colors } from 'common/colors';

export const CardsListWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  background-color: ${colors.secondary_green};
`;
