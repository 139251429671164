import {
  createContext,
  useContext,
  useState,
  useMemo,
  FC,
  Dispatch,
  SetStateAction
} from 'react';
import Pathway from 'pages/pathway/Pathway';
import { Course } from 'pages/pathway/Course';
import { Topic } from 'pages/pathway/Topic';

type PathwayContextResult = [Pathway, Dispatch<SetStateAction<Pathway>>];

const defaultPathwayContext: PathwayContextResult = [
  new Pathway('noId', 'defaultPathway'),
  () => Pathway
];

const PathwayContext = createContext(defaultPathwayContext);

const usePathway: () => PathwayContextResult = () => {
  const pathway = useContext(PathwayContext);
  if (!pathway) {
    throw new Error('usePathway must be used within PathwayProvider');
  }
  return pathway;
};

const PathwayProvider: FC = (props) => {
  const [pathwayState, setPathwayState] = useState<Pathway>(defaultPathwayContext[0]);
  const pathValue = useMemo(
    () => [pathwayState, setPathwayState], [pathwayState]
  ) as PathwayContextResult;
  return <PathwayContext.Provider value={pathValue} {...props} />;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePathwayActions = () => {
  const [pathwayContext] = usePathway();
  const courses = pathwayContext.courses;
  const getCourse = (courseId: string): Course | undefined => courses.find(course => course.id === courseId);
  const knowledgeBitesPerCourse = (courseId: string) => {
    const course = getCourse(courseId);
    if (!course) {
      return -1;
    }
    let kbNumber = 0;
    course.topics.forEach((topic: Topic) => {
      kbNumber += topic.knowledgeBites.length;
    });
    return kbNumber;
  };
  const stepsInCourse = (courseId: string) => {
    const course = getCourse(courseId);
    if (!course) {
      return -1;
    }
    let stepsNumber = 0;
    course.topics.forEach(topic =>
    topic.knowledgeBites.forEach(kb => { stepsNumber += kb.steps.length; }));
    return stepsNumber;
  };
  const allStepsInPathwayFlat = () : { id: string, courseName: string }[] => {
    const stepsArray: { id: string; courseName: string; }[] = [];
    pathwayContext.courses.forEach(course => {
      course.topics.forEach(topic => {
        topic.knowledgeBites.forEach(kb => {
          kb.steps.forEach(step => {
            stepsArray.push({ id: step.id, courseName: course.name });
          });
        });
      });
    });
    return stepsArray;
  };
  const badgesInPathway = (): number => {
    let count = 0;
    pathwayContext.courses.forEach(course => { count += course.topics.length + 1; });
    return count;
  };
  return {
    allStepsInPathwayFlat,
    badgesInPathway,
    getCourse,
    knowledgeBitesPerCourse,
    stepsInCourse
  };
};

export {
  usePathway,
  PathwayProvider,
  usePathwayActions
};
