import styled from 'styled-components';

export enum CheckboxListStylesEnum {
  'default' = 'default',
  'pills' = 'pills'
}

export const CheckboxListWrap = styled.div<{
  listStyle: CheckboxListStylesEnum
}>`
${({ listStyle }) => listStyle === CheckboxListStylesEnum.pills
  && `display: flex;
      flex-flow: row wrap;
      .MuiButtonBase-root{
        margin-right: 10px;
      }`
}   
`;
