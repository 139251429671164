import styled from 'styled-components';
import { colors } from 'common/colors';

export const CourseEntryWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 85px 30px 120px;
  
  .step-header {
    margin-bottom: 5px;
  }
  
  .course-name-header {
    margin-bottom: 20px;
  }
  
  .content-heading {
    margin: 20px 0 10px;
  }
`;

export const InfoCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  
  .next-info {
    margin-top: 10px;
  }
`;

export const InfoCardWrap = styled.div`
  margin-top: 30px
`;

export const LabelWrap = styled.div`
  color: ${colors.primary_gray4};
  margin-bottom: 15px;
`;
