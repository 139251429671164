import styled from 'styled-components';
import {
  Box,
  Tab,
  Tabs, Typography,
  withStyles
} from '@material-ui/core';
import { colors } from 'common/colors';

export const AppTabsWrap = styled.div`
  width: 100%;
`;

export const TabsStyled = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 70,
      backgroundColor: colors.primary_indigo,
      height: '2px',
      width: '100%'
    }
  },
  root: {
    minWidth: 'initial'
  },
  flexContainer: {
    justifyContent: 'space-between'
  }
})(Tabs);

export const TabStyled = withStyles({
  root: {
    padding: '0 5px',
    color: colors.primary_gray2,
    textTransform: 'none',
    fontFamily: 'AppMulish',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    minWidth: 'initial',
    overflow: 'inherit'
  }
})(Tab);

export const BoxStyled = withStyles({
  root: {
    padding: 0
  }
})(Box);

export const TypographyStyled = withStyles({
  root: {
    fontFamily: 'AppMulish'
  }
})(Typography) as typeof Typography;
