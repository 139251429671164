import { FC } from 'react';
import FormInput from 'common/formInput/FormInput';
import Tooltip from 'stepComponents/tooltip/Tooltip';
import { useCvBuilder } from 'utils/CvBuilderContext';
import { StepWrap, StepHeader } from '../styles';

export type BasicsSectionType = {
  fullName: string;
  idNumber: string;
  city: string;
  email: string;
  phoneNumber: string;
};

const Basics: FC = () => {
  const { values: { basics }, handleChange, isValidRSAID } = useCvBuilder();

  return (
    <StepWrap>
      <StepHeader>
        The Basics
      </StepHeader>
      <FormInput
        variant="outlined"
        fullWidth
        name="fullName"
        label="My name is:"
        value={basics.fullName}
        onChange={handleChange('basics')}
      />
      <Tooltip
        closeButton
        backgroundColor="primary_aquamarine"
        title="Tip"
        content="Make sure you use your full name and not a nickname"
      />
      <FormInput
        variant="outlined"
        fullWidth
        name="idNumber"
        label="My ID number is:"
        value={basics.idNumber}
        onChange={handleChange('basics')}
      />
      <FormInput
        variant="outlined"
        fullWidth
        name="city"
        label="I currently live in: [town/city]"
        value={basics.city}
        onChange={handleChange('basics')}
      />
      <FormInput
        variant="outlined"
        fullWidth
        name="email"
        label="You can reach me by email at:"
        value={basics.email}
        onChange={handleChange('basics')}
      />
      <FormInput
        variant="outlined"
        fullWidth
        name="phoneNumber"
        label="You can reach me by phone at:"
        value={basics.phoneNumber}
        onChange={handleChange('basics')}
      />
      {!isValidRSAID && (
        <Tooltip
          backgroundColor="secondary_crimson"
          title="Error"
          content="ID number must be valid"
        />
      )}
    </StepWrap>
  );
};

export default Basics;
