import styled from 'styled-components';
import { colors } from 'common/colors';

export const BadgesWrap = styled.div<{ isLoading: boolean }>`
  padding: ${({ isLoading }) => !isLoading && '25px 30px'};
  .heading {
    margin-bottom: 40px;
  }
  .rounded {
    background-color: ${colors.primary_white};
    border-radius: 100px;
    padding: 9px 15px;
    display: inline-block;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
    margin: 0 10px;
  }
  .error {
    margin-bottom: 12px;
    color: ${colors.primary_red};
  }
`;
