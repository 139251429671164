import styled from 'styled-components';
import { colors } from 'common/colors';

export const SaveMaterialWrap = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  span {
    margin-left: 10px;
    color: ${colors.primary_indigo};
  }
`;
