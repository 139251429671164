import styled from 'styled-components';

export const CvBuilderWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px 30px;
`;

export const Header = styled.h3`
  margin-bottom: 16px;
`;
