import { lazy } from 'react';
import { ComponentsPropsTypes } from 'stepComponents/ComponentsPropsTypes';
import { ComponentsTypesEnum } from 'stepComponents/enums/ComponentsTypesEnum';
import { DescriptionPropsType } from 'stepComponents/description/Description';
import { CardNumberedPropsType } from 'stepComponents/cardNumbered/CardNumbered';
import { InputPropsType } from 'stepComponents/input/Input';
import { StyledListPropsType } from 'stepComponents/styledList/StyledList';
import { TooltipPropTypes } from 'stepComponents/tooltip/Tooltip';
import { RangeSliderPropsType } from 'stepComponents/rangeSlider/RangeSlider';
import { ImagePropsType } from 'stepComponents/image/Image';
import { CardsListPropsType } from 'stepComponents/cardsList/CardsList';
import { AnnounceCardPropsType } from 'stepComponents/announceCard/AnnounceCard';
import { CheckboxPropsType } from 'stepComponents/checkbox/Checkbox';
import { RadioPropsType } from 'stepComponents/radio/Radio';
import { VideoPropsType } from 'stepComponents/video/Video';
import { RangeNumberedPropsType } from 'stepComponents/rangeNumbered/RangeNumbered';
import { DragNDropPropsType } from 'stepComponents/dnd/DragNDrop';
import { CheckboxListPropsType } from 'stepComponents/checkboxList/CheckboxList';
import { AppTabsStepVariantPropsType } from 'stepComponents/appTabs/AppTabs';
import { RadioListPropsType } from 'stepComponents/radioList/RadioList';
import { CardQuizPropsType } from 'stepComponents/cardQuiz/CardQuiz';
import { DropdownListPropsType } from 'stepComponents/dropdownList/DropdownList';
import { DndLettersPropsType } from 'stepComponents/dndLetters/DndLetters';
import { DndOrderPropsType } from 'stepComponents/dndOrder/DndOrder';
import { CvReviewerPropsType } from 'stepComponents/cvReviewer/CvReviewer';
import StepBuilder from './StepBuilder';

const Description = lazy(() =>
  import('stepComponents/description/Description'));
const CardNumbered = lazy(() =>
  import('stepComponents/cardNumbered/CardNumbered'));
const Input = lazy(() => import('stepComponents/input/Input'));
const StyledList = lazy(() => import('stepComponents/styledList/StyledList'));
const Tooltip = lazy(() => import('stepComponents/tooltip/Tooltip'));
const RangeSlider = lazy(() => import('stepComponents/rangeSlider/RangeSlider'));
const Image = lazy(() => import('stepComponents/image/Image'));
const CardsList = lazy(() => import('stepComponents/cardsList/CardsList'));
const AnnounceCard = lazy(() => import('stepComponents/announceCard/AnnounceCard'));
const Checkbox = lazy(() => import('stepComponents/checkbox/Checkbox'));
const Radio = lazy(() => import('stepComponents/radio/Radio'));
const Video = lazy(() => import('stepComponents/video/Video'));
const RangeNumbered = lazy(() => import('stepComponents/rangeNumbered/RangeNumbered'));
const DragNDrop = lazy(() => import('stepComponents/dnd/DragNDrop'));
const CheckboxList = lazy(() => import('stepComponents/checkboxList/CheckboxList'));
const AppTabs = lazy(() => import('stepComponents/appTabs/AppTabs'));
const RadioList = lazy(() => import('stepComponents/radioList/RadioList'));
const CardQuiz = lazy(() => import('stepComponents/cardQuiz/CardQuiz'));
const DropdownList = lazy(() => import('stepComponents/dropdownList/DropdownList'));
const DndLetters = lazy(() => import('stepComponents/dndLetters/DndLetters'));
const DndOrder = lazy(() => import('stepComponents/dndOrder/DndOrder'));
const CvReviewer = lazy(() => import ('stepComponents/cvReviewer/CvReviewer'));

export function chooseComponent(componentsSet: {
  __typename: string,
  props: ComponentsPropsTypes
}, index: number): JSX.Element {
  const createStepTabs = (): JSX.Element => {
    const { tabs } = { ...componentsSet.props as AppTabsStepVariantPropsType };
    const createdTabs = tabs.map((tab, index: number) => ({
      name: tab.name,
      content: <StepBuilder
        step={{
          id: `${tab.name}-${index}`,
          stepComponents: tab.content,
          stepType: '',
          name: `${tab.name}-${index}`
        }}
        isTab={true}
      />
    }));
    return <AppTabs tabs={createdTabs} key={`createdTabs-${index}`} />;
  };

  switch (componentsSet.__typename) {
    case ComponentsTypesEnum.description:
      return <Description {...componentsSet.props as DescriptionPropsType} key={index} />;
    case ComponentsTypesEnum.cardNumbered:
      return <CardNumbered {...componentsSet.props as CardNumberedPropsType} key={index} />;
    case ComponentsTypesEnum.input:
      return <Input {...componentsSet.props as InputPropsType} key={index} />;
    case ComponentsTypesEnum.styledList:
      return <StyledList {...componentsSet.props as StyledListPropsType} key={index} />;
    case ComponentsTypesEnum.tooltip:
      return <Tooltip {...componentsSet.props as TooltipPropTypes} key={index} />;
    case ComponentsTypesEnum.rangeSlider:
      return <RangeSlider {...componentsSet.props as RangeSliderPropsType} key={index} />;
    case ComponentsTypesEnum.image:
      return <Image {...componentsSet.props as ImagePropsType} key={index} />;
    case ComponentsTypesEnum.cardsList:
      return <CardsList {...componentsSet.props as CardsListPropsType} key={index} />;
    case ComponentsTypesEnum.announceCard:
      return <AnnounceCard {...componentsSet.props as AnnounceCardPropsType} key={index} />;
    case ComponentsTypesEnum.checkbox:
      return <Checkbox {...componentsSet.props as CheckboxPropsType} key={index} />;
    case ComponentsTypesEnum.radio:
      return <Radio {...componentsSet.props as RadioPropsType} key={index} />;
    case ComponentsTypesEnum.video:
      return <Video {...componentsSet.props as VideoPropsType} key={index} />;
    case ComponentsTypesEnum.rangeNumbered:
      return <RangeNumbered {...componentsSet.props as RangeNumberedPropsType} key={index} />;
    case ComponentsTypesEnum.dnd:
      return <DragNDrop {...componentsSet.props as DragNDropPropsType} key={index} />;
    case ComponentsTypesEnum.checkboxList:
      return <CheckboxList {...componentsSet.props as CheckboxListPropsType} key={index} />;
    case ComponentsTypesEnum.tabs:
      return createStepTabs();
    case ComponentsTypesEnum.radioList:
      return <RadioList {...componentsSet.props as RadioListPropsType} key={index} />;
    case ComponentsTypesEnum.cardQuiz:
      return <CardQuiz {...componentsSet.props as CardQuizPropsType} key={index} />;
    case ComponentsTypesEnum.dropdownList:
      return <DropdownList {...componentsSet.props as DropdownListPropsType} key={index} />;
    case ComponentsTypesEnum.dndLetters:
      return <DndLetters {...componentsSet.props as DndLettersPropsType} key={index} />;
    case ComponentsTypesEnum.dndOrder:
      return <DndOrder {...componentsSet.props as DndOrderPropsType} key={index} />;
    case ComponentsTypesEnum.cvReviewer:
      return <CvReviewer {...componentsSet.props as CvReviewerPropsType} key={index} />;
    default:
      return (
        <Tooltip
          backgroundColor="secondary_cyan"
          icon="what_happened_cyclops"
          iconPosition="topLeft"
          content={`Missing ${componentsSet.__typename} component`}
          key={`${index}-default`}
        />
      );
  }
}
