import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  SensorDescriptor,
  SensorOptions,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { useState } from 'react';

export type ItemType = {
  id?: string;
  title?: string;
  description?: string;
  letter?: string;
  initialValue?: string;
  currentValue?: string | null;
};

type ItemsType = {
  [key: string]: ItemType[] | ItemType;
};

type UseDndLettersType = (cardItems: ItemType[]) => {
  items: ItemsType;
  sensors: SensorDescriptor<SensorOptions>[]
  handleDragStart: (event: DragStartEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
};

const createInitialState = (items: ItemType[]) => items.reduce(
  (acc: ItemsType, currentItem, idx) => {
    const currentRootItem = {
      id: `letterBox${idx + 1}`,
      initialValue: currentItem.letter,
      currentValue: currentItem.letter
    };
    const currentContainerName = `container${idx + 1}`;
    return ({
      ...acc,
      root: [...acc.root as ItemType[], currentRootItem],
      [currentContainerName]: { ...currentItem, id: currentContainerName, currentValue: null }
    });
  }, { root: [] }
);

const useDndLetters: UseDndLettersType = (cardItems) => {
  const [, setActiveId] = useState<string | null>();
  const [items, setItems] = useState<ItemsType>(createInitialState(cardItems));

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor),
    useSensor(TouchSensor)
  );

  const findContainer = (id: string) => {
    if (id in items) return id;

    return Object.keys(items)
      .find(key => {
        const itemProperty = items[key as keyof ItemsType];
        if (Array.isArray(itemProperty)) {
          return itemProperty.find(el => el.id);
        }
        return itemProperty?.id === id;
      });
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { id } = event.active;
    setActiveId(id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const { id, data } = active;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(over?.id as string);

    if (
      !activeContainer
      || !overContainer
      || activeContainer === overContainer
    ) {
      return;
    }

    setItems(prev => {
      const activeItems = prev[activeContainer as keyof ItemsType];
      const overItems = prev[overContainer as keyof ItemsType];
      const overItemCurrentValue = (overItems as ItemType).currentValue;

      if (
        activeContainer === 'root'
        && data.current
      ) {
        const rootIndex = (activeItems as ItemType[]).findIndex(item => item.id === id);
        const valueToUpdate = data.current.value;
        const rootArrayCopy = [...(prev[activeContainer] as ItemType[])];
        rootArrayCopy[rootIndex].currentValue = overItemCurrentValue || null;
        return {
          ...prev,
          [activeContainer]: rootArrayCopy,
          [overContainer]: {
            ...prev[overContainer],
            currentValue: valueToUpdate
          }
        };
      }

      if (
        activeContainer !== 'root'
        && overContainer !== 'root'
        && data.current
      ) {
        return {
          ...prev,
          [activeContainer]: {
            ...prev[activeContainer],
            currentValue: overItemCurrentValue || null
          },
          [overContainer]: {
            ...prev[overContainer],
            currentValue: data.current.value
          }
        };
      }
      return prev;
    });
    setActiveId(null);
  };

  return {
    items,
    sensors,
    handleDragStart,
    handleDragEnd
  };
};

export default useDndLetters;
