import {
  FC, SyntheticEvent, useEffect, useState
} from 'react';
import { useAuth } from 'utils/AuthContext';
import userApi from 'utils/userApi';
import { ReactComponent as HeroesIcon } from 'assets/img/heroes.svg';
import { useHistory } from 'react-router-dom';
import parseUserData from 'utils/parseUserData';
import Button from 'common/button/Button';
import FormInput from 'common/formInput/FormInput';
import DotsLoader from 'common/dotsLoader/DotsLoader';
import useForm from 'utils/useForm';
import { DAYS, dayTimestamp } from 'utils/AuthConstants';
import {
  Wrapper, Header, StyledLink, ErrorWrap
} from 'common/authStyles/AuthStyles';

const LoginPage: FC = () => {
  const {
    setUser,
    isAuthenticated,
    setIsAuthenticated,
    requestError,
    setRequestError
  } = useAuth();
  const {
    values,
    handleInputChange
  } = useForm({
    email: '',
    password: ''
  });
  const [isLogInInProgress, setIsLogInInProgress] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [history, isAuthenticated]);

  useEffect(() => () => setIsLogInInProgress(false), []);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setIsLogInInProgress(true);
      setRequestError('');
      const { email, password } = values;
      const normalizedEmail = email.toLowerCase();
      const data = await userApi.loginUser({ email: normalizedEmail, password });
      if (data) {
        const now = new Date();
        const auth = {
          jwt: data.jwt,
          expiry: now.getTime() + DAYS * dayTimestamp
        };
        localStorage.setItem('Authorization', JSON.stringify(auth));
        localStorage.setItem('userProgress', JSON.stringify(data.user.progressJson ?? {}));
        const parsed = parseUserData(data.user);
        setUser(parsed);
        setIsAuthenticated(true);
      }
    } catch (err) {
      setRequestError(err.message);
    } finally {
      setIsLogInInProgress(false);
    }
  };

  return (
    <Wrapper>
      <HeroesIcon />
      <Header isRequestError={!!requestError}>Log in</Header>
      {requestError ? <ErrorWrap>{requestError}</ErrorWrap> : null}
      <form onSubmit={handleSubmit}>
        <FormInput
          type="email"
          fullWidth
          variant="outlined"
          name="email"
          label="Email"
          placeholder="Type your name"
          onChange={handleInputChange}
          value={values.email}
        />
        <FormInput
          fullWidth
          variant="outlined"
          name="password"
          label="Password"
          placeholder="Type your password"
          type="password"
          onChange={handleInputChange}
          value={values.password}
        />
        <StyledLink to="/forgot-password">Forgot password?</StyledLink>
        <Button
          type="submit"
          variant="contained"
          fullWidth
        >
          {isLogInInProgress ? <DotsLoader /> : 'Log in'}
        </Button>
        <StyledLink to="/register">Sign up</StyledLink>
      </form>
    </Wrapper>
  );
};

export default LoginPage;
