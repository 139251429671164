import { UserDto } from 'utils/userApi';
import { UserType } from 'utils/AuthContext';
import parseBadgesEarned from 'utils/parseBadgesEarned';

export default function parseUserData(user: UserDto): UserType {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    rsaId: user.rsaId,
    phone: user.phone,
    email: user.email,
    role: user.role.name,
    badgesEarned: parseBadgesEarned(user.badgesEarned),
    certificates: user.certificates,
    cv: user.cv
  };
}
