import { FC, useState } from 'react';
import Button from 'common/button/Button';
import { useCvBuilder } from 'utils/CvBuilderContext';
import { useHistory } from 'react-router-dom';
import DotsLoader from 'common/dotsLoader/DotsLoader';
import {
  Greeting,
  Basics,
  Education,
  WorkExperiencePreview,
  WorkExperience,
  VolunteerExperience,
  FinishingCv,
  TechnicalSkills,
  PersonalSkills,
  SummaryStatement,
  GetCv
} from './steps/steps';
import { CvBuilderWrap, Header } from './CvBuilderStyles';

enum CvBuilderStep {
  GREETING,
  BASICS,
  EDUCATION,
  WORK_EXPERIENCE_PREVIEW,
  WORK_EXPERIENCE,
  VOLUNTEER_EXPERIENCE,
  FINISHING_CV,
  TECHNICAL_SKILLS,
  PERSONAL_SKILLS,
  SUMMARY_STATEMENT,
  GET_CV
}

const componentMap = {
  [CvBuilderStep.GREETING]: Greeting,
  [CvBuilderStep.BASICS]: Basics,
  [CvBuilderStep.EDUCATION]: Education,
  [CvBuilderStep.WORK_EXPERIENCE_PREVIEW]: WorkExperiencePreview,
  [CvBuilderStep.WORK_EXPERIENCE]: WorkExperience,
  [CvBuilderStep.VOLUNTEER_EXPERIENCE]: VolunteerExperience,
  [CvBuilderStep.FINISHING_CV]: FinishingCv,
  [CvBuilderStep.TECHNICAL_SKILLS]: TechnicalSkills,
  [CvBuilderStep.PERSONAL_SKILLS]: PersonalSkills,
  [CvBuilderStep.SUMMARY_STATEMENT]: SummaryStatement,
  [CvBuilderStep.GET_CV]: GetCv
};

const renderStep = (step: CvBuilderStep) => {
  const Component = componentMap[step];
  return Component ? <Component /> : null;
};

const isUpdateRequired = (step: CvBuilderStep) => (
  step === CvBuilderStep.BASICS
  || step === CvBuilderStep.EDUCATION
  || step === CvBuilderStep.WORK_EXPERIENCE
  || step === CvBuilderStep.VOLUNTEER_EXPERIENCE
  || step === CvBuilderStep.TECHNICAL_SKILLS
  || step === CvBuilderStep.PERSONAL_SKILLS
  || step === CvBuilderStep.SUMMARY_STATEMENT
);

const isFirstOrLastStep = (step: CvBuilderStep) => (
  step === CvBuilderStep.GREETING
  || step === CvBuilderStep.GET_CV
);

const CvBuilder: FC = () => {
  const { handleUpdateCv, isUpdatingCvInProgress, isValidRSAID } = useCvBuilder();
  const [currentStep, setCurrentStep] = useState<CvBuilderStep>(CvBuilderStep.GREETING);
  const history = useHistory();

  const handleBack = () => {
    if (isFirstOrLastStep(currentStep)) {
      return history.push('/');
    }
    return setCurrentStep(currentStep => currentStep - 1);
  };

  const handleNext = async () => {
    if (isUpdateRequired(currentStep)) {
      await handleUpdateCv();
    }
    setCurrentStep(currentStep => currentStep + 1);
  };

  return (
    <CvBuilderWrap>
      <Header>CV Builder</Header>
      {renderStep(currentStep)}
      {currentStep !== CvBuilderStep.GET_CV && (
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={(currentStep === CvBuilderStep.BASICS && !isValidRSAID) || isUpdatingCvInProgress}
        >
          {isUpdatingCvInProgress ? <DotsLoader /> : 'Next'}
        </Button>
      )}
      <Button
        onClick={handleBack}
      >
        {isFirstOrLastStep(currentStep) ? 'Get back to Pathways' : 'Back'}
      </Button>
    </CvBuilderWrap>
  );
};

export default CvBuilder;
