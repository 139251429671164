import { FC } from 'react';
import ProgressBar from 'common/progressBar/ProgressBar';
import { Tooltip, AppTabs } from 'stepComponents';
import DoneElementsDisplay from 'pages/courseControler/components/doneElementsDisplay/DoneElementsDisplay';
import WithNavigation from 'pages/courseControler/components/navigation/WithNavigation';
import { usePathway, usePathwayActions } from 'utils/PathwayContext';
import Header from 'pages/header/Header';
import {
  getUserStatistics
} from 'pages/courseControler/components/userProgress/userProgressHelper';
import useScrollToTop from 'utils/useScrollToTop';
import {
  PathwayWrap,
  ScoresWrap,
  PathwayLabelWrap
} from './PathwayStyles';
import AboutTab from './aboutTab/AboutTab';
import CoursesTab from './coursesTab/CoursesTab';
import SavedTab from './savedTab/SavedTab';
import CertificateTab from './certificateTab/CertificateTab';

const Pathway: FC = () => {
  const [{
    name,
    content,
    courses,
    quote,
    certificate,
    image
  }] = usePathway();
  const { allStepsInPathwayFlat, badgesInPathway } = usePathwayActions();
  const {
    badgesEarned,
    completedCourses,
    stepsFinishedInPathway,
    completedTopics,
    completedKnowledgeBites,
  } = getUserStatistics();
  const userFinishedPathway = () => Math.floor((stepsFinishedInPathway.length / allStepsInPathwayFlat().length) * 100);
  const ref = useScrollToTop();
  const sortedCourses = [...courses].sort((a, b) => a.orderNumber - b.orderNumber);
  return (
    <WithNavigation level="pathway">
      <PathwayWrap ref={ref}>
        <PathwayLabelWrap className="labelMobile12Bold">
          Pathways / {name}
        </PathwayLabelWrap>

        <img src={`${image.url}`} alt="pathway_img" />

        <div className="header h4">{name}</div>

        <ScoresWrap>
          <DoneElementsDisplay label="Courses" earned={completedCourses.length} max={courses.length} />
          <DoneElementsDisplay label="Badges" earned={badgesEarned} max={badgesInPathway()} />
        </ScoresWrap>

        <ProgressBar label="PROGRESS" progress={userFinishedPathway()} />

        <Tooltip
          content="Click on tabs to explore recent pathway materials."
          icon="cyclops_with_card"
          iconPosition="topLeft"
          backgroundColor="primary_gray_light5"
          closeButton={true}
        />

        <AppTabs tabs={[
          {
            name: 'About',
            content: <AboutTab content={[content]} quote={quote} />
          },
          {
            name: 'Courses',
            content: <CoursesTab
              certificate={certificate}
              courses={sortedCourses}
              userStatistics={{
              completedCourses,
              completedTopics,
              completedKnowledgeBites,
              stepsFinishedInPathway
            }}
            />
          },
          {
            name: 'Certificate',
            hidden: !certificate,
            content: <CertificateTab
              name={certificate?.name}
              description={certificate?.content}
            />
          },
          {
            name: 'Saved',
            content: <SavedTab />
          }
        ]}
        />
        <Header />
      </PathwayWrap>
    </WithNavigation>
  );
};

export default Pathway;
