import { FC } from 'react';
import Description from 'stepComponents/description/Description';
import { StepWrap, StepHeader } from '../styles';

const FinishingCv: FC = () => (
  <StepWrap>
    <StepHeader isDescription>
      Finishing your CV
    </StepHeader>
    <Description
      className="body16Regular"
      content="Now that you know what hard and soft skills you have, we will add these to your CV"
    />
  </StepWrap>
);

export default FinishingCv;
