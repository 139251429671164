import styled from 'styled-components';
import { ReactComponent as BadgeIcon } from 'assets/img/badge.svg';
import { ReactComponent as CertificateWIthBadge } from 'assets/img/certificateWithBadge.svg';
import { colors } from 'common/colors';

export const BadgeIconWrap = styled(BadgeIcon)<{
  color: string;
  leftribboncolor?: string;
  rightribboncolor?: string;
  ringcolor?: string;
  starcolor?: string;
}>`
   .left-ribbon {
     fill: ${props => props.leftribboncolor ?? props.color};
   }
  
  .right-ribbon {
    fill: ${props => props.rightribboncolor ?? props.color};
  }

  .ring-with-star {
    fill: ${props => props.ringcolor ?? props.color};
  }
  
  .star {
    fill: ${props => props.starcolor ?? colors.primary_gray_light3};
  }
`;

export const CertificateIconWrap = styled(CertificateWIthBadge)<{
  color: string;
}>`
  .left-ribbon, .right-ribbon, .ring-with-star {
    fill: ${props => props.color}
  }
  .star {
    fill: ${colors.primary_gray_light3};
  }
 
`;
