import React from 'react';
import allBadges from 'pages/badges/all_badges.json';
import { BadgesLevelsEnum, BadgesTypesEnum } from 'pages/badges/BadgesEnum';
import { colors } from 'common/colors';
import { BadgeIconWrap, CertificateIconWrap } from './BadgeIconStyles';

type PropsType = {
  badgeType?: BadgesTypesEnum;
  badgeLevel?: BadgesLevelsEnum;
  color?: string;
  leftRibbon?: string;
  rightRibbon?: string;
  ring?: string;
  star?: string;
  type?: 'badge' | 'certificate';
};

function BadgeIcon({
  badgeType, badgeLevel, color, leftRibbon, rightRibbon, ring, star, type
}: PropsType): JSX.Element {
  function setColor(): string {
   return allBadges
      .filter(badge => badge.type === badgeType && badge.level === badgeLevel)
      .map(filteredBadge => filteredBadge.color)
      .shift() || colors.badge_of_readiness_beginner;
  }

  return (
    (type === 'certificate')
      ? (
        <CertificateIconWrap
          color={color ?? setColor()}
        />
)
      : (
        <BadgeIconWrap
          color={color ?? setColor()}
          leftribboncolor={leftRibbon}
          rightribboncolor={rightRibbon}
          ringcolor={ring}
          starcolor={star}
        />
)
  );
}

export default BadgeIcon;
