import styled from 'styled-components';
import { colors } from 'common/colors';

export const CardWrap = styled.div<{
  selected: boolean
}>`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  padding: 12px 15px;
  background-color: ${({ selected }) => (selected ? colors.primary_indigo_shade3 : 'inherit')};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? colors.primary_indigo : colors.primary_gray_light2)};
  font-size: 16px;
  line-height: 24px;
  ${({ selected }) => selected && 'box-shadow: 0px 0px 5px rgba(54, 52, 163, 0.5)'};
`;

export const LetterWrap = styled.span`
  margin-right: 10px;
  font-weight: 700;
`;

export const AnswerWrap = styled.span`
  word-break: break-word;
`;
