export enum ComponentsTypesEnum {
  'description' = 'ComponentStepDescription',
  'cardNumbered' = 'ComponentStepCardNumbered',
  'tooltip' = 'ComponentStepTooltip',
  'cardCloseButton' = 'cardCloseButton',
  'appButton' = 'appButton',
  'circleWithChar' = 'circleWithChar',
  'input' = 'ComponentStepInput',
  'styledList' = 'ComponentStepStyledList',
  'rangeSlider' = 'ComponentStepRangeSlider',
  'image' = 'ComponentStepImage',
  'cardsList' = 'ComponentStepCardsList',
  'announceCard' = 'ComponentStepAnnounceCard',
  'checkbox' = 'checkbox',
  'radio' = 'radio',
  'video' = 'ComponentStepVideo',
  'rangeNumbered' = 'ComponentStepRangeNumbered',
  'dnd' = 'ComponentStepDragAndDrop',
  'checkboxList' = 'ComponentStepCheckboxList',
  'tabs' = 'ComponentStepTabs',
  'radioList' = 'ComponentStepRadioList',
  'cardQuiz' = 'ComponentStepCardQuiz',
  'dropdownList' = 'ComponentStepDropdownList',
  'dndLetters' = 'ComponentStepDndLetters',
  'dndOrder' = 'ComponentStepDndOrder',
  'cvReviewer' = 'ComponentStepCvReviewer'
}
