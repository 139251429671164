import styled from 'styled-components';
import { colors } from 'common/colors';

export const CompletedTopicWrap = styled.div`
  padding: 85px 30px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const BadgeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  padding: 30px 0;
  border-radius: 20px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  
  .badge-info {
    color: ${colors.primary_gray1};
    text-align: center;
    margin-bottom: 30px;
  }
  
  .badge-name {
    margin-top: 30px;
    color: ${colors.primary_green};
  }
`;
