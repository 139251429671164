import styled from 'styled-components';
import { colors } from 'common/colors';

export const ListWrap = styled.div`
  display: flex;
  max-width: 48%;
  flex-grow: 1;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid ${colors.primary_gray5};
  background-color: ${colors.primary_white};
`;

export const TitleWrap = styled.div`
  margin-bottom: 10px;
`;
