import styled from 'styled-components';
import { colors } from 'common/colors';

export const CardWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
  background-color: ${colors.primary_white};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardImg = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  
  & img, svg {
    max-width: 50px;
    max-height: 50px;
  }
`;

export const ImgPlaceholder = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: ${colors.primary_gray_light1};
`;

export const Title = styled.div`
  padding-left: 20px;
`;
