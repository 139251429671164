import styled from 'styled-components';
import { Button as ButtonMUI, withStyles } from '@material-ui/core';
import { colors } from 'common/colors';

export const StepWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

export const StepHeader = styled.h4<{
  isDescription?: boolean;
}>`
  align-self: flex-start;
  ${({ isDescription }) => (!isDescription && 'margin-bottom: 24px;')}
`;

export const SectionWrap = styled.div`
  width: 100%;
  margin-bottom: 24px;
  &:not(:last-of-type) {
    padding-bottom: 12px;
    border-bottom: 1px solid ${colors.primary_gray_light1};
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const RemoveSectionButton = styled.button`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #FFCECE;
  border: none;
  border-radius: 50%;
  svg path {
    stroke: ${colors.primary_red};
  }
`;

export const AddSectionButton = styled.button`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: 1px dashed ${colors.primary_gray5};
  font-family: 'AppMulish', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 10px;
  padding: 24px 0;
`;

export const AddSectionIconWrap = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary_aquamarine};
  margin-bottom: 8px;
  svg {
    height: 40px;
    width: 40px;
    path {
      stroke: ${colors.secondary_green};
    }
  }
`;

export const ItemWrap = styled.div`
  width: 100%;
`;

export const RemoveItemButton = withStyles({
  root: {
    fontFamily: 'AppMulish',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '10px',
    textTransform: 'none',
    marginBottom: '3px',
    padding: '3px 6px',
    float: 'right'
  },
  text: {
    color: colors.primary_red,
    '&:hover': {
      boxShadow: '0px 4px 9px rgba(54, 52, 163, 0.3)',
      backgroundColor: 'inherit'
    }
  }
})(ButtonMUI);

export const AddItemButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-family: 'AppMulish', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
`;
