import styled from 'styled-components';
import { colors } from 'common/colors';

export const FooterMenuWrap = styled.div`
  box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.1);
  background: ${colors.primary_white};
  padding: 24px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  z-index: 100;
  bottom: 0;
`;
