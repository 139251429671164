const localStorageExpirationValidator = (token: string | null): string | null => {
  let jwtToken = null;
  if (token) {
    // const now = new Date();
    const { jwt } = JSON.parse(token);
    // if (now.getTime() > expiry) {
    //   localStorage.removeItem('Authorization');
    // } else {
    jwtToken = jwt;
    // }
  }
  return jwtToken;
};

export default localStorageExpirationValidator;
