import styled from 'styled-components';
import { colors } from '../colors';

export const Error = styled.h3`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: ${colors.primary_red};
`;
