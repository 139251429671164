import React from 'react';
import { ReactComponent as CertificatesIcon } from 'assets/img/footermenu-icons/certificates-icon.svg';
import { MenuButtonWrapAsRouterLink } from './MenuButtonStyles';

function MenuButtonCertificates(): JSX.Element {
  return (
    <MenuButtonWrapAsRouterLink to="/certificates">
      <CertificatesIcon />
      <span className="label12Bold">Certificates</span>
    </MenuButtonWrapAsRouterLink>
  );
}

export default MenuButtonCertificates;
