import { FC } from 'react';
import WhatsNext from 'stepComponents/whatsNext/WhatsNext';
import { BadgesLevelsEnum, BadgesTypesEnum } from 'pages/badges/BadgesEnum';
import WithNavigation from 'pages/courseControler/components/navigation/WithNavigation';
import AppSpinner from 'common/appSpinner/AppSpinner';
import useAddBadge from 'utils/useAddBadge';
import BadgeWithStarsIcon from 'common/badgeWithStarsIcon/BadgeWithStarsIcon';
import { CompletedTopicWrap, BadgeCard } from './CompletedTopicStyles';

export type CompletedTopicPropsType = {
  topicName: string;
  badgeEarnedType: BadgesTypesEnum;
  badgeEarnedLevel: BadgesLevelsEnum;
  text: string;
};

const CompletedTopic: FC = () => {
  const lSData = localStorage.getItem('currentTreadData');
  const {
    id: topicId,
    currentTopicOrder,
    badges,
    courseName,
    mindfulnessBreak
  } = JSON.parse(lSData || '{name: \'fallback Topic\'}');
  const { isAddingBadgeInProgress } = useAddBadge({ topicId });

  return (
    <WithNavigation level="completedTopic">
      <CompletedTopicWrap>
        { isAddingBadgeInProgress ? <AppSpinner /> : (
          <>
            <h3>Awesome!</h3>
            <h4>You&apos;ve completed Topic {currentTopicOrder + 1}!</h4>
            <BadgeCard>
              <h4 className="badge-info">You have earned a<br />Badge of {badges.type}</h4>
              <BadgeWithStarsIcon badgeType={badges.type} badgeLevel={badges.level} />
              <h4 className="badge-name" color={badges.color}>{courseName} {badges.level}</h4>
            </BadgeCard>
            <WhatsNext variant="completedTopic" parentId={topicId} mindfulnessBreak={mindfulnessBreak} />
          </>
        )}
      </CompletedTopicWrap>
    </WithNavigation>
  );
};

export default CompletedTopic;
