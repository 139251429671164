import { FC } from 'react';
import { BadgesLevelsEnum, BadgesTypesEnum } from 'pages/badges/BadgesEnum';
import allBadges from 'pages/badges/all_badges.json';
import { colors } from 'common/colors';
import { BadgeIconWithStarsWrap } from './BadgeWithStarsIconStyles';

type BadgeWithStarsIconPropsType = {
  badgeType?: BadgesTypesEnum;
  badgeLevel?: BadgesLevelsEnum;
  color?: string;
};
const BadgeWithStarsIcon: FC<BadgeWithStarsIconPropsType> = ({
  badgeType,
  badgeLevel,
  color
                                                             }) => {
  function setColor(): string {
    return allBadges
      .filter(badge => badge.type === badgeType && badge.level === badgeLevel)
      .map(filteredBadge => filteredBadge.color)
      .shift() || colors.badge_of_readiness_beginner;
  }
  return (
    <BadgeIconWithStarsWrap color={color || setColor()} />
);
};

export default BadgeWithStarsIcon;
