import React from 'react';
import { AppSpinnerWrap, AppSpinnerSvgWrap } from './AppSpinnerStyles';

function AppSpinner(): JSX.Element {
  return (
    <AppSpinnerWrap>
      <AppSpinnerSvgWrap viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="35" />
      </AppSpinnerSvgWrap>
    </AppSpinnerWrap>
  );
}

export default AppSpinner;
