import { Topic } from './Topic';
import { BadgeInterface, MindfulnessBreakInterface } from './pathway.interface';

export class Course {
  name: string;

  id: string;

  topics: Topic[];

  isLocked: boolean;

  content: string;

  badges: BadgeInterface;

  mindfulness_break: MindfulnessBreakInterface;

  orderNumber: number;

  constructor(
    name: string,
    id?: string,
    topics?: Topic[],
    content?: string,
    badge?: BadgeInterface,
    mindfulnessBreak?: MindfulnessBreakInterface,
    orderNumber?: number
  ) {
    this.name = name;
    this.content = content || '';
    this.id = id || '';
    this.topics = topics || [new Topic(this.name)];
    this.isLocked = false;
    this.badges = badge || { type: 'Growth', level: 'Intermediate', color: '' } as BadgeInterface;
    this.mindfulness_break = mindfulnessBreak || { description: undefined, videoUrl: undefined };
    this.orderNumber = orderNumber || 1;
  }
}
