type CertificateDto = {
  id: string;
  name: string;
  path: {
    name: string
  } | null;
};

export type ParsedCertificatesQuery = {
  id: string;
  name: string;
  pathName: string | undefined;
  isAcquired?: boolean;
}[];

export default function parseCertificatesQuery(certificatesDto: CertificateDto[]): ParsedCertificatesQuery {
  return certificatesDto.map(certificate => ({
    id: certificate.id,
    name: certificate.name,
    pathName: certificate.path?.name
  }));
}
