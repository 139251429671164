import styled from 'styled-components';

export const CompletedKnowledgeBiteWrap = styled.div`
  padding: 85px 30px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  .header {
    margin-top: 40px;
  }
  
  .kb-number {
    margin: 10px 0;
  }
`;
