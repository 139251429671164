import { FunctionComponent } from 'react';
import Tooltip, { TooltipColorType } from 'stepComponents/tooltip/Tooltip';
import Description from 'stepComponents/description/Description';
import { LabelsContainer, Label, StyledSlider } from 'stepComponents/rangeSlider/RangeSliderStyles';
import useRangeSlider from 'stepComponents/rangeSlider/useRangeSlider';
import { handleUserProgressDataChange } from 'pages/courseControler/components/userProgress/userProgressHelper';

export type RangeContent = {
  minValue: number;
  maxValue: number;
  tooltipColor: TooltipColorType;
  content: string;
}[];

export type RangeSliderPropsType = {
  _id?: string;
  header?: string;
  beginningLabel: string;
  middleLabel?: string;
  endLabel: string;
  minValue: number;
  maxValue: number;
  rangeContent: RangeContent;
  storedValue?: number;
};

const RangeSlider: FunctionComponent<RangeSliderPropsType> = ({ header, _id, ...props }) => {
  const {
    STEP_VALUE,
    score,
    tooltipIsVisible,
    tooltipContent,
    tooltipColor,
    labels,
    onCloseTooltip,
    onChange,
    onReleased,
    minValue,
    maxValue,
  } = useRangeSlider(props);

  function handleChange() {
    onReleased();
    handleUserProgressDataChange(`${_id}-${header}`, score);
  }

  return (
    <>
      {header && <Description content={header} className="h4" />}
      <LabelsContainer>
        {labels.map((label, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Label key={`${label}_${index}`}>{label}</Label>
        ))}
      </LabelsContainer>
      <StyledSlider
        marks
        track={false}
        step={STEP_VALUE}
        min={minValue}
        max={maxValue}
        onChange={(_e, value) => onChange(+value)}
        onChangeCommitted={handleChange}
        value={score}
      />
      {tooltipIsVisible ? (
        <Tooltip
          backgroundColor={tooltipColor}
          content={tooltipContent}
          closeButton
          onCloseButton={onCloseTooltip}
        />
      ) : null}
    </>
  );
};

export default RangeSlider;
