import { MutableRefObject, useEffect, useRef } from 'react';

const useScrollToTop: (id?: string) => MutableRefObject<HTMLDivElement | null> = (id?: string) => {
  const ref = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [ref, id]);

  return ref;
};

export default useScrollToTop;
