import styled from 'styled-components';
import { colors } from 'common/colors';

export const CourseNavigationWrap = styled.div<{
  gridTemplateColumns2: boolean;
}>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${colors.primary_white};
  padding: 25px 30px;
  box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-gap: 0 20px;
  grid-template-columns: ${props => (props.gridTemplateColumns2 ? '1fr minmax(max-content, 1fr)' : '1fr')}
`;
