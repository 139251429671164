import { FC } from 'react';
import { ItemType } from 'stepComponents/dndLetters/useDndLetters';
import LetterBoxContainer from '../letterBoxContainer/LetterBoxContainer';
import { LettersListWrap } from './LettersListStyles';

type LettersListPropsType = {
  items: ItemType[];
};

const LettersList: FC<LettersListPropsType> = ({ items }) => (
  <LettersListWrap>
    {items.map(item => (
      <LetterBoxContainer key={item.id} letterItem={item} />
    ))}
  </LettersListWrap>
);

export default LettersList;
