import { FunctionComponent } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import ListItem from 'stepComponents/dnd/list/ListItem';
import { useDroppable } from '@dnd-kit/core';
import { ListWrap, TitleWrap } from './ListStyles';

const List: FunctionComponent<{ id: string, title: string, items: string[] }> = (
  {
    id,
    title,
    items
  }
) => {
  const { setNodeRef } = useDroppable({ id });
  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <ListWrap ref={setNodeRef}>
        <TitleWrap className="body16Bold">
          {title}
        </TitleWrap>
        <div>
          {items.map(item => (
            <ListItem key={item} id={item} />
          ))}
        </div>
      </ListWrap>
    </SortableContext>
  );
};

export default List;
