import styled from 'styled-components';
import { colors } from 'common/colors';
import { ReactComponent as CorrectIcon } from 'assets/img/correct_icon_filled.svg';

export const TopicsListWrap = styled.ol`
  grid-column: 1 / 12;
  padding-left: 17px;
`;

export const TopicListElement = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  li {
    grid-column: 1 / 8;
  }
`;

export const IsCompletedWrap = styled.div`
  grid-column: 8 / 10;
  display: flex;
  justify-content: center;
`;

export const IsCompleted = styled(CorrectIcon)`
  width: 24px;
  height: 24px;
`;

export const TopicItemsRowWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  span {
    margin-left: 4px;
    color: ${colors.primary_gray5};
    font-size: 14px;
    line-height: 21px;
  }
  svg {
    height: 30px;
    width: 30px;
    margin-right: 15px;
  }
`;
