import { CSSProperties, FunctionComponent } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ListItemWrap } from './ListItemStyles';

const ListItem: FunctionComponent<{ id: string }> = ({ id }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id });

  const style = {
    touchAction: 'none',
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    <div
      ref={setNodeRef}
      style={style as CSSProperties}
      {...attributes}
      {...listeners}
    >
      <ListItemWrap
        className="body16Bold"
      >
        {id}
      </ListItemWrap>
    </div>
  );
};

export default ListItem;
