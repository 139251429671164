import { useState } from 'react';
import { TooltipColorType } from 'stepComponents/tooltip/Tooltip';
import { RangeSliderPropsType } from './RangeSlider';

interface RangeSliderHook {
  STEP_VALUE: number;
  score: number;
  tooltipIsVisible: boolean;
  tooltipContent: string;
  tooltipColor: TooltipColorType;
  labels: string[];
  onCloseTooltip: () => void;
  onChange: (score: number) => void;
  onReleased: () => void;
  minValue: number;
  maxValue: number;
}

const useRangeSlider = ({
  beginningLabel,
  middleLabel,
  endLabel,
  minValue,
  maxValue,
  rangeContent,
  storedValue
}: RangeSliderPropsType): RangeSliderHook => {
  const STEP_VALUE = 1;
  const [score, setScore] = useState<number>(storedValue || Math.round((minValue + maxValue) / 2));
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipColor, setTooltipColor] = useState('');
  const labels = [beginningLabel, middleLabel, endLabel].filter(label => label);

  const onChange = (score: number) => {
    setScore(score);
  };
  const onReleased = () => {
    const assignedRange = rangeContent.find(range => range.minValue <= score && score <= range.maxValue);
    if (assignedRange) {
      setTooltipContent(assignedRange.content);
      setTooltipColor(assignedRange.tooltipColor);
      setTooltipIsVisible(true);
    }
  };

  const onCloseTooltip = () => setTooltipIsVisible(false);

  return {
    STEP_VALUE,
    score,
    tooltipIsVisible,
    tooltipContent,
    tooltipColor: tooltipColor as TooltipColorType,
    labels: labels as string[],
    onCloseTooltip,
    onChange,
    onReleased,
    minValue,
    maxValue,
  };
};

export default useRangeSlider;
