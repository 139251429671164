import { FunctionComponent } from 'react';
import BadgeIcon from 'common/badgeIcon/BadgeIcon';
import { BadgesInfoListWrap, BadgeItem, Description } from './BadgesInfoListStyles';

type BadgesInfoListPropsType = {
  badges: {
    type: string;
    level: string;
    color: string;
  }[]
};

const BadgesInfoList: FunctionComponent<BadgesInfoListPropsType> = ({ badges }) => (
  <BadgesInfoListWrap>
    {badges.map(({ type, level, color }) => (
      <BadgeItem key={`${type}_${level}`}>
        <BadgeIcon color={color} key={`${color}`} />
        <Description key={`${level}`}>
          <h4>Badge of {type}</h4>
          <span>{level}</span>
        </Description>
      </BadgeItem>
    ))}
  </BadgesInfoListWrap>
);

export default BadgesInfoList;
