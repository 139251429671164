import { useEffect, useState } from 'react';
import { useAuth, UserType } from 'utils/AuthContext';
import userApi, { BadgeData } from 'utils/userApi';
import parseBadgesEarned from 'utils/parseBadgesEarned';

type UseAddBadgeType = (badgeData: BadgeData) => { isAddingBadgeInProgress: boolean };

const useAddBadge: UseAddBadgeType = (badgeData) => {
  const [isAddingBadgeInProgress, setIsAddingBadgeInProgress] = useState<boolean>(false);
  const { user, setUser } = useAuth();

  const handleAddBadge = async () => {
    if (user) {
      try {
        setIsAddingBadgeInProgress(true);
        const res = await userApi.addBadge(badgeData);
        if (res.badgesEarned) {
          const updatedUser: UserType = {
            ...user,
            badgesEarned: parseBadgesEarned(res.badgesEarned)
          };
          setUser(updatedUser);
        }
      } catch (err) {
        throw new Error(err);
      } finally {
        setIsAddingBadgeInProgress(false);
      }
    }
  };

  useEffect(() => {
    if (
      badgeData.topicId
      && user?.badgesEarned.findIndex(userBadge => userBadge.topicId === badgeData.topicId) === -1
    ) {
      handleAddBadge();
    } else if (
      badgeData.courseId
      && user?.badgesEarned.findIndex(userBadge => userBadge.courseId === badgeData.courseId) === -1
    ) {
      handleAddBadge();
    }
  }, []);

  return {
    isAddingBadgeInProgress
  };
};

export default useAddBadge;
