import { FC } from 'react';
import { ParsedBadge } from 'utils/parseBadgesQuery';
import { colors } from 'common/colors';
import { PathBadgesSetWrap, BadgeWrap } from 'pages/badges/PathBadgesSetStyles';
import BadgeIcon from '../../common/badgeIcon/BadgeIcon';

type PathBadgesSetPropsType = {
  pathName: string;
  pathBadges: ParsedBadge[];
};

const PathBadgesSet: FC<PathBadgesSetPropsType> = ({ pathName, pathBadges }) => (
  <PathBadgesSetWrap>
    <h4 className="title">{pathName}</h4>
    <div className="badges-set">
      {pathBadges.map((badge, index: number) => (
        <BadgeWrap isAcquired={badge?.isAcquired} key={`${badge?.type}-${index + 1}`}>
          <BadgeIcon color={colors[badge?.color as keyof typeof colors]} />
        </BadgeWrap>
      ))}
    </div>
  </PathBadgesSetWrap>
);

export default PathBadgesSet;
