import { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_BADGES_QUERY } from 'utils/gqlQueries';
import Tooltip from 'stepComponents/tooltip/Tooltip';
import parseBadgesQuery, { BadgeOrigin, ParsedBadgesQuery } from 'utils/parseBadgesQuery';
import { colors } from 'common/colors';
import { useAuth, UserType } from 'utils/AuthContext';
import AppSpinner from 'common/appSpinner/AppSpinner';
import PathBadgesSet from 'pages/badges/PathBadgesSet';
import { getMissingBadges } from 'pages/courseControler/components/userProgress/userProgressHelper';
import userApi from 'utils/userApi';
import parseBadgesEarned from 'utils/parseBadgesEarned';
import allBadges from './all_badges.json';
import { BadgesWrap } from './BadgesStyles';
import BadgesInfoList from './BadgesInfoList';

const Badges: FC = () => {
  const { user, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getBadges, { loading: isRequestInProgress, data }] = useLazyQuery(GET_BADGES_QUERY);
  const [badges, setBadges] = useState<ParsedBadgesQuery>([]);
  const [badgesNb, setBadgesNb] = useState<number>(0);
  const [areBadgesInSync, setBadgesInSync] = useState(true);

  const syncBadges = async () => {
    const missingBadges = getMissingBadges(user, badges);
    if (!missingBadges.length || !user) {
      return;
    }
    setIsLoading(true);
    try {
      const updatedBadges = await userApi.addMultipleBadges(missingBadges);
      if (!updatedBadges.badgesEarned) {
        return;
      }
      const updatedUser: UserType = {
        ...user,
        badgesEarned: parseBadgesEarned(updatedBadges.badgesEarned)
      };
      setUser(updatedUser);
    } catch (e) {
      setBadgesInSync(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBadges();
  }, []);

  useEffect(() => {
    if (isRequestInProgress || !badges.length) {
      return;
    }
    syncBadges();
  }, [isRequestInProgress, badges]);

  useEffect(() => {
    if (
      !isRequestInProgress
      && data
      && data.paths
      && user
    ) {
      const parsedBadges = parseBadgesQuery(data.paths)
        .map(badgesSet => ({
          ...badgesSet,
          pathBadges: badgesSet.pathBadges.map(badge => {
            if (badge?.origin === BadgeOrigin.COURSE) {
              return {
                ...badge,
                isAcquired: user.badgesEarned.findIndex(userBadge => userBadge.courseId === badge?.courseId) > -1
              };
            }
            return {
              ...badge,
              isAcquired: user.badgesEarned.findIndex(userBadge => userBadge.topicId === badge?.topicId) > -1
            };
          })
        }));
      const badgesNumber = parsedBadges.reduce((acc, { pathBadges }) => acc + pathBadges.length, 0);

      setBadges(parsedBadges as ParsedBadgesQuery);
      setBadgesNb(badgesNumber);
      setIsLoading(false);
    }
  }, [isRequestInProgress, data, user]);

  return (
    <BadgesWrap isLoading={isLoading}>
      {isLoading ? <AppSpinner /> : (
        <>
          <h4 className="heading">Badges</h4>
          {!areBadgesInSync
            && (
              <h5 className="error">
                <p>Badges could be unsynchronized</p>
                <p>Refresh the application</p>
              </h5>
            )}
          <div className="badges-counter body16Regular">
            You have
            <div className="rounded body16Bold">
              {user?.badgesEarned.length} / {badgesNb}
            </div>
            badges.
          </div>
          {badges.map(({ pathName, pathBadges }, index: number) => (
            <div key={pathName}>
              <PathBadgesSet
                pathName={pathName}
                pathBadges={pathBadges}
              />
              {(index !== badges.length - 1)
                ? <hr color={colors.primary_gray_light1} /> : null}
            </div>
          ))}
          <Tooltip
            icon="what_happened_cyclops"
            iconPosition="topLeft"
            backgroundColor="primary_gray_light5"
            content={'Here is a list of all badges which you can earn when'
            + ' you complete Course or Topic in Pathway.'}
            title="Badges meaning"
          />
          <BadgesInfoList badges={allBadges} />
        </>
      )}
    </BadgesWrap>
  );
};

export default Badges;
