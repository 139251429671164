import { FunctionComponent } from 'react';
import {
  AnnounceCardWrap, Chip, Header, Description, Instruction
} from './AnnounceCardStyles';

export type AnnounceCardPropsType = {
  name: string;
  description: string;
  instruction?: string;
};

const AnnounceCard: FunctionComponent<AnnounceCardPropsType> = ({
  name,
  description
}) => (
  <AnnounceCardWrap>
    <Chip>Upcoming</Chip>
    <Header>{name}</Header>
    <Description>{description}</Description>
    <Instruction>Click <b>&apos;Next&apos;</b> to continue.</Instruction>
  </AnnounceCardWrap>
);

export default AnnounceCard;
