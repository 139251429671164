import React, { ChangeEvent, FunctionComponent } from 'react';
import { handleUserProgressDataChange } from 'pages/courseControler/components/userProgress/userProgressHelper';
import { Label, StyledInput } from './InputStyles';

export type InputPropsType = {
  _id?: string;
  label?: string;
  stepName?: string;
  placeholder?: string;
  storedValue?: string;
  parentId?: string;
};

const Input: FunctionComponent<InputPropsType> = ({
  _id,
                                                    label,
                                                    placeholder,
  storedValue,
  parentId
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      handleUserProgressDataChange(`${label}-${parentId || _id}`, event.target.value);
  };
  return (
    <>
      {label ? <Label>{label}</Label> : null}
      <StyledInput
        defaultValue={storedValue}
        variant="outlined"
        placeholder={placeholder || 'Start typing'}
        multiline
        rows={2}
        fullWidth
        onChange={handleChange}
      />
    </>
  );
};

export default Input;
