import styled from 'styled-components';
import { colors } from 'common/colors';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 45px 30px 30px 30px;
  background-color: ${colors.primary_white};
`;

export const Header = styled.h4<{
  isRequestError: boolean
}>`
  margin-top: 40px;
  margin-bottom: ${({ isRequestError }) => (isRequestError ? '5px' : '20px')};
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  font-family: 'AppMulish', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
  margin: 15px 0;
  color: ${colors.primary_indigo};
`;

export const ErrorWrap = styled.span`
  display: flex;
  color: #f44336;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  margin-bottom: 32px;
`;

export const StyledForm = styled.form`
  width: 100%;
`;
