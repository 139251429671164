import { FC } from 'react';
import BadgeIcon from 'common/badgeIcon/BadgeIcon';
import { BadgesLevelsEnum, BadgesTypesEnum } from 'pages/badges/BadgesEnum';
import { CircleWithIconWrap } from './CircleWithIconStyles';

type CircleWithIconPropsType = {
  variant?: 'certificate' | 'badge';
  badgeType?: BadgesTypesEnum;
  badgeLevel?: BadgesLevelsEnum;
  backgroundColor?: string;
};

const CircleWithIcon: FC<CircleWithIconPropsType> = ({
  variant,
  badgeLevel,
  badgeType,
  backgroundColor
}) => (
  <CircleWithIconWrap backgroundColor={backgroundColor}>
    {variant === 'certificate' ? <BadgeIcon type="certificate" badgeType={badgeType} badgeLevel={badgeLevel} />
      : <BadgeIcon badgeType={badgeType} badgeLevel={badgeLevel} />}
  </CircleWithIconWrap>
);

export default CircleWithIcon;
