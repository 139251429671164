import React, { FC, useState } from 'react';
import { ReactComponent as CloseDropdown } from 'assets/img/close_dropdown_icon.svg';
import { ReactComponent as OpenDropdown } from 'assets/img/open_dropdown_icon.svg';
import { DropdownListWrap, DropdownLabel } from './DropdownListStyles';

export type DropdownListPropsType = {
  dropdownLabel: string;
  dropdownListItems: { name: string }[];
};

const DropdownList: FC<DropdownListPropsType> = ({
                                                   dropdownLabel,
                                                   dropdownListItems
                                                 }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <DropdownListWrap isOpen={isOpen}>
      <DropdownLabel onClick={() => setOpen(!isOpen)}>
        <div className="dropdown-label">{dropdownLabel}</div>
        <div className="dropdown-icon">
          {isOpen
            ? <CloseDropdown />
            : <OpenDropdown />}
        </div>
      </DropdownLabel>
      { isOpen && (
        <>
          {dropdownListItems.map(listPosition =>
            <div className="dropdown-list-item" key={listPosition.name}>{listPosition.name}</div>)}
        </>
      )}
    </DropdownListWrap>
  );
};

export default DropdownList;
