import { ChangeEvent, FunctionComponent } from 'react';
import { TooltipColorType } from 'stepComponents/tooltip/Tooltip';
import {
  RadioWrap, StyledRadioMUI, Icon, Label
} from './RadioStyles';
import { Tooltip } from '../index';

export type RadioPropsType = {
  name?: string;
  label: string;
  value: string;
  checked: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  showTooltip?: boolean;
  tooltipColor?: TooltipColorType;
  tooltipContent?: string;
};

const Radio: FunctionComponent<RadioPropsType> = ({
  name,
  label,
  value,
  checked,
  handleChange,
  showTooltip,
  tooltipColor,
  tooltipContent
}) => (
  <RadioWrap>
    <Label className="button16Semibold">
      <StyledRadioMUI
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        icon={<Icon />}
        checkedIcon={<Icon checked />}
        disableRipple
        disableFocusRipple
        disableTouchRipple
      />
      { label }
    </Label>
    {showTooltip ? (
      <Tooltip
        backgroundColor={tooltipColor}
        content={tooltipContent || ''}
      />
    ) : null}
  </RadioWrap>
);

export default Radio;
