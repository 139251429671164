import styled from 'styled-components';
import { colors } from 'common/colors';

export const CloseButtonWrap = styled.div`
  background-color: ${colors.primary_white};
  border-radius: 10px;
  width: 36px;
  height: 36px;
  color: ${colors.primary_indigo};
  font-size: 16px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;
