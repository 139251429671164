import styled from 'styled-components';
import { colors } from 'common/colors';

export const AnnounceCardWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  
  padding: 20px;
  border-radius: 10px;
  background-color: ${colors.primary_indigo_shade3};
`;

export const Chip = styled.span`
  padding: 9px 15px;
  margin-bottom: 15px;
  border-radius: 100px;
  background-color: ${colors.primary_white};
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
`;

export const Header = styled.h4`
  color: ${colors.primary_gray1};
`;

export const Description = styled.span`
  margin-top: 10px;
  color: ${colors.primary_gray1};
`;

export const Instruction = styled.span`
  margin-top: 10px;
  color: ${colors.primary_gray3};
`;
