import styled from 'styled-components';
import { colors } from 'common/colors';
import { TooltipColorType } from 'stepComponents/tooltip/Tooltip';

export const TooltipWrap = styled.div<{
  background: TooltipColorType,
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-wrap: break-word;   
  padding: 25px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 9px rgba(0,0,0, 0.1);
  background-color: ${props => colors[props.background] || colors.primary_gray_light5};
  margin-bottom: 25px;
  .heading {
    display: grid;
    grid-template-columns: 1fr 36px;
    grid-column-gap: 10px;
    margin: 0;
    
    .closeButton {
      grid-column-start: 2;
    }
    .title {
      margin: 0;
      display: flex;
      & h4 {
        margin-left: 10px;
      }
    }
  }
`;

export const DescriptionWrap = styled.div`
  display: flex;
  align-items: center;
`;
