import { FC } from 'react';
import { ReactComponent as Stars } from 'assets/img/completed_k_b_stars.svg';
import WhatsNext from 'stepComponents/whatsNext/WhatsNext';
import WithNavigation from 'pages/courseControler/components/navigation/WithNavigation';
import { CompletedKnowledgeBiteWrap } from './CompletedKnowledgeBiteStyles';

const CompletedKnowledgeBite: FC = () => {
  const lSData = localStorage.getItem('currentTreadData');
  const {
    currentKBOrder,
    kbInTopic,
    id,
    mindfulnessBreak
  } = JSON.parse(lSData || '{currentKBOrder: \'x\', kbInTopic: \'x\'}');
  return (
    <WithNavigation level="completedKnowledgeBite">
      <CompletedKnowledgeBiteWrap>
        <Stars />
        <h3 className="header">Knowledge bite</h3>
        <div className="kb-number">{currentKBOrder + 1} of {kbInTopic}</div>
        <h4>Completed</h4>
        <WhatsNext variant="completedKnowledgeBite" parentId={id} mindfulnessBreak={mindfulnessBreak} />
      </CompletedKnowledgeBiteWrap>
    </WithNavigation>
);
};

export default CompletedKnowledgeBite;
