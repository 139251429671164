import { FC } from 'react';
import FormInput from 'common/formInput/FormInput';
import Tooltip from 'stepComponents/tooltip/Tooltip';
import { useCvBuilder } from 'utils/CvBuilderContext';
import Description from 'stepComponents/description/Description';
import { StepWrap, StepHeader } from '../styles';
import { InputWrap } from './SummaryStatementStyles';

const SummaryStatement: FC = () => {
  const { values: { summaryStatement }, handleChange } = useCvBuilder();

  return (
    <StepWrap>
      <StepHeader isDescription>
        Personal/summary statement
      </StepHeader>
      <Description
        className="body16Regular"
        content={'A personal/summary statement highlights your qualifications for a job. It is usually 3-4 sentences, '
        + 'or a bulleted list, with a summary of your hard and soft skills.'}
      />
      <InputWrap>
        <FormInput
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={summaryStatement}
          onChange={handleChange('summaryStatement')}
          name="schoolCity"
          label="Write your summary statement:"
        />
      </InputWrap>
      <Tooltip
        closeButton
        backgroundColor="primary_aquamarine"
        content={'- Write out skills that show you are a strong candidate for the job\n- Remember to talk about '
        + 'what you can bring to the team\n- Provide specific achievements - use percentages or numbers as proof '
        + 'of your skills'}
      />
    </StepWrap>
  );
};

export default SummaryStatement;
