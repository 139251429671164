import styled from 'styled-components';
import { colors } from 'common/colors';

export const CertificateCardWrap = styled.div`
  width: 100%;
  height: 224px;
  position: relative;
  background-color: ${colors.primary_gray_light3};
  margin-bottom: 30px;
  border-radius: 10px;
`;

export const CertificateNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 24px;
`;

export const IconWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: ${colors.primary_gray_light3};
  border-radius: 10px;
  opacity: 0.8;
  top: 0;
  left: 0;
  z-index: 100;
`;
