import { FunctionComponent } from 'react';
import { ReactComponent as DefaultImage } from 'assets/img/default_image.svg';
import { ImageWrap } from './ImageStyles';

export type ImagePropsType = {
  image: { url: string };
  name: string;
};

const Image: FunctionComponent<ImagePropsType> = ({ image, name }) => (
  <ImageWrap>
    {image.url ? <img src={image.url} alt={name} /> : <DefaultImage />}
  </ImageWrap>
);

export default Image;
