import {
  ChangeEvent,
  FC,
  useState
} from 'react';
import { Checkbox } from 'stepComponents/index';
import { handleUserProgressDataChange } from 'pages/courseControler/components/userProgress/userProgressHelper';
import { CheckboxListWrap, CheckboxListStylesEnum } from './CheckboxListStyles';

export type CheckboxesState = {
  [key: string]: {
    label: string;
    value: boolean;
  };
};

type CheckboxItem = {
  name: string;
  label: string;
  tooltipContentOnSuccess: string;
  tooltipContentOnError: string;
  correct: boolean;
};

export type CheckboxListPropsType = {
  _id?: string;
  storedValue?: CheckboxesState | string | number | undefined | {
    label: string, value: string, selectedValue: string
  };
  checkboxItems?: CheckboxItem[];
  listStyle?: CheckboxListStylesEnum
};

const createInitialCheckboxState = (checkboxItems: CheckboxItem[] = []) => checkboxItems.reduce(
  (o: CheckboxesState, {
    name,
    label,
  }) => ({
    ...o,
    [name]: { label, value: false }
  }),
  {}
);

const CheckboxList: FC<CheckboxListPropsType> = ({
                                                   _id,
                                                   storedValue,
                                                   checkboxItems,
                                                   listStyle = CheckboxListStylesEnum.default
}) => {
  const [checkboxesState, setCheckboxesState] = useState<CheckboxesState>(
    storedValue as CheckboxesState || createInitialCheckboxState(checkboxItems)
  );

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxesState(prevCheckboxesState => {
      const checkboxName = e.target.name as keyof CheckboxesState;
      const checkboxToChange = prevCheckboxesState[checkboxName];
      const newCheckboxesState = {
        ...prevCheckboxesState,
        [checkboxName]: { ...checkboxToChange, value: e.target.checked }
      };
      handleUserProgressDataChange(`${_id}`, newCheckboxesState);
      return newCheckboxesState;
    });
  };

  return (
    <CheckboxListWrap listStyle={listStyle}>
      {Object.entries(checkboxesState).map(([name, {
        label,
        value,
      }]) => (
        <Checkbox
          key={name}
          name={name}
          label={label}
          handleChange={handleCheckboxChange}
          checked={value}
          listStyle={listStyle}
        />
      ))}
    </CheckboxListWrap>
  );
};

export default CheckboxList;
