import styled from 'styled-components';
import { colors } from 'common/colors';
import { TextField, withStyles } from '@material-ui/core/index';

export const Label = styled.label`
  margin: 15px 0;
  color: ${colors.primary_gray4};
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: ${colors.primary_black};
`;

export const StyledInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      borderWidth: '1px',
      backgroundColor: colors.primary_gray_light4,
      '&.Mui-focused': {
        backgroundColor: colors.primary_white,
        boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.1)'
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primary_indigo,
        borderWidth: '1px'
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      borderWidth: '1px',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 15px'
    },
    marginBottom: '25px'
  }
})(TextField);
