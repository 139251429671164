import styled from 'styled-components';

export const StyledListWrap = styled.ul<{
  markerImgUrl?: string;
}>`
  padding-left: ${props => (props.markerImgUrl ? '29px' : '20px')};;
  list-style: none;

  li{
    position: relative;
  }

  li::before{
    content: '';
    position: absolute;
    right: 100%;
    margin-right: 5px;
    display: inline-block;
    height: ${props => (props.markerImgUrl ? '24px' : '100%')};;
    width: ${props => (props.markerImgUrl ? '24px' : '15px')};
    background-image: url(${props => props.markerImgUrl || '/list_disc.svg'});;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
 }
  li {
    margin: 10px 0;
    word-wrap: break-word;
  }
`;
