import {
 createContext, useContext, useState, useMemo, FC
} from 'react';

type PathStateType = {
  pathStateId?: string;
  courseId?: string;
  topicId?: string;
  knowledgeBiteId?: string;
  knowledgeBiteStepId?: string;
};

type PathContextResult = [PathStateType, React.Dispatch<React.SetStateAction<PathStateType>>];

const defaultPathContext: PathContextResult = [{}, () => {}];

const PathContext = createContext(defaultPathContext);

const usePath: () => PathContextResult = () => {
  const context = useContext(PathContext);
  if (!context) {
    throw new Error('usePath must be used within a PathProvider');
  }
  return context;
};
// eslint-disable-next-line
const PathProvider: FC = (props: any) => {
  const [pathState, setPath] = useState<PathStateType>(defaultPathContext[0]);
  const value = useMemo(() => [pathState, setPath], [pathState]);
  return <PathContext.Provider value={value} {...props} />;
};

const usePathState: () => React.Dispatch<PathStateType> = () => {
  const [,setPath] = usePath();
  return (obj: PathStateType) => setPath((state: PathStateType) => ({ ...state, ...obj }));
};

export {
  PathProvider,
  usePath,
  usePathState,
  PathContext
};
