import { FC, useState } from 'react';
import AnswerCard from 'stepComponents/cardQuiz/AnswerCard';
import { TooltipColorType } from 'stepComponents/tooltip/Tooltip';
import { CardQuizWrap, QuestionWrap } from './CardQuizStyles';

type AnswerItem = {
  answer: string;
  value: string;
  tooltipColor?: TooltipColorType;
  tooltipContent?: string;
};

export type CardQuizPropsType = {
  question: string;
  answerItems: AnswerItem[];
};

const CardQuiz: FC<CardQuizPropsType> = ({ question, answerItems }) => {
  const [selectedCard, setSelectedCard] = useState<string>();
  return (
    <CardQuizWrap>
      <QuestionWrap>
        {question}
      </QuestionWrap>
      {answerItems.map(({ answer, value }, index) => (
        <AnswerCard
          key={value}
          index={index}
          content={answer}
          onClick={() => setSelectedCard(value)}
          selected={selectedCard === value}
        />
      ))}
    </CardQuizWrap>
  );
};

export default CardQuiz;
