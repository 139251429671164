import styled from 'styled-components';
import { colors } from 'common/colors';

export const AppWrap = styled.div`
  background-color: ${colors.primary_gray_light5};
  max-width: 375px;
  min-width: 375px;
  height: 100vh;
  position: relative;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  h3, .h3 {
    font-family: RoundedMplus1c, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
  }

  h4, .h4 {
    font-family: RoundedMplus1c, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  h5, .h5 {
    font-family: RoundedMplus1c, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }

  h6 {
    font-family: RoundedMplus1c, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  
  .body18Regular {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
  }
  
  .body16Regular {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }
  
  .body16Bold {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .label12Regular {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
  }

  .label12Bold {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
  
  .button16Semibold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  
  .link {
    cursor: pointer;
    text-underline: #000066;
  }
  
  .labelMobile12Bold {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
  
  .step-header {
    color: ${colors.secondary_blue_light};
    font-weight: bold;
  }
`;
