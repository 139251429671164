import React from 'react';
import { ActivitiesTagWrap } from './ActivitiesTagStyles';

type ActivitiesTagType = {
  number: number;
  activityName: string;
};

function ActivitiesTag({
  number, activityName
}: ActivitiesTagType): JSX.Element {
  return (
    <ActivitiesTagWrap>
      <div className="label12Regular">{number} {activityName}</div>
    </ActivitiesTagWrap>
  );
}

export default ActivitiesTag;
