import React, { CSSProperties, FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DragIcon } from 'assets/img/drag_icon.svg';
import { DraggableItemWrap } from './DraggableItemStyles';

type DraggableItemPropsType = {
  content: string;
};

const DraggableItem: FC<DraggableItemPropsType> = ({ content }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
  } = useSortable({ id: content });

  const style = {
    touchAction: 'none',
    transform: CSS.Translate.toString(transform),
  };

  return (
    <DraggableItemWrap
      ref={setNodeRef}
      style={style as CSSProperties}
      {...attributes}
      {...listeners}
    >
      <span className="body16Regular">
        {content}
      </span>
      <div>
        <DragIcon />
      </div>
    </DraggableItemWrap>
  );
};

export default DraggableItem;
