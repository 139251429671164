import { FC } from 'react';
import {
  ProgressBarWrap,
  ProgressWrap,
  Label,
  ProgressNumber
} from './ProgressBarStyles';

type ProgressBarPropsType = {
  label?: string;
  hideProgressNumber?: boolean;
  progress: number;
  frontBarColor?: string;
  backBarColor?: string;
};

const ProgressBar: FC<ProgressBarPropsType> = ({
                                                 label,
                                                 progress,
                                                 frontBarColor,
                                                 backBarColor,
                                                 hideProgressNumber
                                               }) => {
  const validatedProgress = (progress >= 0 && progress <= 100) ? progress : 0;
  return (
    <ProgressBarWrap>
      { label && <Label className="labelMobile12Bold">{label}</Label> }
      { !hideProgressNumber
      && <ProgressNumber className="body16Regular">{validatedProgress}%</ProgressNumber> }
      <ProgressWrap
        progress={validatedProgress}
        frontBarColor={frontBarColor}
        backBarColor={backBarColor}
      >
        <div className="frontBar" />
      </ProgressWrap>
    </ProgressBarWrap>
  );
};

export default ProgressBar;
