import styled from 'styled-components';

export const PathBadgesSetWrap = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    margin: 30px 0;
  }
  
  .badges-set {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
`;

export const BadgeWrap = styled.div<{
  isAcquired?: boolean;
}>`
  padding: 2px;
  opacity: ${({ isAcquired }) => (isAcquired ? '1' : '0.5')};
`;
