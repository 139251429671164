import styled from 'styled-components';
import { colors } from 'common/colors';

export const StepDisplayWrap = styled.div`
  position: relative;
  height: 100%;
  padding: 85px 30px 120px;
  overflow: auto;
  background-color: ${colors.primary_white};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StepWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  > * {
    margin-bottom: 25px;
  }
`;
