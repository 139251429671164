import styled from 'styled-components';
import { colors } from 'common/colors';

export const QuoteWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  
  background-color: ${colors.primary_white};
  border-radius: 10px;
  border: 1px solid ${colors.primary_gray_light2};
`;

export const QuoteMark = styled.span`
  font-family: RoundedMplus1c, sans-serif;
  font-size: 64px;
  line-height: 54px;
  font-weight: 500;
  color: ${colors.primary_cyan};
`;

export const Content = styled.span`
  margin-top: -15px;
  margin-bottom: 10px;
  color: ${colors.primary_gray1};
`;

export const Author = styled.span`
  align-self: flex-end;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primary_gray4};
`;
