import { FC } from 'react';
import { Tooltip } from 'stepComponents';

function NoMaterialsSaved(): JSX.Element {
  return (
    <div>
      <div className="h4">No materials saved</div>
      <Tooltip
        content="Click 'Save' button 💾 during the courses to add materials to your learning library."
        icon="what_happened_cyclops"
        iconPosition="topLeft"
        title="Tips and help"
        backgroundColor="primary_gray_light5"
      />
    </div>
  );
}

const SavedTab: FC = () => {
  const pathwayId = () => localStorage.getItem('pathwayId') || '';
  const savedMaterials
    : { [key: string]: { id: string, date: Date }[] } = JSON.parse(localStorage.getItem('savedMaterials') || '{}');
  return (
    <>
      { savedMaterials[pathwayId()]
        ? (
          savedMaterials[pathwayId()].map(data => <div key={data.id}>{data.id}</div>)
        )
      : (<NoMaterialsSaved />)}
    </>
);
};

export default SavedTab;
