import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Providers from 'providers/Providers';
import AuthInterceptor from 'utils/AuthInterceptor';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <AuthInterceptor>
        <App />
      </AuthInterceptor>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
