import { useState, useEffect, FC } from 'react';
import { PathwaysListWrap } from 'pages/pathway/pathwaysList/PathwaysListStyles';
import PathwayCard, {
  PathwayBasicInfo,
} from 'pages/pathway/pathwayCard/PathwayCard';
import { useLazyQuery } from '@apollo/client';
import { GET_PATHS_QUERY } from 'utils/gqlQueries';
import AppSpinner from 'common/appSpinner/AppSpinner';

const PathwaysList: FC = () => {
  const [getPaths, { loading: isRequestInProgress, data }] = useLazyQuery(GET_PATHS_QUERY);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paths, setPaths] = useState<PathwayBasicInfo[]>([]);

  useEffect(() => {
    getPaths();
  }, []);

  useEffect(() => {
    if (
      !isRequestInProgress
      && data
      && data.paths
    ) {
      const sortedPaths = [...data.paths].sort((a, b) => a.orderNumber - b.orderNumber);
      setPaths(sortedPaths);
      localStorage.removeItem('pathwayId');
      localStorage.removeItem('singleLevelThread');
      localStorage.removeItem('currentTread');
      localStorage.removeItem('currentTreadData');
      setIsLoading(false);
    }
  }, [data, isRequestInProgress]);

  return (
    <PathwaysListWrap>
      { isLoading ? <AppSpinner /> : (
        <>
          <h4 className="heading">Pathways</h4>
          {paths.map((pathway: PathwayBasicInfo) => (
            <PathwayCard
              key={pathway.id}
              id={pathway.id}
              coursesNumber={pathway.coursesNumber}
              badgesNumber={pathway.badgesNumber}
              certificatesNumber={pathway.certificatesNumber}
              isLocked={pathway.isLocked}
              isHidden={pathway.isHidden}
              name={pathway.name}
              image={pathway.image}
              content={pathway.content}
            />
          ))}
        </>
      )}
    </PathwaysListWrap>
  );
};

export default PathwaysList;
