import styled from 'styled-components';

export const WhatsNextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

export const Header = styled.div`
  display: flex;
  
  .label {
    padding-left: 15px;
  }
`;

export const BreakButton = styled.div`
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 10px;
`;

export const Instruction = styled.div`
  margin-top: 10px;
  text-align: center;
  line-height: 24px;
`;
