import {
  createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState
} from 'react';
import userApi from 'utils/userApi';
import parseUserData from 'utils/parseUserData';
import { BadgesLevelsEnum, BadgesTypesEnum } from 'pages/badges/BadgesEnum';
import { CvBuilderValues } from 'utils/CvBuilderContext';
import { LOCAL_STORAGE_KEY } from 'utils/AuthConstants';
import { isUserComplete } from 'utils/isUserComplete';
import localStorageExpirationValidator from './localStorageExpirationValidator';

export type BadgeEarnedType = {
  id: string;
  topicId?: string;
  topicName?: string;
  courseId?: string;
  courseName?: string;
  badge: {
    id: string;
    type: BadgesTypesEnum;
    level: BadgesLevelsEnum;
    color: string;
  };
};

export type UserType = {
  id: string;
  firstName?: string;
  lastName?: string;
  rsaId?: string;
  phone?: string;
  email: string;
  role: string;
  badgesEarned: BadgeEarnedType[];
  certificates: string[];
  cv: CvBuilderValues;
};

type AuthStateType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  user: UserType | null;
  setUser: Dispatch<SetStateAction<UserType | null>>
  isSignInInProgress: boolean;
  setIsSignInInProgress: (isSignInInProgress: boolean) => void;
  requestError: string;
  setRequestError: (errorMessage: string) => void;
  logout: () => void;
  isComplete: boolean;
  setComplete: (isComplete: boolean) => void;
};

export const AuthContext = createContext<AuthStateType | undefined>(undefined);

export const useAuth: () => AuthStateType = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthContext');
  }
  return context;
};

export const AuthProvider: FC = ({ children }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isComplete, setComplete] = useState(false);
  const [user, setUser] = useState<UserType | null>(null);
  const [isSignInInProgress, setIsSignInInProgress] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>('');

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsSignInInProgress(true);
        if (token) {
          const jwtToken = localStorageExpirationValidator(token);
          if (jwtToken) {
            const { data } = await userApi.getSelf();
            const userData = parseUserData(data);
            setUser(userData);
            setIsAuthenticated(true);
          }
        }
      } catch (err) {
        setIsAuthenticated(false);
      } finally {
        setIsSignInInProgress(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!user) {
      setComplete(false);
      return;
    }
    setComplete(() => isUserComplete(user));
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        isSignInInProgress,
        setIsSignInInProgress,
        requestError,
        setRequestError,
        logout,
        isComplete,
        setComplete,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
