import styled from 'styled-components';
import { colors } from 'common/colors';

export const CompletedCourseWrap = styled.div`
  padding: 85px 30px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const BadgeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  padding: 30px 0;
  border-radius: 20px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  
  .badge-info {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .badge-name {
    margin-top: 30px;
    color: ${colors.primary_green};
  }
`;

export const GifContainer = styled.div`
  margin: 10px 0;
  padding: 0 30px;
`;

export const Gif = styled.img`
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
`;
