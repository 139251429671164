import { useState } from 'react';
import {
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  SensorDescriptor,
  SensorOptions,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import shuffleArray from 'utils/shuffleArray';

type UseDndOrderType = (cardItems: { name: string }[]) => {
  items: string[];
  sensors: SensorDescriptor<SensorOptions>[];
  handleDragEnd: (event: DragEndEvent) => void;
};

const useDndOrder: UseDndOrderType = (cardItems) => {
  const shuffledArray = shuffleArray(cardItems);
  const [items, setItems] = useState(shuffledArray.map(el => el.name));

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    }),
    useSensor(TouchSensor)
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setItems(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over?.id as string);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return {
    items,
    sensors,
    handleDragEnd
  };
};

export default useDndOrder;
