import { Button as ButtonMUI, withStyles } from '@material-ui/core';
import { colors } from 'common/colors';

const Button = withStyles({
  root: {
    fontFamily: 'AppMulish',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    height: '48px',
    borderRadius: '10px',
    textTransform: 'none',
    marginBottom: '3px',
  },
  contained: {
    backgroundColor: colors.primary_indigo,
    color: colors.primary_white,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 4px 9px rgba(54, 52, 163, 0.3)',
      backgroundColor: colors.primary_indigo
    }
  },
  text: {
    color: colors.primary_indigo,
    '&:hover': {
      boxShadow: '0px 4px 9px rgba(54, 52, 163, 0.3)',
      backgroundColor: 'inherit'
    }
  },
  outlined: {
    padding: '9px',
    border: `1px solid ${colors.primary_gray_light2}`,
    marginBottom: 0,
    height: '36px',
    minWidth: '36px',
    maxWidth: '36px',
    backgroundColor: colors.primary_white
  }
})(ButtonMUI);

export default Button;
