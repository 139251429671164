import styled from 'styled-components';

export const MindfulnessBreakWrap = styled.div`
  padding: 85px 30px 120px;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 25px;
  }
  align-items: flex-start;
  height: 100vh;
`;
