import { FC } from 'react';
import FormInput from 'common/formInput/FormInput';
import Tooltip from 'stepComponents/tooltip/Tooltip';
import { useCvBuilder } from 'utils/CvBuilderContext';
import Description from 'stepComponents/description/Description';
import {
  AddSectionIconWrap, StepHeader, StepWrap, AddItemButton, RemoveItemButton, ItemWrap
} from 'pages/cvBuilder/steps/styles';
import { ReactComponent as PlusIcon } from 'assets/img/plus_icon.svg';

export type PersonalSkillsSectionType = {
  skillName: string;
  description: string;
};

const PersonalSkills: FC = () => {
  const {
    values: { personalSkills },
    handleChange,
    handleAddClick,
    handleRemoveClick
  } = useCvBuilder();

  return (
    <StepWrap>
      <StepHeader isDescription>
        Add your personal skills
      </StepHeader>
      <Description
        className="body16Regular"
        content={'Next you can add your personal skills. These are also sometimes called transferable skills. Personal '
        + 'skills employers value include positive attitude, communication, teamwork, self-management, and willingness'
        + ' to learn.'}
      />
      {personalSkills.map(({ skillName, description }, i) => (
        <ItemWrap key={`skill-${i + 1}`}>
          <RemoveItemButton
            onClick={() => handleRemoveClick('personalSkills', i)}
          >
            Remove
          </RemoveItemButton>
          <FormInput
            fullWidth
            variant="outlined"
            name="skillName"
            label="Type your skill here"
            value={skillName}
            onChange={e => handleChange('personalSkills')(e, i)}
          />
          <FormInput
            fullWidth
            variant="outlined"
            name="description"
            label="Describe your skill here"
            value={description}
            onChange={e => handleChange('personalSkills')(e, i)}
          />
        </ItemWrap>
      ))}
      <AddItemButton
        onClick={() => handleAddClick('personalSkills')}
      >
        <AddSectionIconWrap>
          <PlusIcon />
        </AddSectionIconWrap>
        Add personal skill
      </AddItemButton>
      <Tooltip
        closeButton
        backgroundColor="primary_aquamarine"
        title="Tip"
        content={'Provide specific examples of ways you have demonstrated good communication skills. For example, say: '
        + "'Tutored students in math, which required communicating complex ideas in ways students could relate to "
        + "and understand'"}
      />
    </StepWrap>
  );
};

export default PersonalSkills;
