import React from 'react';
import { CircleWithCharWrap } from './CircleWithCharStyles';

export type CircleWithCharPropsType = {
  char: string | number;
  backgroundColor?: string;
  diameter?: number;
};

function CircleWithChar({
  char, backgroundColor, diameter
}: CircleWithCharPropsType): JSX.Element {
  return (
    <CircleWithCharWrap
      backgroundColor={backgroundColor}
      diameter={diameter}
    >{char}
    </CircleWithCharWrap>
  );
}

export default CircleWithChar;
