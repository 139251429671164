import styled from 'styled-components';
import { colors } from 'common/colors';

export const DropdownListWrap = styled.div<{
  isOpen: boolean
}>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 15px 2px;
  border: ${props => (props.isOpen ? `1px solid ${colors.primary_indigo}` : `1px solid ${colors.primary_gray_light2}`)};
  box-shadow: ${props => (props.isOpen ? '0 4px 9px rgba(54, 52, 163, 0.3)' : 'none')};
  background-color: ${colors.primary_white};
  border-radius: 8px;
  width: 100%;
  &:hover {
    border: 1px solid ${colors.primary_indigo};
    box-shadow: 0 4px 9px rgba(54, 52, 163, 0.3);
  }
  
  .dropdown-list-item {
    width: 100%;
    padding: 10px 0;
    &:hover {
      background-color: ${colors.primary_gray_light5};
    }
  }
`;

export const DropdownLabel = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  width: 100%;
  .dropdown-label {
    grid-column: 1 / 9;
    padding-bottom: 10px;
  }
  .dropdown-icon {
    grid-column: 10 / 10;
  }
`;
