import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors } from 'common/colors';

export const MenuButtonWrapAsRouterLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.primary_black};
  opacity: 0.5;
  user-select: none;
  
  svg {
    height: 22px;
    width: 22px;
  }
  
  &:active, &.focus, &.active {
    opacity: 1;
  }
  
  &.profile {
    svg {
      fill: ${colors.primary_gray3}
    }
  }
`;
