import { FC } from 'react';
import Description from 'stepComponents/description/Description';
import { StepWrap, StepHeader } from '../styles';

const WorkExperiencePreview: FC = () => (
  <StepWrap>
    <StepHeader isDescription>
      Work Experience
    </StepHeader>
    <Description
      className="body16Regular"
      content="Next, you will enter information about your paid and volunteer work experiences."
    />
  </StepWrap>
);

export default WorkExperiencePreview;
