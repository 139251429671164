import { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  QuoteWrap, QuoteMark, Content, Author
} from './QuoteStyles';

export type QuotePropTypes = {
  content: string;
  author: string;
};

const Quote: FunctionComponent<QuotePropTypes> = ({
  content,
  author
}) => (
  <QuoteWrap>
    <QuoteMark>“</QuoteMark>
    <Content>
      <ReactMarkdown>{content}</ReactMarkdown>
    </Content>
    <Author>- {author}</Author>
  </QuoteWrap>
);

export default Quote;
