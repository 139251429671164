import { ChangeEvent, SyntheticEvent, useState } from 'react';

export type FormValuesType = {
  [inputName: string]: string;
};

type ErrorsType = FormValuesType;

type TouchedType = {
  [inputName: string]: boolean;
};

type UseFormType = (initialValues: FormValuesType) => {
  values: FormValuesType;
  errors: ErrorsType;
  touched: TouchedType;
  handleInputChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onSubmit: (handleSubmit: () => void) => (event: SyntheticEvent) => Promise<void>;
};

const emailValidation = (email: string) => {
  if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    return null;
  }
  if (email.trim() === '') {
    return 'Email is required';
  }
  if (email.trim().length < 6) {
    return 'Email needs to be at least six characters';
  }
  return 'Please enter valid email';
};

const passwordValidation = (password: string) => {
  if (password.trim() === '') {
    return 'Password is required';
  }
  if (password.trim().length < 6) {
    return 'Password needs to be at least six characters';
  }
  return null;
};

const validate = {
  email: emailValidation,
  password: passwordValidation
};

const useForm: UseFormType = (initialValues) => {
  const [values, setValues] = useState<FormValuesType>(initialValues);
  const [errors, setErrors] = useState<ErrorsType>({});
  const [touched, setTouched] = useState<TouchedType>({});

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues(values => ({
      ...values,
      [name]: value
    }));
    setTouched(prevTouched => ({
      ...prevTouched,
      [name]: true
    }));
  };

  const onSubmit = (handleSubmit: () => void) => async (event: SyntheticEvent) => {
    event.preventDefault();
    const formValidation = Object.keys(values).reduce(
      (acc, key) => {
        const newError = validate[key as keyof typeof validate](values[key]);
        if (newError === null && acc.touched[key]) {
          delete acc.errors[key];
        }
        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched
          },
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched }
      }
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (!Object.values(formValidation.errors).length
      && Object.values(formValidation.touched).length === Object.values(values).length
      && Object.values(formValidation.touched).every(t => t)
    ) {
      await handleSubmit();
    }
  };

  return {
    values,
    errors,
    touched,
    handleInputChange,
    onSubmit
  };
};

export default useForm;
