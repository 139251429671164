import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import ProgressBar from 'common/progressBar/ProgressBar';
import AppTopicsList from 'common/appTopicsList/AppTopicsList';
import InfoCard from 'common/infoCard/InfoCard';
import { colors } from 'common/colors';
import WithNavigation from 'pages/courseControler/components/navigation/WithNavigation';
import useScrollToTop from 'utils/useScrollToTop';

import {
  getUserStatistics,
  calculateProgressBasedOnSteps
} from 'pages/courseControler/components/userProgress/userProgressHelper';
import { getAllStepsInPathway } from 'pages/courseControler/components/navigation/withNavigationHelper';
import {
  CourseEntryWrap,
  LabelWrap,
  InfoCardContent,
  InfoCardWrap,
} from './CourseEntryStyles';

const CourseEntry: FC = () => {
  const lSData = localStorage.getItem('currentTreadData');
  const {
    pathwayName,
    name,
    courseOrder,
    content,
    topics,
    badges
  } = JSON.parse(lSData || '{name: \'fallback Topic\'}');
  const ref = useScrollToTop(name);
  const { stepsFinishedInPathway } = getUserStatistics();
  const stepsFinishedInCourse = stepsFinishedInPathway.filter(data => data.courseName === name).length;
  const allStepsInCourse = getAllStepsInPathway().filter(step => step.courseName === name).length;
  return (
    <WithNavigation level="course">
      <CourseEntryWrap ref={ref}>
        <LabelWrap className="labelMobile12Bold">
          Pathways / {pathwayName} / {name}
        </LabelWrap>
        <span className="step-header body16Bold">Course {courseOrder + 1}</span>
        <span className="h4 course-name-header">{name}</span>
        <ProgressBar
          progress={calculateProgressBasedOnSteps(allStepsInCourse, stepsFinishedInCourse)}
          label="PROGRESS"
        />
        <span className="h5 content-heading">About the course</span>
        <span className="body16Regular">
          <ReactMarkdown>
            {content}
          </ReactMarkdown>
        </span>
        <span className="h5 content-heading">Topics:</span>
        <AppTopicsList topics={topics} />
        <InfoCardWrap>
          {badges && (
            <InfoCard
              variant="badge"
              circleBackgroundColor={colors.primary_white}
              badgeLevel={badges.level}
              badgeType={badges.type}
              backgroundColor={colors.primary_aquamarine}
            >
              <InfoCardContent>
                Complete all topics to earn a
                <span className="body16Bold">Badge of {badges.type}</span>
                {name} {badges.level}.
                <p className="next-info">Click <span className="body16Bold">&apos;Next&apos;</span> to continue</p>
              </InfoCardContent>
            </InfoCard>
          )}
        </InfoCardWrap>
      </CourseEntryWrap>
    </WithNavigation>
  );
};

export default CourseEntry;
