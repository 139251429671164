import React from 'react';
import { ReactComponent as BadgesIcon } from 'assets/img/footermenu-icons/badges-icon.svg';
import { MenuButtonWrapAsRouterLink } from 'pages/main/footer-menu/menu-buttons/MenuButtonStyles';

function MenuButtonBadges(): JSX.Element {
  return (
    <MenuButtonWrapAsRouterLink to="/badges">
      <BadgesIcon />
      <span className="label12Bold">Badges</span>
    </MenuButtonWrapAsRouterLink>
  );
}

export default MenuButtonBadges;
