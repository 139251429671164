import { FC } from 'react';
import { ItemType } from 'stepComponents/dndLetters/useDndLetters';
import { useDroppable } from '@dnd-kit/core';
import LetterBox from 'stepComponents/dndLetters/letterBox/LetterBox';
import { LetterBoxContainerWrap } from './LetterBoxContainerStyles';

type LetterBoxContainerPropsType = {
  letterItem: ItemType;
};

const LetterBoxContainer: FC<LetterBoxContainerPropsType> = ({ letterItem }) => {
  const { setNodeRef } = useDroppable({ id: letterItem.id as string });

  return (
    <LetterBoxContainerWrap ref={setNodeRef}>
      {letterItem.currentValue && (
        <LetterBox
          id={letterItem.id as string}
          letter={letterItem.currentValue as string}
        />
      )}
    </LetterBoxContainerWrap>
  );
};

export default LetterBoxContainer;
