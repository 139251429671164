import {
  FC,
  ReactNode,
  useEffect
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_SINGLE_PATHWAY_QUERY } from 'utils/gqlQueries';
import AppSpinner from 'common/appSpinner/AppSpinner';
import { usePathway } from 'utils/PathwayContext';
import { useHistory } from 'react-router-dom';
import Header from 'pages/header/Header';
import CourseNavigation from './CourseNavigation';
import {
  getLastCompletedElementInPathwayIndex,
  saveUserProgress,
  setUserProgressData,
  closeCollectingDataFromInteraction
} from '../userProgress/userProgressHelper';
import { navigationSingleLevelTread } from './withNavigationHelper';

export type WithNavigationLevelPropsType = 'pathway' | 'course' | 'topic' | 'step' |
  'completedKnowledgeBite' | 'completedTopic' | 'completedCourse' | 'completedPathway';

type WithNavigationPropsType = {
  level: WithNavigationLevelPropsType
  children: ReactNode
};

const WithNavigation: FC<WithNavigationPropsType> = ({
  level,
  children
}) => {
  const pathwayId = localStorage.getItem('pathwayId') || '';
  const [queryExecutor, {
    loading,
    data,
    error
  }] = useLazyQuery(GET_SINGLE_PATHWAY_QUERY(pathwayId));
  const [pathway, setPathway] = usePathway();
  const history = useHistory();
  let tempTread = Number(localStorage.getItem('currentTread') || '0');

  useEffect(() => {
    if (pathwayId) {
      queryExecutor();
    }
  }, [pathwayId]);

  useEffect(() => {
    if (!loading && data && data.path) {
      setPathway(data.path);
    }
  }, [data, loading]);

  useEffect(() => {
    localStorage.setItem('pathway', JSON.stringify(pathway));
  }, [pathway]);

  useEffect(() => {
    setUserProgressData(tempTread);
  }, [tempTread]);

  const navSingleThread = navigationSingleLevelTread(pathway);

  // TODO make it useMemo
  localStorage.setItem('singleLevelThread', JSON.stringify(navSingleThread));

  function increase() {
    if (!pathwayId || tempTread >= navSingleThread.length - 1) {
      closeCollectingDataFromInteraction(tempTread);
      saveUserProgress();
      return history.push('/');
    }
    const { dataLevel: nextDataLevel, id: nextId } = navSingleThread[tempTread + 1];
    closeCollectingDataFromInteraction(tempTread);
    saveUserProgress();
    localStorage.setItem('currentTreadData', JSON.stringify(navSingleThread[tempTread + 1]));
    localStorage.setItem('currentTread', `${tempTread + 1}`);
    history.push(`/${nextDataLevel}/${nextId}`);
  }

  function decrease() {
    if (!tempTread) return undefined;
    const { dataLevel: prevDataLevel, id: prevId } = navSingleThread[tempTread - 1];
    closeCollectingDataFromInteraction(tempTread);
    saveUserProgress();
    localStorage.setItem('currentTreadData', JSON.stringify(navSingleThread[tempTread - 1]));
    localStorage.setItem('currentTread', `${tempTread - 1}`);
    history.push(`/${prevDataLevel}/${prevId}`);
  }

  const lastCompletedElementIndex = getLastCompletedElementInPathwayIndex(pathwayId);
  if (level === 'pathway' && lastCompletedElementIndex > 0) {
    tempTread = lastCompletedElementIndex;
  }

  function goToLastCompleted() {
    const { dataLevel, id } = navSingleThread[tempTread];
    localStorage.setItem('currentTreadData', JSON.stringify(navSingleThread[tempTread]));
    localStorage.setItem('currentTread', `${tempTread}`);
    history.push(`/${dataLevel}/${id}`);
  }

  const navHandler = {
    increase: {
      pathway: {
        action: (tempTread === 0) ? increase : goToLastCompleted,
        label: (tempTread === 0) ? 'Start Learning' : 'Continue Learning'
      },
      course: {
        action: increase,
        label: undefined
      },
      topic: {
        action: increase,
        label: undefined
      },
      step: {
        action: increase,
        label: pathwayId ? undefined : 'Close'
      },
      completedKnowledgeBite: {
        action: increase,
        label: 'Continue'
      },
      completedTopic: {
        action: increase,
        label: 'Unlock new Topic'
      },
      completedCourse: {
        action: increase,
        label: 'Unlock new Course'
      },
      completedPathway: {
        action: increase,
        label: 'Unlock new Pathway'
      }
    },
    decrease: {
      pathway: {
        action: undefined,
        label: undefined
      },
      course: {
        action: decrease,
        label: undefined
      },
      topic: {
        action: decrease,
        label: undefined
      },
      step: {
        action: pathwayId ? decrease : undefined,
        label: undefined
      },
      completedKnowledgeBite: {
        action: decrease,
        label: undefined
      },
      completedTopic: {
        action: decrease,
        label: undefined
      },
      completedCourse: {
        action: decrease,
        label: undefined
      },
      completedPathway: {
        action: decrease,
        label: undefined
      }
    }
  };

  if (error) return <div>Error occurred!</div>;

  return (
    <>
      {
        loading || !pathway ? <AppSpinner />
          : (
            <>
              <>{children}</>
              <CourseNavigation
                increase={navHandler.increase[level].action}
                decrease={navHandler.decrease[level].action}
                increaseLabel={navHandler.increase[level].label}
                decreaseLabel={navHandler.decrease[level].label}
              />
              <Header />
            </>
          )
      }
    </>
  );
};

export default WithNavigation;
