import { ReactComponent as HeroesIcon } from 'assets/img/heroes.svg';
import FormInput from 'common/formInput/FormInput';
import Button from 'common/button/Button';
import DotsLoader from 'common/dotsLoader/DotsLoader';
import userApi from 'utils/userApi';
import {
 useState, SyntheticEvent, useEffect, FC
} from 'react';
import { useAuth } from 'utils/AuthContext';
import useForm from 'utils/useForm';
import { useSnackbar } from 'utils/SnackbarContext';
import { useHistory } from 'react-router-dom';
import {
 StyledForm, StyledLink, ErrorWrap, Header, Wrapper
} from 'common/authStyles/AuthStyles';

const ForgotPassword: FC = () => {
  const history = useHistory();
  const { requestError, setRequestError } = useAuth();
  const { openSnackbar } = useSnackbar();
  const [isResetInProgress, setResetInProgress] = useState(false);
  const {
    values,
    handleInputChange
  } = useForm({
    email: '',
  });

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setRequestError('');
    setResetInProgress(true);
    try {
      await userApi.remindPassword(values.email);
      openSnackbar({ message: 'A reset password email has been sent!' });
      history.push('/');
    } catch (err) {
      setRequestError(err.message);
    } finally {
      setResetInProgress(false);
    }
  };

  useEffect(() => () => setRequestError(''), []);

  return (
    <Wrapper>
      <HeroesIcon />
      <Header isRequestError={!!requestError}>Forgot password</Header>
      {requestError ? <ErrorWrap>{requestError}</ErrorWrap> : null}
      <StyledForm onSubmit={handleSubmit}>
        <FormInput
          fullWidth
          variant="outlined"
          name="email"
          type="email"
          label="Email"
          placeholder="Email"
          onChange={handleInputChange}
          value={values.email}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
        >
          {isResetInProgress ? <DotsLoader /> : 'Reset password'}
        </Button>
      </StyledForm>
      <StyledLink to="/">Back</StyledLink>
    </Wrapper>
  );
};

export default ForgotPassword;
