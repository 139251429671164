import React from 'react';
import AppButton from 'common/appButton/AppButton';
import { CourseNavigationWrap } from './CourseNavigationStyles';

type CourseNavigationPropTypes = {
  increase?: (payload: unknown) => unknown;
  decrease?: (payload: unknown) => unknown;
  decreaseLabel?: string;
  increaseLabel?: string;
};

const CourseNavigation: React.FC<CourseNavigationPropTypes> = ({
  increase,
  decrease,
  increaseLabel,
  decreaseLabel
}: CourseNavigationPropTypes) => (
  <CourseNavigationWrap gridTemplateColumns2={!!increase && !!decrease}>
    { decrease
      ? (
        <AppButton
          text={decreaseLabel || 'Back'}
          option="outline"
          onButtonClick={decrease}
        />
      ) : null}
    { increase
    ? (
      <AppButton
        text={increaseLabel || 'Next'}
        option="fill"
        onButtonClick={increase}
      />
     ) : null}

  </CourseNavigationWrap>
);

export default CourseNavigation;
