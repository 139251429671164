import { FC } from 'react';
import { DndContext } from '@dnd-kit/core';
import List from 'stepComponents/dnd/list/List';
import UnassignedList from 'stepComponents/dnd/list/UnassignedList';
import useDragNDrop from 'stepComponents/dnd/useDragNDrop';
import { ListsWrap, DnDWrap } from './DragNDropStyles';

export type DragNDropPropsType = {
  dndItems: { name: string }[];
  containerNames: { name: string }[];
};

const DragNDrop: FC<DragNDropPropsType> = ({ dndItems: rootItems, containerNames }) => {
  const {
    items,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd
  } = useDragNDrop(rootItems);

  return (
    <DnDWrap>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <UnassignedList
          id="root"
          items={items.root}
        />
        <ListsWrap>
          <List
            id="container1"
            title={containerNames[0].name}
            items={items.container1}
          />
          { containerNames[1] && (
          <List
            id="container2"
            title={containerNames[1].name}
            items={items.container2}
          />
)}
        </ListsWrap>
      </DndContext>
    </DnDWrap>
  );
};

export default DragNDrop;
