import { Course } from './Course';
import { MindfulnessBreakInterface } from './pathway.interface';

export default class Pathway {
  name: string;

  content: string;

  id: string;

  mindfulness_break: MindfulnessBreakInterface;

  quote: {
    content: string;
    author: string
  };

  image: {
    name: string;
    url: string;
  };

  courses: Course[];

  certificate: {
    id: string;
    name: string;
    content: string;
  };

  constructor(
    id: string,
    name: string,
    content?: string,
    quote?: {
      content: string;
      author: string
    },
    courses?: Course[],
    certificate? : {
      id: string;
      name: string;
      content: string;
    },
    image? : {
      name: string;
      url: string;
    },
    mindfulnessBreak?: MindfulnessBreakInterface
  ) {
    this.id = id || 'noId';
    this.name = name;
    this.content = content || 'new content';
    this.quote = quote || { content: 'new quote', author: 'unknown' };
    this.courses = courses || [new Course('newCourse')];
    this.certificate = certificate || { id: '', name: 'new cert', content: 'new content' };
    this.image = image || { name: 'img', url: '' };
    this.mindfulness_break = mindfulnessBreak || { description: undefined, videoUrl: undefined };
  }
}
