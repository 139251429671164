import { TextField, withStyles } from '@material-ui/core';
import { colors } from 'common/colors';

const FormInput = withStyles({
  root: {
    marginBottom: '20px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      borderWidth: '1px',
      backgroundColor: colors.primary_white,
      '&.Mui-focused': {
        boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.1)'
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primary_indigo,
        borderWidth: '1px'
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    }
  }
})(TextField);

export default FormInput;
