import { FC } from 'react';
import { DotsLoaderWrap, Dot } from './DotsLoaderStyles';

const DotsLoader: FC = () => (
  <DotsLoaderWrap>
    <Dot />
    <Dot />
    <Dot />
  </DotsLoaderWrap>
);

export default DotsLoader;
