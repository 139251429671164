import styled from 'styled-components';
import { colors } from 'common/colors';

export const CoursesTabWrap = styled.div<{
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0 0;
`;

export const CoursesListWrap = styled.div`
  width: 100%;
  margin-top: 45px;
`;

export const ListHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .total {
    color: ${colors.primary_gray4};
  }
`;

export const CourseCardWrap = styled.div`
  background-color: ${colors.primary_white};
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  margin-bottom: 15px;
  .is-completed { 
    grid-column: 1 / 2;
    height: 24px;
    width: 24px;
  }
  .title-and-progress {
    grid-column: 1 / 7;
    padding: 0;
    
  }
  .play-icon {
    padding: 0;
    grid-column: 7 / 9;
    align-self: center;
    justify-self: center;
  }
  
  .dropdown-icon {
    padding: 0;
    grid-column: 9 / 11;
    align-self: center;
    justify-self: center;
  }
  
  h5 {
    margin: 15px 0;
    grid-column: 1 / 11;
  }
`;

export const ScoresWrap = styled.div`
  grid-column: 1 / 11;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid ${colors.primary_gray_light1};
`;
