import React from 'react';
import { ReactComponent as PathwaysIcon } from 'assets/img/footermenu-icons/pathways-icon.svg';
import { MenuButtonWrapAsRouterLink } from './MenuButtonStyles';

function MenuButtonPathways(): JSX.Element {
  return (
    <MenuButtonWrapAsRouterLink exact to="/">
      <PathwaysIcon />
      <span className="label12Bold">Pathways</span>
    </MenuButtonWrapAsRouterLink>
  );
}

export default MenuButtonPathways;
