import { FC } from 'react';
import { ApolloProvider } from '@apollo/client';
import { client as apolloClient } from 'config/apolloClientConfig';
import { PathProvider } from 'utils/PathContext';
import { CvBuilderProvider } from 'utils/CvBuilderContext';
import { AuthProvider } from 'utils/AuthContext';
import { PathwayProvider } from 'utils/PathwayContext';
import { SnackbarProvider } from 'utils/SnackbarContext';
import { BrowserRouter as Router } from 'react-router-dom';

const Providers: FC = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    <AuthProvider>
      <SnackbarProvider>
        <PathProvider>
          <PathwayProvider>
            <CvBuilderProvider>
              <Router>
                {children}
              </Router>
            </CvBuilderProvider>
          </PathwayProvider>
        </PathProvider>
      </SnackbarProvider>
    </AuthProvider>
  </ApolloProvider>
);

export default Providers;
