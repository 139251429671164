import { FC } from 'react';
import CircleWithChar from 'common/circleWithChar/CircleWithChar';
import { useHistory } from 'react-router-dom';
import AppButton from 'common/appButton/AppButton';
import {
  WhatsNextWrap, Header, BreakButton, Instruction
} from './WhatsNextStyles';

type Props = {
  variant: 'completedKnowledgeBite' | 'completedTopic' | 'completedCourse' | 'completedPathway';
  parentId: string,
  mindfulnessBreak?: { description: string, videoUrl: string, image: { name: string, url: string } }
};

const instructionMap = {
  completedKnowledgeBite: 'Continue',
  completedTopic: 'Unlock new Topic',
  completedCourse: 'Unlock new Course',
  completedPathway: 'Unlock new Pathway'
};

const WhatsNext: FC<Props> = ({ variant, parentId, mindfulnessBreak }) => {
  const history = useHistory();
  const isMindfulnessBreak = mindfulnessBreak && (mindfulnessBreak.description || mindfulnessBreak.videoUrl);
  function handleMindfulnessBreak(): void {
    if (!mindfulnessBreak || !(mindfulnessBreak.description || mindfulnessBreak.videoUrl || mindfulnessBreak.image)) {
      return;
    }
    history.push({
      pathname: `/mindfulness-break/${parentId}`,
      state: {
        navigationLevel: variant,
        description: mindfulnessBreak?.description,
        videoUrl: mindfulnessBreak?.videoUrl,
        image: mindfulnessBreak?.image
      }
    });
  }

  return (
    <WhatsNextWrap>
      <Header>
        <CircleWithChar char="?" />
        <h4 className="label">What&apos;s next?</h4>
      </Header>
      {
        isMindfulnessBreak && (
          <>
            <BreakButton onClick={handleMindfulnessBreak}>
              <AppButton text="Have a mindfulness break" option="outline" />
            </BreakButton>
            <p>or</p>
          </>
        )
      }

      <Instruction>
        click <b>&apos;{instructionMap[variant]}&apos;</b><br />to continue learning.
      </Instruction>
    </WhatsNextWrap>
  );
};

export default WhatsNext;
