import React, { FC, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { StepComponentInterface } from 'pages/pathway/pathway.interface';
import {
  AppTabsWrap,
  TabsStyled,
  TabStyled,
  BoxStyled,
  TypographyStyled
} from './AppTabsStyles';

type AppTabsPropsType = {
  tabs: { name: string, content: React.ReactNode, hidden?: boolean }[]
};

export type AppTabsStepVariantPropsType = {
  tabs: { name: string; content: StepComponentInterface[]; }[]
};

type TabPanelPropsType = {
  children: React.ReactNode;
  value: number;
  index: number;
};

const TabPanel: FC<TabPanelPropsType> = ({
   children,
   value,
   index
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
  >
    {value === index && (
      <BoxStyled p={3}>
        <TypographyStyled component="div">{children}</TypographyStyled>
      </BoxStyled>
    )}
  </div>
);

const AppTabs: FC<AppTabsPropsType> = ({
                   tabs
                 }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<unknown>, newActiveTabIndex: number) => {
    setActiveTabIndex(newActiveTabIndex);
  };
  const handleTabIndexChange = (index: number) => setActiveTabIndex(index);

  return (
    <AppTabsWrap>
      <TabsStyled
        TabIndicatorProps={{ children: <span /> }}
        value={activeTabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
      >
        {tabs.filter(tab => !tab.hidden).map((tab) => <TabStyled label={tab.name} key={tab.name} />)}
      </TabsStyled>
      <SwipeableViews
        axis="x"
        index={activeTabIndex}
        onChangeIndex={handleTabIndexChange}
      >
        {tabs.map((tab, index: number) => (
          <TabPanel
            value={activeTabIndex}
            index={index}
            key={tab.name}
          >
            {tab.content}
          </TabPanel>
        ))}
      </SwipeableViews>
    </AppTabsWrap>
  );
};

export default AppTabs;
