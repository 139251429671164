import styled from 'styled-components';
import { colors } from 'common/colors';

export const PathwayCardWrap = styled.div<{
  isLocked: boolean;
}>`
  cursor: pointer;
  opacity: ${props => (props.isLocked ? 0.6 : 1)};
  margin: 15px 0;
  background-color: ${colors.primary_white};
  padding: 15px;
  border-radius: 8px;
  border: 1px solid ${colors.primary_gray_light2};
  .imgContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 125px;
    }
  }
  .activities {
    display: flex;
  }
`;
