import { FC } from 'react';
import { Video, Image } from 'stepComponents';
import { useLocation } from 'react-router-dom';
import WithNavigation, {
  WithNavigationLevelPropsType,
} from 'pages/courseControler/components/navigation/WithNavigation';
import { MindfulnessBreakWrap } from './MindfulnessBreakStyles';

type MindfulnessBreakPropsType = {
  navigationLevel: WithNavigationLevelPropsType;
  description?: string;
  videoUrl?: string;
  image?: {
    name: string;
    url: string;
  };
};

const MindfulnessBreak: FC = () => {
  const locationData = useLocation();
  const data = locationData.state as MindfulnessBreakPropsType;
  const {
 navigationLevel, description, videoUrl, image
} = data;

  return (
    <WithNavigation level={navigationLevel}>
      <MindfulnessBreakWrap>
        <div className="h3">Mindfulness break</div>
        {description && <div className="body16Regular">{description}</div>}

        {videoUrl && (
          <>
            <div className="h5">Your mindfulness video:</div>
            <Video url={videoUrl} />
          </>
        )}
        {image && <Image image={image} name={image.name} />}

      </MindfulnessBreakWrap>
    </WithNavigation>
  );
};

export default MindfulnessBreak;
