import { FC, useEffect, useState } from 'react';
import WhatsNext from 'stepComponents/whatsNext/WhatsNext';
import { ReactComponent as CertificateIcon } from 'assets/img/certificate_icon.svg';
import userApi from 'utils/userApi';
import { useAuth, UserType } from 'utils/AuthContext';
import AppSpinner from 'common/appSpinner/AppSpinner';
import WithNavigation from '../navigation/WithNavigation';
import {
  CompletedPathWrap, CertificateCard, GifContainer, Gif
} from './CompletedPathStyles';

const CompletedPath: FC = () => {
  const lSData = localStorage.getItem('currentTreadData');
  const {
    id,
    certificate,
    name,
    gifUrl,
    mindfulnessBreak
  } = JSON.parse(lSData || '{name: \'fallback Course\'}');
  const [isAddingCertificateInProgress, setIsAddingCertificateInProgress] = useState<boolean>(false);
  const { user, setUser } = useAuth();

  const handleAddCertificate = async () => {
    if (user) {
      try {
        setIsAddingCertificateInProgress(true);
        const res = await userApi.addCertificate({ certificateId: certificate.id });
        if (res.certificates) {
          const updatedUser: UserType = {
            ...user,
            certificates: res.certificates.map(certificate => certificate.id)
          };
          setUser(updatedUser);
        }
      } catch (err) {
        throw new Error(err);
      } finally {
        setIsAddingCertificateInProgress(false);
      }
    }
  };

  useEffect(() => {
    if (
      certificate
      && certificate.id
      && user?.certificates
      && user?.certificates.findIndex(certificateId => certificateId === certificate.id) === -1
    ) {
      handleAddCertificate();
    }
  }, []);

  return (
    <WithNavigation level="completedPathway">
      <CompletedPathWrap>
        { isAddingCertificateInProgress ? <AppSpinner /> : (
          <>
            <h3>Awesome!</h3>
            <h4>You&apos;ve completed {name}!</h4>
            {certificate && (
              <CertificateCard>
                <h4 className="badge-info">You have earned a<br />Certificate of<br />{certificate.name}!</h4>
                <CertificateIcon />
              </CertificateCard>
            )}
            {gifUrl ? (
              <GifContainer>
                <Gif src={gifUrl} alt="gif" />
              </GifContainer>
            ) : null}
            <WhatsNext variant="completedPathway" parentId={id} mindfulnessBreak={mindfulnessBreak} />
          </>
        )}
      </CompletedPathWrap>
    </WithNavigation>
  );
};

export default CompletedPath;
