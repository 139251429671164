import { FC } from 'react';
import { ReactComponent as LockedButton } from 'assets/img/locked_button.svg';
import downloadFile from 'utils/downloadFile';
import { usePDF } from '@react-pdf/renderer';
import CertificateTemplate from 'pages/certificates/CertificateTemplate';
import { useAuth } from 'utils/AuthContext';
import { parseFullName } from 'utils/parseFullName';
import { CertificateCardWrap, CertificateNameWrap, IconWrap } from './CertificateCardStyles';

type CertificateCardPropsType = {
  isLocked?: boolean;
  pathName?: string;
  certName: string;
};

const CertificateCard: FC<CertificateCardPropsType> = ({
  isLocked,
  certName,
  pathName
}) => {
  const { user } = useAuth();
  const [instance] = usePDF({
    document: CertificateTemplate({
      certName: certName.split('Certificate of')[1] || '',
      pathName: pathName || 'Pathway Name',
      username: parseFullName(user)
    })
  });

  const handleClick = () => {
    if (!instance.loading && !instance.error && instance.url) {
      downloadFile(instance.url, 'certificate.pdf');
    }
  };

  return (
    <CertificateCardWrap>
      <CertificateNameWrap
        onClick={!isLocked ? handleClick : undefined}
      >
        <span>{certName}</span>
      </CertificateNameWrap>
      {isLocked && (
        <IconWrap>
          <LockedButton />
        </IconWrap>
      )}
    </CertificateCardWrap>
  );
};

export default CertificateCard;
