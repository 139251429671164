import { FC } from 'react';
import { ItemType } from 'stepComponents/dndLetters/useDndLetters';
import { useDroppable } from '@dnd-kit/core';
import { ReactComponent as CorrectIcon } from 'assets/img/correct_icon_filled.svg';
import { ReactComponent as IncorrectIcon } from 'assets/img/incorrect_icon_filled.svg';
import LetterBox from '../letterBox/LetterBox';
import {
  CardContent, CardTextWrap, CardWrap, IconWrap
} from './LetterCardStyles';

type LetterCardPropsType = {
  id: string;
  item: ItemType;
};

const LetterCard: FC<LetterCardPropsType> = ({ id, item }) => {
  const { isOver, setNodeRef } = useDroppable({ id });
  const icon = item.currentValue
    && (item.currentValue === item.letter ? <CorrectIcon /> : <IncorrectIcon />);

  return (
    <CardWrap isItemOver={isOver} ref={setNodeRef}>
      <CardContent hasLetter={Boolean(item.currentValue)}>
        {item.currentValue ? (
          <LetterBox
            id={item.id as string}
            letter={item.currentValue as string}
          />
        ) : null}
        <IconWrap>
          {icon}
        </IconWrap>
      </CardContent>
      <CardTextWrap>
        <h5>{item.title}</h5>
        <span className="body16Regular">{item.description}</span>
      </CardTextWrap>
    </CardWrap>
  );
};

export default LetterCard;
