import React, { useState } from 'react';
import InfoCard from 'common/infoCard/InfoCard';
import { colors } from 'common/colors';
import { useHistory } from 'react-router-dom';
import { Course } from 'pages/pathway/Course';
import ProgressBar from 'common/progressBar/ProgressBar';
import { ReactComponent as PlayIcon } from 'assets/img/play_icon.svg';
import { ReactComponent as DisabledPlayIcon } from 'assets/img/disabled_icon.svg';
import { ReactComponent as OpenDropdown } from 'assets/img/open_dropdown_icon.svg';
import { ReactComponent as CloseDropdown } from 'assets/img/close_dropdown_icon.svg';
import { ReactComponent as CorrectIcon } from 'assets/img/correct_icon_filled.svg';
import DoneElementsDisplay from 'pages/courseControler/components/doneElementsDisplay/DoneElementsDisplay';
import { Topic } from 'pages/pathway/Topic';
import AppTopicsList from 'common/appTopicsList/AppTopicsList';
import { SingleLevelThreadPossibleTypes } from 'pages/courseControler/components/navigation/withNavigationHelper';
import { Certificate } from 'pages/pathway/pathway.interface';
import { usePathwayActions } from 'utils/PathwayContext';
import {
  CoursesTabWrap,
  CoursesListWrap,
  ListHeaderWrap,
  CourseCardWrap,
  ScoresWrap
} from './CoursesTabStyles';

type CourseTabPropsType = {
  certificate: Certificate
  courses: Course[],
  userStatistics: {
    completedCourses: { id: string; }[],
    completedTopics: { id: string; courseName?: string; }[],
    completedKnowledgeBites: { courseName?: string; id: string; }[],
    stepsFinishedInPathway: { id: string; courseName?: string; }[]
  }
};

type CourseCardPropsType = {
  name: string;
  isLocked: boolean;
  topics: Topic[];
  courseName: string;
  knowledgeBitesNumber: number;
  isCompleted: boolean;
  completedTopics: { id: string; courseName?: string; }[],
  completedStepsInCourse: number
  completedKnowledgeBites: number,
  stepsInCourse: number
};

const CourseCard: React.FC<CourseCardPropsType> = ({
                                                     name,
                                                     isLocked,
                                                     topics,
                                                     courseName,
                                                     knowledgeBitesNumber,
                                                     completedTopics,
                                                     isCompleted,
                                                     completedKnowledgeBites,
                                                     completedStepsInCourse,
                                                     stepsInCourse
                                                   }) => {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const singleLevelThread: SingleLevelThreadPossibleTypes[] = JSON.parse(
    localStorage.getItem('singleLevelThread') || '[]'
  );
  const courseProgress = Math.floor((completedStepsInCourse / stepsInCourse) * 100);
  const getCompletedTopicsInCourse = (courseName: string): number =>
    completedTopics.filter(obj => obj.courseName === courseName).length;
  const openCourse = (): void => {
    const courseIndex = singleLevelThread.findIndex((data) => data.dataLevel === 'course' && data.name === courseName);
    const courseData = singleLevelThread.find((data) => data.dataLevel === 'course' && data.name === courseName);
    if (courseIndex >= 0) {
      localStorage.setItem('currentTreadData', JSON.stringify(courseData));
      localStorage.setItem('currentTread', `${courseIndex}`);
      history.push(`/course/${courseName}`);
    }
  };
  return (
    <CourseCardWrap>
      { isCompleted && <CorrectIcon className="is-completed" />}
      <div className="title-and-progress">
        <span className="title">{name}</span>
        <ProgressBar progress={courseProgress} hideProgressNumber={true} />
      </div>
      <div className="play-icon">
        {isLocked
          ? <DisabledPlayIcon />
          : <PlayIcon onClick={() => openCourse()} />}
      </div>
      <div className="dropdown-icon">
        {isOpen
          ? <CloseDropdown onClick={() => setOpen(!isOpen)} />
          : <OpenDropdown onClick={() => setOpen(!isOpen)} />}
      </div>
      {isOpen && (
        <>
          <ScoresWrap>
            <DoneElementsDisplay label="Topics" earned={getCompletedTopicsInCourse(name)} max={topics.length} />
            <DoneElementsDisplay label="Knowledge bites" earned={completedKnowledgeBites} max={knowledgeBitesNumber} />
          </ScoresWrap>
          <h5>Topics:</h5>
          <AppTopicsList topics={topics} completedTopics={completedTopics} />
        </>
      )}
    </CourseCardWrap>
  );
};

const CoursesTab: React.FC<CourseTabPropsType> = ({
                                                    courses,
                                                    certificate,
                                                    userStatistics: {
                                                      completedCourses,
                                                      completedTopics,
                                                      completedKnowledgeBites,
                                                      stepsFinishedInPathway
                                                    }
                                                  }) => {
  const { knowledgeBitesPerCourse, stepsInCourse } = usePathwayActions();

  const getCompletedStepsInCourse = (courseName: string) =>
    stepsFinishedInPathway.filter(step => step.courseName === courseName).length;

  const getCompletedKBInCourse = (courseName: string): number =>
    completedKnowledgeBites.filter(obj => obj.courseName === courseName).length;

  const isCompleted = (id: string): boolean => completedCourses.findIndex(obj => obj.id === id) >= 0;
  return (
    <CoursesTabWrap>
      { certificate && (
        <InfoCard
          backgroundColor={colors.primary_aquamarine}
          circleBackgroundColor={colors.secondary_green}
          variant="certificate"
        >
          Complete all courses to earn a <div className="body16Bold">Certificate of {certificate.name}</div>
        </InfoCard>
      )}
      <CoursesListWrap>
        <ListHeaderWrap className="h5">
          Courses<span className="labelMobile12Bold total">Total: {courses.length}</span>
        </ListHeaderWrap>
        { courses.map((course: Course) => (
          <CourseCard
            name={course.name}
            isLocked={course.isLocked}
            key={course.name}
            topics={course.topics}
            completedTopics={completedTopics}
            isCompleted={isCompleted(course.id)}
            courseName={course.name}
            knowledgeBitesNumber={knowledgeBitesPerCourse(course.id)}
            stepsInCourse={stepsInCourse(course.id)}
            completedStepsInCourse={getCompletedStepsInCourse(course.name)}
            completedKnowledgeBites={getCompletedKBInCourse(course.name)}
          />
        ))}
      </CoursesListWrap>
    </CoursesTabWrap>
  );
};

export default CoursesTab;
