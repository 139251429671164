import { FC } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { Letter, LetterWrap } from './LetterBoxStyles';

type LetterBoxPropsType = {
  id: string;
  letter: string;
};

const LetterBox: FC<LetterBoxPropsType> = ({ id, letter }) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform
  } = useDraggable({ id, data: { value: letter } });

  const style = {
    touchAction: 'none',
    transform: CSS.Translate.toString(transform)
  };

  return (
    <LetterWrap
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Letter>
        {letter}
      </Letter>
    </LetterWrap>
  );
};

export default LetterBox;
