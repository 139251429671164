import { FC, useEffect } from 'react';
import StyledList from 'stepComponents/styledList/StyledList';
import Tooltip from 'stepComponents/tooltip/Tooltip';
import InfoCard from 'common/infoCard/InfoCard';
import { colors } from 'common/colors';
import useScrollToTop from 'utils/useScrollToTop';
import ProgressBar from 'common/progressBar/ProgressBar';
import WithNavigation from 'pages/courseControler/components/navigation/WithNavigation';
import {
  calculateProgressBasedOnSteps,
  getUserStatistics
} from 'pages/courseControler/components/userProgress/userProgressHelper';
import { usePathwayActions } from 'utils/PathwayContext';
import {
  EntranceWrap, TopicHeader, Description, TitledListWrap, InfoCardContent
} from './TopicEntranceStyles';

const TopicEntrance: FC = () => {
  let lSData = localStorage.getItem('currentTreadData');
  const {
    name,
    description,
    whatYouWillLearn,
    whatYouWillDo,
    badges,
    courseName,
    courseId,
    currentTopicOrder
  } = JSON.parse(lSData || '{name: \'fallback Topic\'}');
  const { stepsFinishedInPathway } = getUserStatistics();
  const { stepsInCourse } = usePathwayActions();
  const allStepsInCourse = stepsInCourse(courseId);
  const stepsFinishedInCourse = stepsFinishedInPathway.filter(step => step.courseName === courseName).length;

  useEffect(() => {
    lSData = localStorage.getItem('currentTreadData');
  }, [lSData]);
  const ref = useScrollToTop(name);
  return (
    <WithNavigation level="topic">
      <EntranceWrap ref={ref}>
        <ProgressBar progress={calculateProgressBasedOnSteps(allStepsInCourse, stepsFinishedInCourse)} />
        <TopicHeader>
          <span className="step-header">Topic {currentTopicOrder + 1}</span>
          <div className="h4 topic-name">{name}</div>
        </TopicHeader>
        <Description>{description}</Description>
        <TitledListWrap>
          <h5>What you will learn?</h5>
          <StyledList listItems={whatYouWillLearn} />
        </TitledListWrap>
        <TitledListWrap>
          <h5>What you will do?</h5>
          <StyledList listItems={whatYouWillDo} />
        </TitledListWrap>
        <Tooltip
          icon="happy_cyclops"
          iconPosition="right"
          content="Each knowledge bite only takes 5 - 10 minutes to complete"
        />
        <InfoCard
          variant="badge"
          badgeType={badges?.type}
          badgeLevel={badges?.level}
          backgroundColor={colors.primary_aquamarine}
          circleBackgroundColor={colors.primary_white}
        >
          <InfoCardContent className="body16Regular">
            Complete this topic to earn a<br />
            <b>Badge of {badges?.type}</b><br />
            {courseName} {badges?.level}<br />
          </InfoCardContent>
          <span>Click <b>&apos;Next&apos;</b> to continue</span>
        </InfoCard>
      </EntranceWrap>
    </WithNavigation>
  );
};

export default TopicEntrance;
