import { FC } from 'react';
import { useAuth } from 'utils/AuthContext';
import FormInput from 'common/formInput/FormInput';
import Button from 'common/button/Button';
import { ProfilePageWrap, Header, StyledLink } from './ProfilePageStyles';

const ProfilePage: FC = () => {
  const { user, logout } = useAuth();

  return (
    <ProfilePageWrap>
      <Header>Profile page</Header>
      <FormInput
        variant="outlined"
        fullWidth
        name="email"
        label="Email"
        value={user?.email || ''}
        disabled
      />
      <StyledLink to="/cv-builder">Visit CV Builder</StyledLink>
      <Button
        variant="contained"
        fullWidth
        onClick={logout}
      >
        Log out
      </Button>
    </ProfilePageWrap>
  );
};

export default ProfilePage;
