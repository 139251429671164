import { FunctionComponent } from 'react';
import { VideoWrap } from './VideoStyles';

export type VideoPropsType = {
  url: string;
};

const Video: FunctionComponent<VideoPropsType> = ({ url }) => (
  <VideoWrap>
    <iframe
      width="100%"
      src={url}
      title="Video player"
      allowFullScreen
    />
  </VideoWrap>
);

export default Video;
