import styled from 'styled-components';
import { colors } from 'common/colors';

export const RangeNumberedWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`;

export const Scale = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const NumberBox = styled.div<{
  isActive: boolean;
}>`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primary_gray1};
  padding: 12px 19px;
  border-radius: 8px;
  user-select: none;
  border: ${({ isActive }) => (
    isActive ? `1px solid ${colors.primary_indigo}` : `1px solid ${colors.primary_gray_light2}`
  )};
  ${({ isActive }) => isActive && `
    background-color: ${colors.primary_indigo_shade3};
  `}
`;

export const LabelsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Label = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primary_gray3};
  flex-grow: 1;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(2) {
    text-align: center;
  }

  &:last-child {
    text-align: right;
  }
`;
