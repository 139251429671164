import styled from 'styled-components';
import { colors } from 'common/colors';

export const CircleWithCharWrap = styled.div<{
  backgroundColor?: string;
  diameter?: number;
}>`
  width: ${props => props.diameter || 36}px;
  height: ${props => props.diameter || 36}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.primary_white};
  font-size: ${props => (props.diameter ? props.diameter - 12 : 24)}px;
  background-color: ${props => props.backgroundColor || colors.secondary_green};
  user-select: none;
`;
