import UserApi, { BadgeData } from 'utils/userApi';
import { AxiosResponse } from 'axios';
import { ParsedBadgesQuery } from 'utils/parseBadgesQuery';
import { UserType } from 'utils/AuthContext';

const getUserProgress = () => localStorage.getItem('userProgress') || '{}';

type ElementInteraction = {
  entryTime: Date;
  leaveTime: Date | undefined;
  storedData: Record<string, unknown>;
};

type UserPathwayProgress = {
  dataLevel: string;
  id: string;
  isCompleted: boolean;
  courseName?: string;
  topicName?: string;
  interaction: ElementInteraction[];
};

export type UserProgress = {
  [pathwayId: string]: UserPathwayProgress[];
};

const pathwayId: () => string = () => localStorage.getItem('pathwayId') || '';

function startCollectingDataFromInteraction(prevStoredData?: Record<string, unknown>): ElementInteraction {
  return {
    entryTime: new Date(),
    leaveTime: undefined,
    storedData: prevStoredData || {}
  };
}

function closeCollectingDataFromInteraction(currentThreadIndex: number): void {
  const userProgress: UserProgress = JSON.parse(getUserProgress());
  const pathwayID = pathwayId();
  const interactionElement = userProgress[pathwayID][currentThreadIndex].interaction.pop();
  if (interactionElement) {
    userProgress[pathwayID][currentThreadIndex].interaction.push({
      ...interactionElement,
      leaveTime: new Date(),
    });
  }
  localStorage.setItem('userProgress', JSON.stringify(userProgress));
}

function startUserProgressCollection(): UserPathwayProgress {
  const currentThreadData = JSON.parse(localStorage.getItem('currentTreadData') || '{}');
  return {
    dataLevel: currentThreadData.dataLevel,
    id: currentThreadData.id,
    isCompleted: false,
    courseName: currentThreadData?.courseName,
    interaction: [startCollectingDataFromInteraction()]
  };
}

const getMissingBadges = (user: UserType | null, badges: ParsedBadgesQuery): BadgeData[] => {
  const userProgress: UserProgress = JSON.parse(getUserProgress());
  const missingBadges: BadgeData[] = [];
  Object.keys(userProgress)
    .forEach(pathwayId => {
      if (!Array.isArray(userProgress[pathwayId])) {
        return;
      }
      userProgress[pathwayId].forEach((progressPackage) => {
        if (progressPackage?.dataLevel === 'completedCourse') {
          const doesBadgeExist = badges.some(parsedBadge =>
            parsedBadge.pathBadges.some(badge => badge?.courseId === progressPackage?.id));
          if (!doesBadgeExist) {
            return;
          }
          const hasUserAcquiredBadge = user?.badgesEarned.some(b => b.courseId === progressPackage?.id);
          if (hasUserAcquiredBadge) {
            return;
          }
          missingBadges.push({ courseId: progressPackage?.id });
        }
        if (progressPackage?.dataLevel === 'completedTopic') {
          const doesBadgeExist = badges.some(parsedBadge =>
            parsedBadge.pathBadges.some(badge => badge?.topicId === progressPackage?.id));
          if (!doesBadgeExist) {
            return;
          }
          const hasUserAcquiredBadge = user?.badgesEarned.some(b => b.topicId === progressPackage?.id);
          if (hasUserAcquiredBadge) {
            return;
          }
          missingBadges.push({ topicId: progressPackage?.id });
        }
      });
    });
  return missingBadges;
};

const setUserProgressData = (currentThreadIndex: number): void => {
  const userProgress: UserProgress = JSON.parse(getUserProgress());
  const pathwayID = pathwayId();
  let prevData = { storedData: {} };
  if (userProgress[pathwayID]) {
    if (userProgress[pathwayID][currentThreadIndex]) {
      if (userProgress[pathwayID][currentThreadIndex].interaction.length > 0) {
        prevData = userProgress[pathwayID][currentThreadIndex]
          .interaction[userProgress[pathwayID][currentThreadIndex].interaction.length - 1];
      }
      userProgress[pathwayID][currentThreadIndex]
        .interaction.push(startCollectingDataFromInteraction(prevData?.storedData));
    } else {
      userProgress[pathwayID][currentThreadIndex] = startUserProgressCollection();
    }
  } else {
    userProgress[pathwayID] = [];
    userProgress[pathwayID][currentThreadIndex] = startUserProgressCollection();
  }
  localStorage.setItem('userProgress', JSON.stringify(userProgress));
};

const handleUserProgressDataChange = (property: string, value: unknown): void => {
  const userProgress: UserProgress = JSON.parse(getUserProgress());
  const pathwayID = pathwayId();
  const currentThreadIndex = Number(localStorage.getItem('currentTread'));
  if (userProgress[pathwayID]) {
    if (userProgress[pathwayID][currentThreadIndex]) {
      const interactionElement = userProgress[pathwayID][currentThreadIndex].interaction.pop();
      if (interactionElement) {
        userProgress[pathwayID][currentThreadIndex].interaction.push({
          ...interactionElement,
          storedData: { ...interactionElement.storedData, [property]: value }
        });
      }
    }
    localStorage.setItem('userProgress', JSON.stringify(userProgress));
  }
};

const getUserStoredDataForStep = (stepId: string): Record<string, unknown> => {
  const userProgress: UserProgress = JSON.parse(getUserProgress());
  const pathwayID = pathwayId();
  if (userProgress && userProgress[pathwayID]) {
    const stepInteraction = userProgress[pathwayID].find(element => element && element.id === stepId);
    if (stepInteraction && stepInteraction.interaction) {
      const interactionArrayLength = stepInteraction.interaction.length;
      if (interactionArrayLength > 0) {
        return stepInteraction.interaction[interactionArrayLength - 1].storedData;
      }
    }
  }
  return {};
};

  const getUserStatistics = (): {
    badgesEarned: number;
    completedTopics: { id: string; courseName?: string }[];
    completedCourses: { id: string }[];
    stepsFinishedInPathway: { id: string; courseName?: string }[];
    completedKnowledgeBites: { courseName?: string; id: string }[];
  } => {
    const userProgress: UserProgress = JSON.parse(getUserProgress());
    const pathwayID = pathwayId();
    let badges = 0;
    const courses: { id: string }[] = [];
    const stepsFinished: { id: string; courseName?: string }[] = [];
    const completedKB: { courseName?: string; id: string }[] = [];
    const topics: { id: string; courseName?: string }[] = [];
    if (Array.isArray(userProgress[pathwayID])) {
      userProgress[pathwayID].forEach((progressPackage) => {
        if (!progressPackage || !progressPackage.dataLevel) {
          return;
        }
        switch (progressPackage.dataLevel) {
          case 'completedCourse':
            badges += 1;
            courses.push({ id: progressPackage.id });
            break;
          case 'completedTopic':
            badges += 1;
            topics.push({ id: progressPackage.id, courseName: progressPackage.courseName });
            break;
          case 'step':
            stepsFinished.push({ id: progressPackage.id, courseName: progressPackage.courseName });
            break;
          case 'completedKnowledgeBite':
            completedKB.push({ courseName: progressPackage.courseName, id: progressPackage.id });
        }
      });
    }
    return {
      badgesEarned: badges,
      completedTopics: topics,
      completedCourses: courses,
      stepsFinishedInPathway: stepsFinished,
      completedKnowledgeBites: completedKB
    };
  };

  const calculateProgressBasedOnSteps = (totalSteps: number, earnedSteps: number): number => {
    if (totalSteps > 0 && earnedSteps > 0 && earnedSteps > totalSteps) {
      return 0;
    }
    return Math.floor((earnedSteps / totalSteps) * 100);
  };

  const userSavedMaterials = () => JSON.parse(localStorage.getItem('savedMaterials') || '{}');

  const isMaterialSaved = (stepId: string): boolean =>
    userSavedMaterials()[pathwayId()]
    && userSavedMaterials()[pathwayId()].findIndex((savedM: { id: string; date: Date }) => savedM.id === stepId) >= 0;

  const addSavedMaterialInPathway = (stepId: string): void => {
    const pathwayID = pathwayId();
    const savedMaterials = userSavedMaterials();
    if (savedMaterials[pathwayID]) {
      if (!isMaterialSaved(stepId)) {
        savedMaterials[pathwayID].push({
          id: stepId,
          date: new Date()
        });
      }
    } else {
      savedMaterials[pathwayID] = [];
      savedMaterials[pathwayID].push({
        id: stepId,
        date: new Date()
      });
    }
    localStorage.setItem('savedMaterials', JSON.stringify(savedMaterials));
  };

  const getLastCompletedElementInPathwayIndex = (pathwayId: string): number => {
    const userProgress: UserProgress = JSON.parse(getUserProgress());
    if (!userProgress || !userProgress[pathwayId]) {
      return 0;
    }
    return (userProgress[pathwayId].length - 1);
  };

  const saveUserProgress = (): Promise<AxiosResponse<UserProgress>> =>
    UserApi.saveUserProgress(JSON.parse(getUserProgress()));

  export {
    setUserProgressData,
    getUserStatistics,
    calculateProgressBasedOnSteps,
    addSavedMaterialInPathway,
    isMaterialSaved,
    saveUserProgress,
    getLastCompletedElementInPathwayIndex,
    handleUserProgressDataChange,
    getUserStoredDataForStep,
    closeCollectingDataFromInteraction,
    getMissingBadges
  };
