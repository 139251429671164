import { Slider, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'common/colors';

export const LabelsContainer = styled.div`
  position: relative;
  height: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  width: 50px;
  text-align: center;
  position: absolute;
  color: ${colors.primary_gray3};
  transition: color 300ms;

  &:nth-child(1) {
    left: 0;
    text-align: left;
  }

  &:nth-child(2) {
    left: calc(50% - 24px);
  }

  &:last-child {
    left: calc(100% - 48px);
    text-align: right;
  }
`;

export const StyledSlider = withStyles({
  root: {
    color: colors.secondary_blue,
    height: 2,
    padding: '13px 0'
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: colors.primary_indigo,
    marginTop: -8,
    marginLeft: -8,
  },
  active: {
    boxShadow: 'none'
  },
  track: {
    height: 2
  },
  rail: {
    height: 2,
    opacity: 1,
    backgroundColor: colors.primary_gray_light2
  },
  mark: {
    backgroundColor: colors.primary_indigo,
    height: 8,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor'
  }
})(Slider);
