import axios, { AxiosResponse } from 'axios';
import { API_URL } from 'config/config';
import { UserProgress } from 'pages/courseControler/components/userProgress/userProgressHelper';
import { BadgesLevelsEnum, BadgesTypesEnum } from 'pages/badges/BadgesEnum';
import { CvBuilderValues } from 'utils/CvBuilderContext';
import { handleStrapiError } from 'utils/handleStrapiError';

type LoginCredentials = {
  email: string;
  password: string;
};

type RoleDto = {
  description: string;
  id: string;
  name: string;
  type: string;
  __v: number;
  _id: string;
};

type TopicDto = {
  id: string;
  createdAt: string;
  knowledgeBites: string[];
  name: string;
  updatedAt: string;
  what_you_will_do: string[];
  what_you_will_learn: string[];
  __v: number;
  _id: string;
};

type CourseDto = {
  badges: string;
  content: string;
  createdAt: string;
  created_by: string;
  id: string;
  name: string;
  topics: string[];
  updatedAt: string;
  updated_by: string;
  __v: number;
  _id: string;
};

type BadgeDto = {
  id: string;
  type: BadgesTypesEnum;
  level: BadgesLevelsEnum;
  color: string;
};

export type BadgeEarnedDto = {
  id: string;
  topic?: TopicDto;
  course?: CourseDto;
  badges: BadgeDto;
  __v: number;
  _id: string;
};

export type UserDto = {
  blocked: boolean;
  confirmed: boolean;
  createdAt: string;
  email: string;
  rsaId?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  id: string;
  provider: string;
  role: RoleDto;
  badgesEarned: BadgeEarnedDto[];
  certificates: string[];
  cv: CvBuilderValues;
  updatedAt: string;
  progressJson: Record<string, unknown>;
  __v: number;
  _id: string;
};

export type LoginResponse = {
  jwt: string;
  user: UserDto;
};

export type RegisterData = {
  email: string;
  password: string;
};

export type BadgeData = {
  courseId?: string;
  topicId?: string;
};

type AddBadgeResponse = {
  message: string;
  badgesEarned: BadgeEarnedDto[];
};

export type CertificateData = {
  certificateId: string;
};

type CertificateDto = {
  components: {
    _id: string;
    kind: string;
    ref: string;
  }[];
  content: string;
  createdAt: string;
  created_by: string;
  id: string;
  name: string;
  path: string;
  updatedAt: string;
  updated_by: string;
  __v: number;
  _id: string;
};

type AddCertificateResponse = {
  message: string;
  certificates: CertificateDto[];
};

type CvData = {
  cv: CvBuilderValues;
};

type UpdateCvResponse = {
  message: string;
  cv: CvBuilderValues;
};

type ResetPasswordData = {
  code: string;
  password: string;
  passwordConfirmation: string;
};

const getSelf = async (): Promise<AxiosResponse<UserDto>> => axios.get(`${API_URL}/users/me`);

const loginUser = async (loginCredentials: LoginCredentials): Promise<LoginResponse> => (
  axios.post(`${API_URL}/auth/local`, loginCredentials)
    .then(res => res.data)
    .catch(err => {
      if (err?.response?.status === 400) {
        throw new Error(err.response.data.message[0].messages[0].message);
      } else {
        localStorage.removeItem('Authorization');
        throw new Error('Please refresh page and try again.');
      }
      // throw new Error(err);
    })
);

const registerUser = async (registerData: RegisterData): Promise<number | void> => (
  axios.post(`${API_URL}/auth/local/register`, registerData)
    .then(res => res.status)
    .catch(handleStrapiError)
);

const updateUser = async (userId: string, userData: Partial<UserDto>): Promise<UserDto> =>
  axios.put(`${API_URL}/users/${userId}`, userData).then(res => res.data).catch(handleStrapiError);

const addBadge = async (badgeData: BadgeData): Promise<AddBadgeResponse> => (
  axios.post(`${API_URL}/users/me/addBadge`, badgeData)
    .then(res => res.data)
);

const addMultipleBadges = async (badgesDataArray: BadgeData[]): Promise<AddBadgeResponse> => (
  axios.post(`${API_URL}/users/me/addMultipleBadges`, badgesDataArray)
    .then(res => res.data)
);

const addCertificate = async (certificateData: CertificateData): Promise<AddCertificateResponse> => (
  axios.post(`${API_URL}/users/me/addCertificate`, certificateData)
    .then(res => res.data)
);

const saveUserProgress = async (userProgressData: UserProgress): Promise<AxiosResponse<UserProgress>> => (
  axios.patch(`${API_URL}/users/me/saveProgress`, {
    progressJson: userProgressData
  })
    .then(res => res)
    .catch(error => {
      throw new Error(error);
    })
);

const updateCv = async (cvData: CvData): Promise<UpdateCvResponse> => (
  axios.patch(`${API_URL}/users/me/updateCv`, cvData)
    .then(res => res.data)
);

const remindPassword = async (email: string): Promise<void> => (
  axios.post(`${API_URL}/auth/forgot-password`, { email }).then(res => res.data).catch(handleStrapiError)
);

const resetPassword = async (resetPasswordData: ResetPasswordData): Promise<void> => (
  axios.post(`${API_URL}/auth/reset-password`, resetPasswordData).then(res => res.data).catch(handleStrapiError)
);

export default {
  getSelf,
  loginUser,
  registerUser,
  addBadge,
  addCertificate,
  saveUserProgress,
  updateCv,
  remindPassword,
  resetPassword,
  updateUser,
  addMultipleBadges
};
